<template>
  <div class="file-upload">
    <h3>File upload</h3>
    <b-form-file
      v-model="fileUploadData"
      plain
      placeholder="Choose a file or drop it here..."
    ></b-form-file>

    <div class="dropDown" v-if="documentCategory === 'physio'">
      <p>Select document type</p>

      <b-form-select v-model="selectedDcoumetCategory">
        <b-form-select-option :value="null"
          >Please select an option</b-form-select-option
        >
        <b-form-select-option
          v-if="elementData.physio_document_id === null"
          value="physio"
          >Physio</b-form-select-option
        >
        <b-form-select-option
          v-if="elementData.player_document_id === null"
          value="physio-player"
          >Physio player</b-form-select-option
        >
      </b-form-select>
      <p
        v-if="
          elementData.physio_document_id !== null &&
          elementData.player_document_id !== null
        "
      >
        You can only upload up to two documents to a player's injury report. To
        add a new document, please delete an existing one first.
      </p>
      <button v-else @click="sendFile">Upload!</button>
    </div>
    <div class="dropDown" v-else>
      <button @click="sendFile">Upload!</button>
    </div>
  </div>
</template>
<script>
import Axios from "axios";
export default {
  name: "imageUploader",
  data() {
    return {
      fileUploadData: [],
      selectedDcoumetCategory: null,
    };
  },
  props: {
    documentCategory: {
      type: String,
      default: null,
    },
    user_id: {
      type: Number,
      default: null,
    },
    club_id: {
      type: String,
      default: null,
    },
    elementData: {
      type: Object,
      default: null,
    },
    selectedDocumentData: {
      type: Object,
      default: null,
    },
  },
  created() {},
  methods: {
    async sendFile() {
      let userData;
      let API_SERVER = "";
      if (window.location.href.indexOf("8080") > -1) {
        API_SERVER = "http://www.localhost:8888/upload-document.php";
      } else {
        API_SERVER = "/personal-training-BE/upload-document.php";
      }
      if (this.selectedDocumentData !== null) {
        if (this.selectedDocumentData.category === "physio-report") {
          userData = {
            documentCategory: this.selectedDocumentData.category,
            user_id: this.user_id,
            club_id: this.club_id,
            element_id: this.elementData.id,
            physio_id: this.elementData.physio_id,
          };
        } else {
          userData = {
            documentCategory: this.selectedDocumentData.category,
            user_id: this.user_id,
            club_id: this.club_id,
            selectedDocumentData: JSON.stringify(this.selectedDocumentData),
          };
        }
      } else {
        userData = {
          documentCategory: this.selectedDcoumetCategory,
          user_id: this.user_id,
          club_id: this.club_id,
          element_id: this.elementData.id,
        };
      }
      const formData = new FormData();
      formData.append("pdf", this.fileUploadData);

      Object.entries(userData).forEach(([k, v]) => {
        if (Array.isArray(v)) {
          // used stringify but might also just use join() for comma separated string
          v = JSON.stringify(v);
        }
        formData.append(k, v);
      });

      Axios.post(API_SERVER, formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      })
        .then((responce) => {
          if (responce.status === 200) {
            if (this.documentCategory === "physio-report") {
            } else {
              this.$emit("getDocumentUploadsCall");
            }

            this.$bvToast.toast("Document uploaded.", {
              title: "Uploaded",
              toaster: "b-toaster-bottom-right",
              solid: true,
              appendToast: true,
              variant: "success",
            });
          }
        })
        .catch(() => {});
    },
  },
};
</script>

<style scoped lang="scss">
.file-upload {
  .dropDown {
    p {
      margin: 1rem 0 0 0;
    }

    button {
      display: block;
      margin-top: 1rem;
    }
  }
}
</style>
