<template>
  <div class="auth-page loading">
    <spinner-loader :loadingStuff="loadingStuff" />
    <main>
      <b-alert
        v-if="deferredPrompt !== null ? 'show' : ''"
        class="installAlert"
        variant="success"
      >
        <p>
          Install the app onto your device so you can receive notifications.
        </p>
        <div class="button-wrappers">
          <button @click="install">Install</button>
          <button @click="dismiss">Dismiss</button>
        </div>
      </b-alert>
      <div class="content content--side">
        <header class="codrops-header uk-flex uk-flex-center">
          <img src="../../img/surf-logo.svg" alt="" />
          <h1 class="uk-margin-remove uk-text-center login-header">
            Partick Thistle
            <span>{{ $store.getters.appName }}</span>
          </h1>
        </header>
        <form class="form" v-on:submit.prevent="login()">
          <p v-if="validation" class="uk-text-danger">
            {{ validationText }}
          </p>
          <div class="form__item">
            <label class="form__label" for="email">Email Address</label>
            <input
              class="form__input"
              type="email"
              v-model="email"
              id="email"
            />
          </div>
          <div class="form__item">
            <label class="form__label" for="password">Password</label>
            <div class="form__input-wrap">
              <input
                class="form__input"
                type="password"
                v-model="password"
                id="password"
              />
              <p class="form__password-strength" id="strength-output"></p>
              <span class="reset-password">
                <a @click="openResetPasswordModal()" href="javascript:;"
                  >Reset Password</a
                >
              </span>
            </div>
          </div>
          <div class="form__item form__item--actions">
            <button type="submit">Log in</button>
          </div>
        </form>
      </div>
    </main>

    <b-modal
      id="resetPasswordModal"
      size="lg"
      title="Reset password"
      :hide-footer="Boolean(true)"
    >
      <div v-if="emailsentValue === false" class="form">
        <p>
          Enter your email and we will send you a link to reset your password.
        </p>
        <div class="form__item">
          <label class="form__label" for="email">Email Address</label>
          <input class="form__input" type="email" v-model="email" id="email" />
        </div>

        <button @click="emailResetPasswordLink()" class="green-button">
          Send link to email
        </button>
      </div>
      <div v-else class="form">
        <p>
          Email sent, check your email and open the link we sent you to
          continue.
        </p>
      </div>
    </b-modal>
  </div>
</template>

<style>
.login {
  display: flex;
  flex-direction: column;
  width: 300px;
}
</style>

<script>
import img from "./img/login.jpg";
import { AUTH_REQUEST } from "@/store/actions/auth";
import { USER_POPULATE } from "@/store/actions/user";
import { Users } from "@/services/user-service.js";
import { Mail } from "@/services/mail-service.js";
import { Storage } from "@/services/storage";
import SpinnerLoader from "../../../components/LoadingSpinning";
import {
  getAuth,
  createUserWithEmailAndPassword,
  signInWithEmailAndPassword,
} from "firebase/auth";
export default {
  name: "Login",
  components: {
    SpinnerLoader,
  },
  data() {
    return {
      loadingStuff: false,
      deferredPrompt: null,
      emailsentValue: false,
      email: "",
      password: "",
      action: "login",
      img: img,
      validation: false,
      validationText: "Invalid Username or Password!",
    };
  },
  mounted() {
    window.addEventListener("beforeinstallprompt", (e) => {
      e.preventDefault();
      // Stash the event so it can be triggered later.
      this.deferredPrompt = e;
    });
    window.addEventListener("appinstalled", () => {
      this.deferredPrompt = null;
    });
  },
  methods: {
    async dismiss() {
      this.deferredPrompt = null;
    },
    async install() {
      this.deferredPrompt.prompt();
    },
    async emailResetPasswordLink() {
      this.emailsentValue = true;
      const userData = {
        email: this.email,
        action: "emailResetPasswordLink",
      };

      const responce = await Mail.find(userData).catch((error) => {
        console.log(error);
      });

      if (responce.data !== null) {
      }
    },
    async login() {
      this.loadingStuff = true;

      const { email, password, action } = this;

      if (password.length <= 5) {
        this.fireBaseAuthValidation();
        this.loadingStuff = false;
        return;
      }

      try {
        // Dispatch Vuex authentication request
        const response = await this.$store.dispatch(AUTH_REQUEST, {
          email,
          password,
          action,
        });

        if (response === "Invalid Username or Password!") {
          this.passwordCheck(response);
        } else {
          const auth = getAuth();
          if (response[0].uid !== null) {
            // User exists, sign in
            await this.signIn(auth, email, password, response);
          } else {
            // User does not exist, attempt to create an account
            await this.registerUser(auth, email, password, response);
          }
        }
      } catch (error) {
        this.$snack.danger({ text: error.message });
      } finally {
        this.loadingStuff = false;
      }
    },
    async signIn(auth, email, password, response) {
      try {
        const userCredential = await signInWithEmailAndPassword(
          auth,
          email,
          password
        );
        response[0].uid = userCredential.user.uid;
        if (userCredential) {
          this.loginSuccess(response);
        }
      } catch (error) {
        console.error("Sign-in error:", error.message);
        this.handleAuthError(error);
      }
    },
    async registerUser(auth, email, password, response) {
      try {
        const userCredential = await createUserWithEmailAndPassword(
          auth,
          email,
          password
        );
        response[0].uid = userCredential.user.uid;
        if (userCredential) {
          this.loginSuccess(response);
          this.updatePlayerProfileUid(
            response[0].id,
            response[0].uid,
            response[0].email
          );
        }
      } catch (error) {
        console.error("Registration error:", error.message);

        if (error.code === "auth/email-already-in-use") {
          await this.signIn(auth, email, password, response); // Try signing in if email is taken
          this.updatePlayerProfileUid(
            response[0].id,
            response[0].uid,
            response[0].email
          );
        } else if (error.code === "auth/weak-password") {
          this.fireBaseAuthValidation();
        }
      }
    },
    async updatePlayerProfileUid(id, uid, email) {
      const data = {
        id: id,
        uid: uid,
        email: email,
        action: "updatePlayerProfileUid",
      };
      const responce = await Users.find(data).catch((error) => {
        console.log(error);
      });
    },
    handleAuthError(error) {
      if (error.code === "auth/email-already-in-use") {
        this.$snack.warning({ text: "Email already in use. Try signing in." });
      } else if (error.code === "auth/wrong-password") {
        this.$snack.danger({ text: "Incorrect password." });
      } else {
        this.$snack.danger({ text: error.message });
      }
    },
    phpAuthValidation() {
      this.validation = true;
      this.validationText = "Invalid Username or Password!";
      this.loadingStuff = false;
    },
    fireBaseAuthValidation() {
      this.validation = true;
      this.validationText =
        "Your password needs to be at least 6 characters long";
      this.loadingStuff = false;
    },
    openResetPasswordModal() {
      this.$bvModal.show("resetPasswordModal");
    },
    loginSuccess(response) {
      if (response[0].id !== undefined) {
        if (response[0].uid !== null) {
          this.validation = false;
          this.loadingStuff = false;

          if (response.length > 1) {
            const stringifiedObj = JSON.stringify(response);
            localStorage.setItem("userHasMoreThanOneAccount", stringifiedObj);
          }
          this.poupulateUserData(response[0]);
          this.$router.push("/dashboard").catch(() => {});
        } else {
        }
      } else {
        this.validation = true;
        this.loadingStuff = false;
      }
    },
    passwordCheck(response) {
      if (response === "Invalid Username or Password!") {
        this.phpAuthValidation();
      }

      this.loginSuccess(response);
    },
    poupulateUserData(userData) {
      this.$store.dispatch(USER_POPULATE, { userData });
      Storage.setJSON("selectedClientId", userData.id);
    },
  },
};
</script>

<style lang="scss" scoped>
@import "./../styles/auth-styles";

.reset-password {
  margin-top: 0.5rem;
  display: block;

  a {
    color: #403436;
    text-align: right;
    display: block;
    font-size: 0.9rem;
  }
}

.installAlert {
  position: absolute;
  display: flex;
  flex-wrap: wrap;
  gap: 1rem;
  top: 0;
  left: 0;
  width: 101%;
  z-index: 110;

  .button-wrappers {
    display: flex;
    gap: 0.5rem;
  }
}

@media (min-width: 1200px) {
  .login-header {
    font-size: 1.5rem;
  }
}
</style>
