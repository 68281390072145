import {
  AUTH_REQUEST,
  AUTH_SIGNUP,
  AUTH_ERROR,
  AUTH_SUCCESS,
  AUTH_LOGOUT,
} from "@/store/actions/auth";
import { POPULATE_WEEK_SELECTED_NUMBER, USER_POPULATE } from "../actions/user";
import { apiCall, api_routes } from "@/utils/api";
import router from "./../../router";
import dayjs from "dayjs";
import { getAuth, createUserWithEmailAndPassword } from "firebase/auth";
import { Users } from "@/services/user-service.js";
export const STORAGE_KEY = "w-academy-pt";
const updatePlayerProfileUid = async (id, uid) => {
  const data = {
    id: id,
    uid: uid,
    action: "updatePlayerProfileUid",
  };
  const responce = await Users.find(data).catch((error) => {
    console.log(error);
  });
};
const state = {
  token: localStorage.getItem("user-token") || "",
  status: "",
  hasLoadedOnce: false,
};

const getters = {
  isAuthenticated: (state) => !!state.token,
  authStatus: (state) => state.status,
};

const actions = {
  [AUTH_REQUEST]: ({ commit, dispatch }, user) => {
    return new Promise((resolve, reject) => {
      commit(AUTH_REQUEST);
      apiCall({ url: api_routes.user.login, data: user, method: "post" })
        .then((resp) => {
          if (resp !== "Invalid Username or Password!") {
            let token = dayjs().format("YYYY-MM-DD");
            token = `${token}/${resp[0].id}`;
            localStorage.setItem("user-token", token);
            commit(AUTH_SUCCESS, resp);
            // dispatch(USER_REQUEST);
          }

          resolve(resp);
        })
        .catch((err) => {
          commit(AUTH_ERROR, err);
          //localStorage.removeItem("user-token");
          reject(err);
        });
    });
  },
  [AUTH_SIGNUP]: ({ commit }, user) => {
    return new Promise((resolve, reject) => {
      commit(AUTH_REQUEST);
      apiCall({ url: api_routes.user.signup, data: user, method: "post" })
        .then((resp) => {
          const auth = getAuth();
          createUserWithEmailAndPassword(auth, user.email, user.password)
            .then((userCredential) => {
              const uid = userCredential.user.uid;
              updatePlayerProfileUid(user.id, uid);
            })
            .catch((error) => {
              const errorCode = error.code;
              const errorMessage = error.message;
            });

          resolve(resp);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
  [AUTH_LOGOUT]: ({ commit }) => {
    return new Promise((resolve) => {
      commit(AUTH_LOGOUT);
      //localStorage.removeItem("user-token");
      localStorage.removeItem("the_w_selectedClientId");
      localStorage.removeItem("weekNumber");
      localStorage.removeItem("calendarDate");
      localStorage.removeItem("mondaySunDates");
      localStorage.removeItem("coachesUsersData");
      localStorage.removeItem("currentPlayingSeason");
      localStorage.removeItem("currentPlayingseasonStartDate");
      localStorage.removeItem("currentPlayingseasonEndDate");
      localStorage.removeItem("the_w_clubId");
      localStorage.removeItem("userHasMoreThanOneAccount");
      commit(POPULATE_WEEK_SELECTED_NUMBER, null);
      commit(USER_POPULATE, null);
      router.push("/login");
      resolve();
    });
  },
};

const mutations = {
  [AUTH_REQUEST]: (state) => {
    state.status = "loading";
  },
  [AUTH_SUCCESS]: (state, resp) => {
    state.status = "success";
    state.token = "test-token";
    state.hasLoadedOnce = true;
    Event.$emit("user-authenticated");
  },
  [AUTH_ERROR]: (state) => {
    state.status = "error";
    state.hasLoadedOnce = true;
  },
  [AUTH_LOGOUT]: (state) => {
    state.token = "";
  },
  [POPULATE_WEEK_SELECTED_NUMBER](state, selectedWeek) {
    state.selectedWeek = selectedWeek;
  },
  [USER_POPULATE]: (state, resp) => {
    state.profile = resp;
  },
};

export default {
  state,
  getters,
  actions,
  mutations,
};
