<template>
  <div>
    <spinner-loader :loadingStuff="loadingStuff" />
    <app-template />
    <div class="uk-container uk-container-large">
      <h2>Trialists</h2>

      <ul class="tabs">
        <li class="tab-item">
          <a :class="selectedTab === 'trialist-tab' ? 'tab-link active' : 'tab-link'
            " @click="tabChange('selectedTab', 'trialist-tab')" href="javascript:;">Trialist</a>
        </li>
        <li class="tab-item">
          <a :class="selectedTab === 'leads-tab' ? 'tab-link active' : 'tab-link'
            " @click="tabChange('selectedTab', 'leads-tab')" href="javascript:;">Player leads</a>
        </li>
        <li class="tab-item">
          <a :class="selectedTab === 'gamesAttened-tab'
            ? 'tab-link active'
            : 'tab-link'
            " @click="tabChange('selectedTab', 'gamesAttened-tab')" href="javascript:;">Games attended</a>
        </li>

        <li class="tab-item">
          <a :class="selectedTab === 'signed-tab' ? 'tab-link active' : 'tab-link'
            " @click="tabChange('selectedTab', 'signed-tab')" href="javascript:;">Signed</a>
        </li>
        <li class="tab-item">
          <a :class="selectedTab === 'archived-tab' ? 'tab-link active' : 'tab-link'
            " @click="tabChange('selectedTab', 'archived-tab')" href="javascript:;">Archived
          </a>
        </li>
        <li class="tab-item">
          <a :class="selectedTab === 'id-tab' ? 'tab-link active' : 'tab-link'"
            @click="tabChange('selectedTab', 'id-tab')" href="javascript:;">Scout id</a>
        </li>
        <li class="tab-item">
          <a :class="selectedTab === 'fixtures-tab' ? 'tab-link active' : 'tab-link'
            " @click="tabChange('selectedTab', 'fixtures-tab')" href="javascript:;">Fixtures</a>
        </li>
      </ul>

      <template>
        <div class="tabs-wrapper">
          <div class="current-week-tab leads-tab tab-content" v-if="selectedTab === 'leads-tab'">
            <div class="add-new-lead-wrapper">
              <button @click="openLeadsModal('new')" class="green-button add-new-lead">
                Add Player lead
              </button>
            </div>

            <div class="table-wrapper table-scroll">
              <div v-if="allPlayerLeadsData.length > 0" class="">
                <vue-good-table style-class="vgt-table bordered" :columns="columnsPlayerLeads"
                  :rows="allPlayerLeadsData" :search-options="{
                    enabled: true,
                    placeholder: 'Search leads',
                  }">
                  <template slot="table-row" slot-scope="props">
                    <span v-if="props.column.field == 'dob'">
                      <span>{{
                        formatDates(props.formattedRow[props.column.field])
                      }}</span>
                    </span>
                    <span v-else-if="props.column.field == 'userImage'">
                      <span>
                        <img class="user-image-small" :src="getImgUrl('default-image.jpg')" />
                      </span>
                    </span>
                    <span class="action-buttons" v-else-if="props.column.field == 'state'">
                      <span :class="setPlayerLeadTextColor(props.row.state)">{{
                        props.row.state
                      }}</span>
                    </span>
                    <span class="action-buttons" v-else-if="props.column.field == 'date'">
                      <span>{{ formatDateForHuman(props.row.date) }}</span>
                    </span>
                    <span class="action-buttons" v-else-if="props.column.field == 'actions'">
                      <div class="player-game-responce">
                        <b-button v-if="props.row.trialistId === null && userData.userType === 'Admin'"
                          @click="addEditTrialistModel(props.row)" variant="outline-success" v-b-tooltip.hover
                          title="Add lead as a trialist">
                          <b-icon icon="person" aria-hidden="true"></b-icon></b-button>

                        <b-button @click="selectedUseChanged(props.row, 'playerLead')" variant="outline-success"
                          v-b-tooltip.hover title="View report"><b-icon icon="eye"
                            aria-hidden="true"></b-icon></b-button>
                      </div>
                    </span>
                  </template>
                </vue-good-table>
              </div>
              <div v-else>
                <div class="empty-state">
                  <img src="../img/emptyStateIllustration.svg" />
                  <p>No Teams.</p>
                </div>
              </div>
            </div>
          </div>
          <div class="current-week-tab trialist-tab tab-content" v-if="selectedTab === 'trialist-tab'">
            <div style="width: 100%; margin-bottom: 2rem">
              <button @click="addEditTrialistModel()" class="green-button add-new-programme">
                Add trialist
              </button>
            </div>

            <template>
              <section class="player-profile-wrapper" v-if="liveTrialistsData.length > 0">
                <p>Total number of trialists: {{ liveTrialistsData.length }}</p>

                <div class="button-wrapper-top filters-sort">
                  <b-input-group size="sm" class="mb-2 autocomplete search-small">
                    <b-input-group-prepend is-text>
                      <b-icon icon="search"></b-icon>
                    </b-input-group-prepend>
                    <b-form-input type="search" placeholder="Search players"
                      @input="lookUpSearch($event)"></b-form-input>
                  </b-input-group>

                  <ul class="autocomplete-results" v-if="filteredTrialistsData.length > 0">
                    <li @click="selectedUseChanged(userData, 'trialist')" v-for="userData in filteredTrialistsData"
                      :key="userData.id" class="autocomplete-result">
                      {{ userData.name }}
                    </li>
                  </ul>

                  <b-button @click="toggleFilters = !toggleFilters" variant="outline-success filter-small">
                    <b-icon icon="filter" aria-hidden="true"></b-icon>
                    Filters</b-button>

                  <div class="filters" v-if="toggleFilters === true">
                    <b-form-select class="mb-3" @change="filterBy('liveTrialistsData', 'teamId')"
                      v-model="selectedFilterData">
                      <b-form-select-option :value="null" disabled>Select a team</b-form-select-option>
                      <b-form-select-option v-for="team in clubTeams" :key="team.id" :value="team.id">{{ team.teamName
                      }}</b-form-select-option>
                    </b-form-select>
                    <a @click="removeFilter()" v-if="hideRemoveTeamFilterButton === false" href="javascript:;"
                      class="remove-icon-button"><b-icon icon="x" font-scale="2" aria-hidden="true"></b-icon></a>
                  </div>
                </div>
                <div v-for="trialist in liveTrialistsDataFiltered" :value="trialist.id" :key="trialist.id"
                  class="player-profile-details">
                  <div class="user-photo-info">
                    <img v-if="
                      trialist.userImage !== '' && trialist.userImage !== null
                    " :src="getImgUrl(trialist.userImage)" alt="" />
                    <img v-else :src="getImgUrl('default-image.jpg')" alt="" />
                  </div>
                  <div class="user-info">
                    <h2>{{ trialist.name }}</h2>
                    <p>Position: {{ trialist.position }}</p>
                    <p>Team: {{ getTeamName(trialist.teamId) }}</p>
                    <p>
                      Trial length: {{ trialLength(trialist.trial_length) }}
                    </p>
                    <p>
                      Trial time left:
                      <span class="trial-ended" v-if="
                        getTimeLeftInTrial(
                          trialist.trial_start_date,
                          trialist.trial_length
                        ) === 'Trial period has ended.'
                      ">{{
                        getTimeLeftInTrial(
                          trialist.trial_start_date,
                          trialist.trial_length
                        )
                      }}</span>
                      <span class="trial-in-progress" v-else>{{
                        getTimeLeftInTrial(
                          trialist.trial_start_date,
                          trialist.trial_length
                        )
                      }}</span>
                    </p>

                    <p>Trial score: {{ totalScore(trialist) }} / 30</p>
                    <p>Coach: {{ trialist.coach_name }}</p>
                    <p :class="`outCome-${outComeText(trialist.outcome)}`">
                      {{ outComeText(trialist.outcome) }}
                    </p>
                  </div>

                  <div class="player-game-responce">
                    <b-button variant="outline-warning" v-b-tooltip.hover title="Edit"
                      @click="selectedUseChanged(trialist, 'trialist')"><b-icon icon="pencil"
                        aria-hidden="true"></b-icon></b-button>

                    <b-button @click="removeTrialistFromDB(trialist.id)" variant="outline-danger" v-b-tooltip.hover
                      title="Delete trialist"><b-icon icon="trash" aria-hidden="true"></b-icon></b-button>
                    <b-button @click="extendTrialModal(trialist)" variant="outline-success" v-b-tooltip.hover
                      title="Extend trial"><b-icon icon="alarm" aria-hidden="true"></b-icon></b-button>
                  </div>
                </div>
              </section>

              <div class="empty-state" v-else>
                <img src="../img/emptyStateIllustration.svg" />
                <p>Please select a trialist to view their information</p>
              </div>
            </template>
          </div>

          <div class="current-week-tab trialist-tab tab-content" v-if="selectedTab === 'signed-tab'">
            <template>
              <section class="player-profile-wrapper" v-if="signedtrialistsData.length > 0">
                <div v-for="trialist in signedtrialistsData" :value="trialist.id" :key="trialist.id"
                  class="player-profile-details">
                  <div class="user-photo-info">
                    <img v-if="
                      trialist.userImage !== '' && trialist.userImage !== null
                    " :src="getImgUrl(trialist.userImage)" alt="" />
                    <img v-else :src="getImgUrl('default-image.jpg')" alt="" />
                  </div>
                  <div class="user-info">
                    <h2>{{ trialist.name }}</h2>
                    <p>Position: {{ trialist.position }}</p>
                    <p>Team: {{ getTeamName(trialist.teamId) }}</p>
                    <p>
                      Trial length: {{ trialLength(trialist.trial_length) }}
                    </p>

                    <p>Trial score: {{ totalScore(trialist) }} / 30</p>
                    <p>Coach: {{ trialist.coach_name }}</p>
                    <p :class="`outCome-${outComeText(trialist.outcome)}`">
                      {{ outComeText(trialist.outcome) }}
                    </p>
                  </div>

                  <div class="player-game-responce">
                    <b-button variant="outline-warning" v-b-tooltip.hover title="Edit" @click="
                      selectedUseChanged(trialist, 'trialist', 'signed')
                      "><b-icon icon="pencil" aria-hidden="true"></b-icon></b-button>

                    <b-button @click="removeTrialistFromDB(trialist.id)" variant="outline-danger" v-b-tooltip.hover
                      title="Delete trialist"><b-icon icon="trash" aria-hidden="true"></b-icon></b-button>
                  </div>
                </div>
              </section>

              <div class="empty-state" v-else>
                <img src="../img/emptyStateIllustration.svg" />
                <p>Please select a trialist to view their information</p>
              </div>
            </template>
          </div>

          <div class="current-week-tab trialist-tab tab-content" v-if="selectedTab === 'gamesAttened-tab'">
            <div class="add-new-lead-wrapper" style="width: 100%">
              <button class="green-button add-new-lead" @click="addEditGameAttendanceModel()">
                Add game attendance
              </button>
            </div>

            <div class="table-wrapper table-scroll">
              <div v-if="allScoutGameAttendanceReports.length > 0" class="">
                <vue-good-table style-class="vgt-table bordered" :columns="columnsScoutGameAttendance"
                  :rows="allScoutGameAttendanceReports" :search-options="{
                    enabled: true,
                    placeholder: 'Search games',
                  }">
                  <template slot="table-row" slot-scope="props">
                    <span v-if="props.column.field == 'scoutId'">
                      <span>{{ getScoutName(props.row.scoutId) }}</span>
                    </span>

                    <span v-else-if="props.column.field == 'teams'">
                      <span>{{ props.row.homeTeam }} v
                        {{ props.row.awayTeam }}</span>
                    </span>
                    <span v-else-if="props.column.field == 'matchDate'">
                      <span>{{ formatDateForHuman(props.row.matchDate) }}</span>
                    </span>
                    <span class="action-buttons" v-else-if="props.column.field == 'actions'">
                      <div class="player-game-responce">
                        <b-button @click="
                          addEditGameAttendanceModel(props.row.id, 'edit')
                          " variant="outline-success" v-b-tooltip.hover title="View report"><b-icon icon="eye"
                            aria-hidden="true"></b-icon></b-button><b-button @click="deleteGameAttendance(props.row.id)"
                          variant="outline-danger" v-b-tooltip.hover title="Delete game"><b-icon icon="trash"
                            aria-hidden="true"></b-icon></b-button>
                      </div>
                    </span>
                  </template>
                </vue-good-table>
              </div>
              <div v-else>
                <div class="empty-state">
                  <img src="../img/emptyStateIllustration.svg" />
                  <p>No Teams.</p>
                </div>
              </div>
            </div>
          </div>
          <div class="current-week-tab trialist-tab tab-content scout-fixtures-tab"
            v-if="selectedTab === 'fixtures-tab'">
            <p style="width: 100%">Upcoming games</p>

            <div class="games" v-if="allGamesData.length > 0">
              <div v-for="(game, index) in allGamesData" :key="index" class="game" :id="game.id + index">
                <div class="game-wrapper">
                  <div class="match-details">
                    <span>{{ game.gameType }}</span>
                    <p v-if="game.result_added == '0'" class="teams">
                      {{ getTeamName(game.teamId) }} <span>v</span>
                      {{ game.opposition }}
                    </p>
                    <p class="game-info">
                      <span><b-icon icon="calendar3"></b-icon>{{ getDayAndDate(game.matchDate) }}</span>
                      <span><b-icon icon="stopwatch"></b-icon>{{
                        formatISOStringDateAndTime(game.kickOffTime)
                        }}</span>
                    </p>
                    <p style="margin-bottom: 1rem">
                      <span class="venue-text"><b-icon icon="map" aria-hidden="true"></b-icon> Venue:
                        {{ game.address }} {{ game.postCode }}</span>
                    </p>
                    <p style="margin-bottom: 1rem">
                      {{ game.locationName }} -
                      <a class="map-link" v-if="game.postCode !== ''"
                        :href="`https://maps.google.fr/maps?q=${game.postCode}`" target="_blank"><b-icon icon="pin-map"
                          aria-hidden="true"></b-icon>
                        Map</a>
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div v-else>
              <div class="empty-state">
                <img src="../img/emptyStateIllustration.svg" />
                <p>No Upcoming Games.</p>
              </div>
            </div>
          </div>
          <div class="current-week-tab trialist-tab tab-content scout-id-tab" v-if="selectedTab === 'id-tab'">
            <div class="table-scroll">
              <div class="scout-id">
                <h2>
                  <img :src="getImgUrl('default-image.jpg')" alt="" />
                  {{ setClubName() }} scout
                </h2>

                <div class="id-info-wrapper">
                  <div class="user-photo-info">
                    <img v-if="
                      userData.userImage !== '' && userData.userImage !== null
                    " :src="getImgUrl(userData.userImage)" alt="" />
                    <img v-else :src="getImgUrl('default-image.jpg')" alt="" />
                  </div>

                  <div class="id-info">
                    <p>name: {{ userData.name }}</p>
                    <p>Scouting representative</p>
                    <p>{{ setTodaysDate() }}</p>
                  </div>
                </div>
                <img class="background-logo" :src="getImgUrl('default-image.jpg')" alt="" />
              </div>
            </div>
          </div>

          <div class="current-week-tab trialist-tab tab-content" v-if="selectedTab === 'archived-tab'">
            <template>
              <section class="player-profile-wrapper" v-if="archivedtrialistsData.length > 0">
                <div v-for="trialist in archivedtrialistsData" :value="trialist.id" :key="trialist.id"
                  class="player-profile-details">
                  <div class="user-photo-info">
                    <img v-if="
                      trialist.userImage !== '' && trialist.userImage !== null
                    " :src="getImgUrl(trialist.userImage)" alt="" />
                    <img v-else :src="getImgUrl('default-image.jpg')" alt="" />
                  </div>
                  <div class="user-info">
                    <h2>{{ trialist.name }}</h2>
                    <p>Position: {{ trialist.position }}</p>
                    <p>Team: {{ getTeamName(trialist.teamId) }}</p>
                    <p>
                      Trial length: {{ trialLength(trialist.trial_length) }}
                    </p>
                    <p>
                      Trial time left:
                      {{
                        getTimeLeftInTrial(
                          trialist.trial_start_date,
                          trialist.trial_length
                        )
                      }}
                    </p>

                    <p>Trial score: {{ totalScore(trialist) }} / 30</p>
                    <p>Coach: {{ trialist.coach_name }}</p>
                    <p :class="`outCome-${outComeText(trialist.outcome)}`">
                      {{ outComeText(trialist.outcome) }}
                    </p>
                  </div>

                  <div class="player-game-responce">
                    <b-button variant="outline-warning" v-b-tooltip.hover title="Edit"
                      @click="selectedUseChanged(trialist, 'trialist')"><b-icon icon="pencil"
                        aria-hidden="true"></b-icon></b-button>

                    <b-button @click="removeTrialistFromDB(trialist.id)" variant="outline-danger" v-b-tooltip.hover
                      title="Delete trialist"><b-icon icon="trash" aria-hidden="true"></b-icon></b-button>
                  </div>
                </div>
              </section>

              <div class="empty-state" v-else>
                <img src="../img/emptyStateIllustration.svg" />
                <p>Please select a trialist to view their information</p>
              </div>
            </template>
          </div>
        </div>
      </template>
    </div>
    <b-modal id="viewLead" size="lg" title="Lead information" :hide-footer="Boolean(true)">
      <div class="form lead-form-wrapper">
        <div class="form__item">
          <label class="form__label" for="name">Player name</label>
          <p>{{ selectedLeadData.name }}</p>
        </div>

        <div class="form__item">
          <label class="form__label" for="name">Club name</label>
          <p>{{ selectedLeadData.club_name }}</p>
        </div>

        <div class="form__item">
          <label class="form__label" for="name">Age group</label>
          <p>{{ selectedLeadData.age_group }}</p>
        </div>

        <div class="form__item">
          <label class="form__label" for="name">Position</label>
          <p>{{ selectedLeadData.playing_position }}</p>
        </div>

        <div class="form__item">
          <label class="form__label" for="name">Status</label>
          <p>{{ selectedLeadData.state }}</p>
        </div>

        <div class="form__item">
          <label class="form__label" for="name">Scout name</label>
          <p>{{ selectedLeadData.scout_name }}</p>
        </div>

        <div class="form__item">
          <label class="form__label" for="name">Scout notes / videos</label>
          <p v-html="selectedLeadData.notes"></p>
        </div>
      </div>
    </b-modal>

    <b-modal id="addEditLead" size="lg" :title="editMode === false ? 'Add new lead' : 'Edit lead details'"
      :hide-footer="Boolean(true)">
      <div class="form lead-form-wrapper">
        <div class="form__item">
          <label class="form__label" for="name">Player name</label>
          <input class="form__input" type="text" v-model="selectedLeadData.name" id="name" />
        </div>

        <div class="form__item">
          <label class="form__label" for="name">Current club name</label>
          <input class="form__input" type="text" v-model="selectedLeadData.club_name" id="name" />
        </div>

        <div class="form__item">
          <label class="form__label" for="dob">DOB</label>

          <datepicker v-model="selectedLeadData.dob" name="datepicker" id="dob" input-class="input-class">
          </datepicker>
        </div>

        <div class="form__item">
          <label class="form__label" for="name">Age group</label>

          <datepicker v-model="selectedLeadData.age_group" :format="DatePickerFormat" minimum-view="year"
            name="datepicker" id="input-id" input-class="input-class">
          </datepicker>
        </div>

        <div class="form__item">
          <label class="form__label" for="position">Position</label>
          <select id="position" v-model="selectedLeadData.playing_position">
            <option disabled value="">Please select a position</option>
            <option value="Goalkeeper">Goalkeeper</option>
            <option value="Right Full-back (Wingback)">
              Right Full-back (Wingback)
            </option>
            <option value="Left Full-back (Wingback)">
              Left Full-back (Wingback)
            </option>
            <option value="Center-back left">Center-back left</option>
            <option value="Center-back right">Center-back right</option>
            <option value="Defensive Midfielder">Defensive Midfielder</option>
            <option value="Centre midfield">Centre midfield</option>
            <option value="Centre Defensive Midfield">
              Centre Defensive Midfield
            </option>
            <option value="Right Midfielder (Winger)">
              Right Midfielder (Winger)
            </option>
            <option value="Left Midfielder (Winger)">
              Left Midfielder (Winger)
            </option>
            <option value="Attacking Midfielder">Attacking Midfielder</option>
            <option value="Center Forward (Striker) left">
              Center Forward (Striker) left
            </option>
            <option value="Center Forward (Striker) right">
              Center Forward (Striker) right
            </option>
            <option value="Plays in more than one position">
              Play's in more than one position
            </option>
          </select>
        </div>

        <div class="form__item">
          <label class="form__label" for="position">Status</label>
          <select id="position" v-model="selectedLeadData.state">
            <option disabled value="">Please select a state</option>
            <option value="Still to Watch">Still to Watch</option>
            <option value="Invited for trial">Invited for trial</option>
            <option value="Need to watch again">Need to watch again</option>
            <option value="Trial invite refused">Trial invite refused</option>
            <option value="Not at the standard required">
              Not at the standard required
            </option>
          </select>
        </div>

        <div class="form__item">
          <label class="form__label" for="position">Assign scout</label>
          <select id="position" @change="populateAssignedScout($event)">
            <option disabled value="">Please select a state</option>
            <option value="">Mark as unassigned</option>
            <option v-for="scout in allScoutsData" :key="scout.id" :value="scout.id"
              :selected="scout.id === selectedLeadData.scoutId">
              {{ scout.name }}
            </option>
          </select>
        </div>

        <div class="form__item">
          <label class="form__label" for="name">Scout notes / videos</label>

          <wysiwyg v-model="selectedLeadData.notes" />
        </div>

        <div class="form__item" v-if="editMode === false">
          <button @click="addNewPlayerLead()" type="submit" class="green-button">
            Add player lead
          </button>
        </div>

        <div class="form__item" v-else>
          <button @click="editPlayerLead()" type="submit" class="green-button">
            Edit player lead
          </button>
        </div>
      </div>
    </b-modal>
    <b-modal id="addEditTrialist" size="lg" :title="editMode === false ? 'Add new trialist' : 'Edit trialist details'"
      :hide-footer="Boolean(true)" @hide="resetTrialistData()">
      <p class="trialist-onboarding-message" v-if="editMode === false">
        You can send the trialist the onboarding link, so they can fully
        onboard. <br />
        {{ getTrialLink() }}
        <b-button variant="outline-primary" @click="copyToClipboard(getTrialLink())">Copy link</b-button>
      </p>
      <form class="form" @submit.prevent="">
        <div class="form__item">
          <label class="form__label" for="name">Name</label>
          <input class="form__input" type="text" v-model="selectedUserData.name" id="name" />
        </div>

        <div class="form__item">
          <label class="form__label" for="phone">phone</label>
          <input type="number" v-model="selectedUserData.phone" id="phone" />
        </div>

        <div class="form__item">
          <label class="form__label" for="email">Email Address</label>
          <input :class="validationValues.email.state === true
            ? 'danger-outline form__input'
            : 'form__input'
            " @change="checkChangeVlaidation(selectedUserData.email, 'email')" type="email"
            v-model="selectedUserData.email" id="email" />
          <p class="invalid-feedback" v-if="validationValues.email.state === true">
            {{ validationValues.email.text }}
          </p>
        </div>

        <div v-if="editMode === false" class="form__item">
          <label class="form__label" for="password">Password</label>
          <input type="password" v-model="selectedUserData.password" id="password" />
        </div>

        <div class="form__item uk-form dob">
          <label class="form__label" for="dob">DOB</label>
          <datepicker v-model="selectedUserData.dob" name="dob" id="dob" />
        </div>

        <ImageUploader @populateImageObjectFromUpload="imageUploadFileDataChnged($event)"
          @populateImageFileDataFromUpload="
            imageUploadObjectDataChanged($event)
            " @clearImageDataFromUpload="clearImageDataFromUpload()" :imageURL="selectedUserData.userImage" />

        <div class="form__item">
          <label class="form__label" for="position">Position</label>
          <select id="position" v-model="selectedUserData.position">
            <option disabled value="">Please select a position</option>
            <option value="Goalkeeper">Goalkeeper</option>
            <option value="Right Full-back (Wingback)">
              Right Full-back (Wingback)
            </option>
            <option value="Left Full-back (Wingback)">
              Left Full-back (Wingback)
            </option>
            <option value="Center-back left">Center-back left</option>
            <option value="Center-back right">Center-back right</option>
            <option value="Defensive Midfielder">Defensive Midfielder</option>
            <option value="Centre midfield">Centre midfield</option>
            <option value="Centre Defensive Midfield">
              Centre Defensive Midfield
            </option>
            <option value="Right Midfielder (Winger)">
              Right Midfielder (Winger)
            </option>
            <option value="Left Midfielder (Winger)">
              Left Midfielder (Winger)
            </option>
            <option value="Attacking Midfielder">Attacking Midfielder</option>
            <option value="Center Forward (Striker) left">
              Center Forward (Striker) left
            </option>
            <option value="Center Forward (Striker) right">
              Center Forward (Striker) right
            </option>
            <option value="I played more than one position">
              I played more than one position
            </option>
          </select>
        </div>

        <div class="form__item">
          <label class="form__label" for="coachSelect">Select team</label>
          <select v-model="selectedUserData.teamId" @change="
            selectTeamSelectCoach('selectedUserData', selectedUserData.teamId)
            " id="coachSelect">
            <option disabled value="">Please select a team</option>
            <option v-for="team in clubTeams" :value="team.id" :key="team.id">
              {{ team.teamName }}
            </option>
          </select>
        </div>

        <!--<div class="form__item">
            <label class="form__label" for="coachSelect">Select coach</label>
            <select v-model="selectedUserData.coach_id" id="coachSelect">
              <option disabled value="">Please select a coach</option>
              <option
                v-for="coach in coachData"
                :value="coach.id"
                :key="coach.id"
              >
                {{ coach.name }}
              </option>
            </select>
          </div>-->

        <div class="form__item uk-form address">
          <label class="form__label" for="address">Address</label>

          <b-form-textarea id="address" v-model="selectedUserData.address" placeholder="" rows="8"></b-form-textarea>
        </div>

        <div class="form__item uk-form post_code">
          <label class="form__label" for="post_code">Post code</label>

          <input class="form__input" type="text" v-model="selectedUserData.post_code" id="post_code" />
        </div>

        <div class="form__item uk-form place_of_birth">
          <label class="form__label" for="place_of_birth">Place of birth</label>

          <input class="form__input" type="text" v-model="selectedUserData.place_of_birth" id="place_of_birth" />
        </div>

        <div class="form__item uk-form place_of_birth">
          <label class="form__label" for="country_of_birth">Country of birth</label>

          <input class="form__input" type="text" v-model="selectedUserData.country_of_birth" id="place_of_birth" />
        </div>

        <div class="form__item uk-form nationality">
          <label class="form__label" for="nationality">Nationality</label>

          <input class="form__input" type="text" v-model="selectedUserData.nationality" id="nationality" />
        </div>

        <div class="form__item uk-form previous_club">
          <label class="form__label" for="nationality">Previous club</label>

          <input class="form__input" type="text" v-model="selectedUserData.previous_club" id="previous_club" />
        </div>

        <div class="form__item">
          <label class="form__label" for="parent_name">Parent name</label>
          <input class="form__input" type="text" v-model="selectedUserData.parent_name" id="parent_name" />
        </div>

        <div class="form__item">
          <label class="form__label" for="emergency_contact_one">Emergency Contact</label>
          <input class="form__input" type="text" v-model="selectedUserData.emergency_contact_one"
            id="emergency_contact_one" />
        </div>

        <div class="form__item">
          <label class="form__label" for="emergency_contact_two">Emergency Contact two</label>
          <input class="form__input" type="text" v-model="selectedUserData.emergency_contact_two"
            id="emergency_contact_two" />
        </div>

        <div class="form__item">
          <label class="form__label" for="position">Permission</label>
          <b-form-checkbox id="checkbox-1" v-model="selectedUserData.permission" name="checkbox-1">
            Permission to take photographs
          </b-form-checkbox>
        </div>

        <div class="form__item">
          <label class="form__label" for="position">Medical information</label>

          <b-form-textarea id="textarea" v-model="selectedUserData.medical" placeholder="" rows="8"></b-form-textarea>
        </div>

        <div class="form__item">
          <label class="form__label" for="coachSelect">Select trial length</label>
          <select v-model="selectedTrialistsData.trial_length" id="trialLengthSelect">
            <option disabled value="">Please select a trial length</option>
            <option value="3">3 weeks</option>
            <option value="6">6 weeks</option>
            <option value="12">12 weeks</option>
          </select>
        </div>

        <div class="form__item uk-form dob">
          <label class="form__label" for="trialStartDate">Trial start date</label>
          <datepicker v-model="selectedTrialistsData.trial_start_date" name="trialStartDate" id="trialStartDate" />
        </div>

        <div class="form__item">
          <label class="form__label" for="preferredFootSelect">Select preferred foot</label>
          <select v-model="selectedTrialistsData.preferred_foot" id="preferredFootSelect">
            <option disabled value="">Please select a preferred foot</option>
            <option value="Right">Right</option>
            <option value="Left">Left</option>
            <option value="Both">Both</option>
          </select>
        </div>

        <div v-if="editMode === false" class="form__item">
          <button @click="addEditCoachDetails()" class="green-button">
            Add trialist
          </button>
        </div>

        <div v-else class="form__item">
          <button @click="saveTrialistPerformanceReview()" class="green-button">
            Edit trialist
          </button>
        </div>
      </form>
    </b-modal>

    <b-modal id="ViewTrialistLeadPlayerProfile" size="lg" modal-class="modal-fullscreen trialist-player-profile"
      title="Player information" :hide-footer="Boolean(true)" @hide="resetSelectedCoach()">
      <div class="profile-page">
        <div class="intro-header" style="width: 100%">
          <img class="main-image-top" src="./img/pitch.svg" alt="pitch" />
          <div class="user-photo-info">
            <img id="user_photo" v-if="
              selectedUserData.userImage !== '' &&
              selectedUserData.userImage !== null
            " :src="getImgUrl(selectedUserData.userImage)" alt="" />
            <img id="user_photo" v-else :src="getImgUrl('default-image.jpg')" alt="" />
            <h1>
              <span> Player Profile</span><br />
              for {{ selectedUserData.name }}
            </h1>
          </div>

          <div class="sessons-header">
            <div class="header-buttons">
              <h2>
                <img id="small_shirt" src="./img/player-shirt-blue.svg" />Player
                profile
              </h2>
              <span class="week-selected-dates-text">
                <span class="selected-user">Name:<span class="highlighted-text">{{
                  selectedUserData.name
                    }}</span></span>
                <span class="selected-user">DOB:<span class="highlighted-text">{{
                  dayMonthYear(selectedUserData.dob)
                    }}</span></span>
                <span class="selected-user">Age group:<span class="highlighted-text">{{
                  selectedUserData.age_group
                    }}</span></span>
                <span class="selected-user">Nationality:<span class="highlighted-text">{{
                  selectedUserData.nationality
                    }}</span></span>
              </span>
            </div>
          </div>

          <div class="out-come-buttons" v-if="
            selectedTrialistsData.id !== undefined &&
            selectedTrialistsData.outcome === 0
          ">
            <button v-if="userData.userType === 'Admin'" @click="recordOutcome('1')" class="green-button">
              <b-icon icon="pen" aria-hidden="true"></b-icon> Sign
            </button>
            <button v-if="userData.userType === 'Admin'" @click="recordOutcome('2')" class="red-button">
              <b-icon icon="exclamation-circle" aria-hidden="true"></b-icon>
              Reject
            </button>

            <!--<button @click="recordOutcome('3')" class="yellow-button">Waiting list</button>-->
            <p>
              When you sign a player, they will be automatically registered with
              the team they tried out for.
            </p>
          </div>
          <p class="player-status-text signed" v-if="selectedTrialistsData.outcome === 1">
            Player signed
          </p>
          <p class="player-status-text rejected" v-if="selectedTrialistsData.outcome === 2">
            Player rejected
          </p>
        </div>

        <ul class="tabs">
          <li class="tab-item">
            <a :class="selectedPlayerInfoTab === 'lead-tab'
              ? 'tab-link active'
              : 'tab-link'
              " @click="tabChange('selectedPlayerInfoTab', 'lead-tab')" href="javascript:;">Lead information</a>
          </li>
          <li class="tab-item" v-if="selectedTrialistsData.id !== undefined">
            <a :class="selectedPlayerInfoTab === 'trial-tab'
              ? 'tab-link active'
              : 'tab-link'
              " @click="tabChange('selectedPlayerInfoTab', 'trial-tab')" href="javascript:;">Trial information</a>
          </li>

          <li class="tab-item" v-if="selectedTrialistsData.id !== undefined">
            <a :class="selectedPlayerInfoTab === 'player-stats-tab'
              ? 'tab-link active'
              : 'tab-link'
              " @click="tabChange('selectedPlayerInfoTab', 'player-stats-tab')" href="javascript:;">Player stats</a>
          </li>
          <li class="tab-item" v-if="selectedTrialistsData.id !== undefined">
            <a :class="selectedPlayerInfoTab === 'player-history-tab'
              ? 'tab-link active'
              : 'tab-link'
              " @click="tabChange('selectedPlayerInfoTab', 'player-history-tab')" href="javascript:;">Player
              history</a>
          </li>
          <li class="tab-item" v-if="selectedTrialistsData.id !== undefined">
            <a :class="selectedPlayerInfoTab === 'player-documents-tab'
              ? 'tab-link active'
              : 'tab-link'
              " @click="
                tabChange('selectedPlayerInfoTab', 'player-documents-tab')
                " href="javascript:;">Documents</a>
          </li>
          <li class="tab-item" v-if="selectedTrialistsData.id !== undefined">
            <a :class="selectedPlayerInfoTab === 'player-videos-tab'
              ? 'tab-link active'
              : 'tab-link'
              " @click="tabChange('selectedPlayerInfoTab', 'player-videos-tab')" href="javascript:;">videos</a>
          </li>
          <li class="tab-item" v-if="selectedTrialistsData.id !== undefined">
            <a :class="selectedPlayerInfoTab === 'player-report-tab'
              ? 'tab-link active'
              : 'tab-link'
              " @click="tabChange('selectedPlayerInfoTab', 'player-report-tab')" href="javascript:;">Trialists full
              report</a>
          </li>
        </ul>

        <div class="tabs-wrapper">
          <div class="tab-content no-flex" v-if="selectedPlayerInfoTab === 'lead-tab'">
            <div class="games-info" v-if="Object.keys(selectedLeadData).length > 0">
              <b-button @click="
                openLeadsModal('add more lead information', selectedLeadData)
                " type="submit" v-b-tooltip.hover title="Add more lead information" variant="outline-success"><b-icon
                  icon="plus" aria-hidden="true"></b-icon>Add
              </b-button>
              <br />
              <br />

              <div class="form lead-form-wrapper">
                <div class="form__item">
                  <label class="form__label" for="name">Club name</label>
                  <p>{{ selectedLeadData.club_name }}</p>
                </div>

                <div class="form__item">
                  <label class="form__label" for="name">Age group</label>
                  <p>{{ selectedLeadData.age_group }}</p>
                </div>

                <div class="form__item">
                  <label class="form__label" for="name">Position</label>
                  <p>{{ selectedLeadData.playing_position }}</p>
                </div>

                <div class="form__item">
                  <label class="form__label" for="name">Status</label>
                  <p>{{ selectedLeadData.state }}</p>
                </div>

                <div class="form__item">
                  <label class="form__label" for="name">Scout name</label>
                  <p>{{ selectedLeadData.scout_name }}</p>
                </div>

                <div class="form__item">
                  <label class="form__label" for="name">Scout notes</label>
                  <p v-html="selectedLeadData.notes"></p>
                </div>
              </div>

              <div class="player-game-responce">
                <b-button variant="outline-warning" @click="openLeadsModal('edit', selectedLeadData)"><b-icon
                    icon="pencil" aria-hidden="true"></b-icon>
                  Edit</b-button>
                <b-button @click="removePlayerLead(selectedLeadData.id)" variant="outline-danger"><b-icon icon="trash"
                    aria-hidden="true"></b-icon>
                  Delete</b-button>
              </div>

              <div class="other-leads-wrapper">
                <h4>More lead information</h4>
                <div class="other-leads" v-for="extraLeads in metaDataReults" :key="extraLeads.id">
                  <div class="leads-data" v-if="extraLeads.name == selectedLeadData.name">
                    <div class="form lead-form-wrapper">
                      <div class="form__item">
                        <label class="form__label" for="name">Club name</label>
                        <p>{{ extraLeads.club_name }}</p>
                      </div>

                      <div class="form__item">
                        <label class="form__label" for="name">Age group</label>
                        <p>{{ extraLeads.age_group }}</p>
                      </div>

                      <div class="form__item">
                        <label class="form__label" for="name">Position</label>
                        <p>{{ extraLeads.playing_position }}</p>
                      </div>

                      <div class="form__item">
                        <label class="form__label" for="name">Status</label>
                        <p>{{ extraLeads.state }}</p>
                      </div>

                      <div class="form__item">
                        <label class="form__label" for="name">Scout name</label>
                        <p>{{ extraLeads.scout_name }}</p>
                      </div>

                      <div class="form__item">
                        <label class="form__label" for="name">Scout notes</label>
                        <p v-html="extraLeads.notes"></p>
                      </div>
                    </div>

                    <div class="player-game-responce">
                      <b-button variant="outline-warning" @click="openLeadsModal('edit', extraLeads)"><b-icon
                          icon="pencil" aria-hidden="true"></b-icon>
                        Edit</b-button>
                      <b-button @click="removePlayerLead(extraLeads.id)" variant="outline-danger"><b-icon icon="trash"
                          aria-hidden="true"></b-icon>
                        Delete</b-button>
                    </div>
                  </div>
                  <br />
                  <br />
                  <br />
                </div>
              </div>
            </div>
            <div v-else class="empty-state">
              <img src="../img/emptyStateIllustration.svg" />
              <p>No lead data</p>
            </div>
          </div>
        </div>

        <div class="tab-content no-flex" v-if="selectedPlayerInfoTab === 'trial-tab'">
          <div class="games-info">
            <div class="two-col-pop">
              <div class="user-details-pop">
                <p><span>Phone:</span> {{ selectedUserData.phone }}</p>
                <p><span>Email:</span> {{ selectedUserData.email }}</p>
                <p>
                  <span>DOB:</span>
                  {{ formatDateForHuman(selectedUserData.dob) }}
                </p>
                <p><span>Position:</span> {{ selectedUserData.position }}</p>
                <p>
                  <span>Preferred foot:</span>
                  {{ selectedTrialistsData.preferred_foot }}
                </p>

                <p>
                  <span>Trial length:</span>
                  {{ trialLength(selectedTrialistsData.trial_length) }}
                </p>
                <p>
                  <span>Trial start date:</span>
                  {{
                    formatDateForHuman(selectedTrialistsData.trial_start_date)
                  }}
                </p>

                <p>
                  <span>Coach:</span>
                  {{ selectedTrialistsData.coach_name }}
                </p>

                <p>
                  <span>Team:</span>
                  {{ getTeamName(selectedTrialistsData.teamId) }}
                </p>

                <button style="margin-bottom: 2rem" @click="editTrialistPerformanceReview(selectedTrialistsData)"
                  class="green-button">
                  Edit trialist
                </button>
              </div>
            </div>

            <div class="trial-performance-wrapper">
              <h4>Performance review</h4>

              <div class="form__item">
                <label class="form__label" for="attitude">Attitude</label>
                <div class="ratingStars">
                  <star-rating v-model="selectedTrialistsData.attitude"></star-rating>
                </div>
              </div>

              <div class="form__item">
                <label class="form__label" for="skill">Skill</label>
                <div class="ratingStars">
                  <star-rating v-model="selectedTrialistsData.skill"></star-rating>
                </div>
              </div>

              <div class="form__item">
                <label class="form__label" for="physique">Physique</label>
                <div class="ratingStars">
                  <star-rating v-model="selectedTrialistsData.physique"></star-rating>
                </div>
              </div>

              <div class="form__item">
                <label class="form__label" for="physique">Mentality</label>
                <div class="ratingStars">
                  <star-rating v-model="selectedTrialistsData.mentality"></star-rating>
                </div>
              </div>

              <div class="form__item">
                <label class="form__label" for="physique">Leadership</label>
                <div class="ratingStars">
                  <star-rating v-model="selectedTrialistsData.leadership"></star-rating>
                </div>
              </div>

              <div class="form__item">
                <label class="form__label" for="physique">Professionalism</label>
                <div class="ratingStars">
                  <star-rating v-model="selectedTrialistsData.professionalism"></star-rating>
                </div>
              </div>

              <div class="form__item">
                <label class="form__label" for="comments">Comments</label>
                <wysiwyg v-model="selectedTrialistsData.comments" />
              </div>
            </div>

            <div class="form__item">
              <button @click="saveTrialistPerformanceReview()" class="green-button">
                Save Performance review
              </button>
            </div>
          </div>
        </div>

        <div class="tab-content no-flex" v-if="selectedPlayerInfoTab === 'player-stats-tab'">
          <div class="games-info">
            <div class="stats-wrapper">
              <div class="game-stats">
                <h3 class="no-margin">Game stats for this season</h3>
                <ul class="key-value-list">
                  <li>
                    <span>Appearances:</span>{{ gamePerformanceData.gamesPlayed }}
                  </li>
                  <li v-if="checkUserPosition() !== 'Goalkeeper'">
                    <span>Goals:</span>
                    <template v-if="gamePerformanceData.goals !== null">{{
                      gamePerformanceData.goals
                    }}</template>
                    <template v-else>0</template>
                  </li>
                  <li v-else>
                    <span>Clean sheets:</span>
                    <template v-if="gamePerformanceData.clean_sheet !== null">{{
                      gamePerformanceData.clean_sheet
                    }}</template>
                    <template v-else>0</template>
                  </li>
                  <li>
                    <span>Assists:</span>
                    {{ gamePerformanceData.assists }}
                  </li>
                  <!--<li
                          v-if="gamePerformanceData.player_self_rating !== null"
                        >
                          <span>Player performace rating:</span>
                          {{ gamePerformanceData.player_self_rating }}
                        </li>-->
                  <li v-if="gamePerformanceData.timeOnPitch !== null">
                    <span>Game time:</span>
                    {{ gamePerformanceData.timeOnPitch }} mins
                  </li>
                  <li>
                    <span>Yellow cards:</span>
                    {{ gamePerformanceData.yellowCardTotal }}
                  </li>
                  <li>
                    <span>Red cards:</span>
                    {{ gamePerformanceData.redCardTotal }}
                  </li>
                </ul>
              </div>

              <div class="playing-positions">
                <h3>Playing positions</h3>
                <div class="pitch-wrapper">
                  <img class="goalkeeper small-shirt" src="../img/player-shirt-gray.svg" alt="player-shirt" />

                  <img class="small-shirt right-full-back" src="../img/player-shirt-gray.svg" alt="player-shirt" />
                  <img class="small-shirt center-back-left" src="../img/player-shirt-gray.svg" alt="player-shirt" />
                  <img class="small-shirt center-back-right" src="../img/player-shirt-gray.svg" alt="player-shirt" />
                  <img class="small-shirt left-full-back" src="../img/player-shirt-gray.svg" alt="player-shirt" />

                  <img class="small-shirt left-midfielder" src="../img/player-shirt-gray.svg" alt="player-shirt" />
                  <img class="small-shirt defensive-midfielder" src="../img/player-shirt-gray.svg" alt="player-shirt" />
                  <img class="small-shirt attacking-midfielder" src="../img/player-shirt-gray.svg" alt="player-shirt" />
                  <img class="small-shirt right-midfielder" src="../img/player-shirt-gray.svg" alt="player-shirt" />

                  <img class="small-shirt striker-left" src="../img/player-shirt-gray.svg" alt="player-shirt" />
                  <img class="small-shirt striker-right" src="../img/player-shirt-gray.svg" alt="player-shirt" />

                  <div class="position-played-wrapper" v-for="(
position, index
                    ) in gamePerformanceData.postion_played" :key="index">
                    <img v-if="position === 'Goalkeeper'" class="goalkeeper small-shirt"
                      src="../img/player-shirt-blue.svg" alt="player-shirt" />

                    <img v-if="position === 'Right Full-back (Wingback)'" class="small-shirt right-full-back"
                      src="../img/player-shirt-blue.svg" alt="player-shirt" />
                    <img v-if="position === 'Center-back left'" class="small-shirt center-back-left"
                      src="../img/player-shirt-blue.svg" alt="player-shirt" />
                    <img v-if="position === 'Center-back right'" class="small-shirt center-back-right"
                      src="../img/player-shirt-blue.svg" alt="player-shirt" />
                    <img v-if="position === 'Left Full-back (Wingback)'" class="small-shirt left-full-back"
                      src="../img/player-shirt-blue.svg" alt="player-shirt" />

                    <img v-if="position === 'Left Midfielder (Winger)'" class="small-shirt left-midfielder"
                      src="../img/player-shirt-blue.svg" alt="player-shirt" />
                    <img v-if="
                      position === 'Defensive Midfielder' ||
                      position === 'Centre Defensive Midfield'
                    " class="small-shirt defensive-midfielder" src="../img/player-shirt-blue.svg" alt="player-shirt" />
                    <img v-if="
                      position === 'Attacking Midfielder' ||
                      position === 'Centre midfield'
                    " class="small-shirt attacking-midfielder" src="../img/player-shirt-blue.svg" alt="player-shirt" />
                    <img v-if="position === 'Right Midfielder (Winger)'" class="small-shirt right-midfielder"
                      src="../img/player-shirt-blue.svg" alt="player-shirt" />

                    <img v-if="position === 'Center Forward (Striker) left'" class="small-shirt striker-left"
                      src="../img/player-shirt-blue.svg" alt="player-shirt" />
                    <img v-if="position === 'Center Forward (Striker) right'" class="small-shirt striker-right"
                      src="../img/player-shirt-blue.svg" alt="player-shirt" />
                  </div>

                  <img src="../img/pitch.svg" alt="pitch" class="pitch-svg" id="pitch_svg" />
                </div>
              </div>

              <div class="training-scores">
                <h3 class="no-margin">Training stats for this season</h3>
                <ul v-if="trainingPerformanceData.traningAttended > 0" class="key-value-list">
                  <li>
                    <span>Sessions attended:</span>{{ trainingPerformanceData.traningAttended }}
                  </li>
                  <li>
                    <span>Training hours this season:</span>{{ trainingPerformanceData.time }} mins
                  </li>
                  <li>
                    <span>Last Training session rating:</span>{{ trainingPerformanceData.enjoyment }}
                  </li>

                  <span v-if="gamePerformanceData.created_date !== undefined"
                    class="small-date-player-coach full-width-error" :class="`days-${checkDateTimeFromNow(
                      gamePerformanceData.created_date
                    )}`">
                    Updated on
                    {{ formatDate(gamePerformanceData.created_date) }}</span>
                </ul>
                <p v-else>
                  You have not attened any training sessions this seasion. You
                  must attened at least one training session and submit a
                  traiing repoort to view training statistics.
                </p>

                <div v-if="trainingPerformanceData.effort == '10'" class="rpe-scale-result ten">
                  <span class="big-number"><b-icon icon="stopwatch" aria-hidden="true"></b-icon>
                    10</span>
                  <h3>MAX EFFORT ACTIVITY</h3>
                  <p>
                    Enjoyment rating: {{ trainingPerformanceData.enjoyment }}
                  </p>
                </div>

                <div v-if="trainingPerformanceData.effort == '9'" class="rpe-scale-result nine">
                  <span class="big-number"><b-icon icon="stopwatch" aria-hidden="true"></b-icon>
                    9</span>
                  <h3>VERY HARD ACTIVITY</h3>
                  <p>
                    Enjoyment rating: {{ trainingPerformanceData.enjoyment }}
                  </p>
                </div>

                <div v-if="trainingPerformanceData.effort == '8'" class="rpe-scale-result eight">
                  <span class="big-number"><b-icon icon="stopwatch" aria-hidden="true"></b-icon>
                    8</span>
                  <h3>VIGOROUS ACTIVITY</h3>
                  <p>
                    Enjoyment rating: {{ trainingPerformanceData.enjoyment }}
                  </p>
                </div>

                <div v-if="trainingPerformanceData.effort == '7'" class="rpe-scale-result four-six">
                  <span class="big-number"><b-icon icon="stopwatch" aria-hidden="true"></b-icon>
                    7</span>
                  <h3>MODERATE ACTIVITY</h3>
                  <p>
                    Enjoyment rating: {{ trainingPerformanceData.enjoyment }}
                  </p>
                </div>

                <div v-if="trainingPerformanceData.effort == '6'" class="rpe-scale-result two-three">
                  <span class="big-number"><b-icon icon="stopwatch" aria-hidden="true"></b-icon>
                    6</span>
                  <h3>LIGHT ACTIVITY</h3>
                  <p>
                    Enjoyment rating: {{ trainingPerformanceData.enjoyment }}
                  </p>
                </div>

                <div v-if="trainingPerformanceData.effort == '5'" class="rpe-scale-result one">
                  <span class="big-number"><b-icon icon="stopwatch" aria-hidden="true"></b-icon>
                    5</span>
                  <h3>VERY LIGHT ACTIVITY</h3>
                  <p>
                    Enjoyment rating: {{ trainingPerformanceData.enjoyment }}
                  </p>
                </div>
                <div v-if="trainingPerformanceData.effort === undefined">
                  <div class="empty-state">
                    <img src="../img/emptyStateIllustration.svg" />
                    <p>No session effort rating data</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="tab-content no-flex" v-if="selectedPlayerInfoTab === 'player-history-tab'">
          <div class="games-info">
            <div class="button-wrapper-top">
              <b-button class="main-button" @click="produceThePlayerCV()" size="lg" v-b-tooltip.hover
                title="View players c.v" variant="outline-success"><b-icon icon="eye"></b-icon></b-button>
            </div>

            <div class="player-cv">
              <section class="cv-section">
                <h3>Personal profile</h3>

                <p v-if="playerCvData.personal_profile !== ''">
                  {{ playerCvData.personal_profile }}
                </p>
                <p v-else>Add your personal Profile.</p>

                <b-button variant="outline-warning" @click="openPlayerCVpersonalProfileModal()"><b-icon icon="pencil"
                    aria-hidden="true"></b-icon>
                  Edit</b-button>
              </section>
              <section class="cv-section">
                <h3>PLAYING EXPERIENCE</h3>

                <p v-if="playerCvData.playingExperence.length === 0">
                  Add your playing Experence
                </p>

                <table v-else style="margin-bottom: 0.5rem" class="w-a-style">
                  <thead>
                    <tr>
                      <th>Club</th>
                      <th>Time at the club</th>
                      <th>Playing stats</th>
                      <th>Actions</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr v-for="(item, index) in playerCvData.playingExperence" :key="index">
                      <td>{{ item.club }}</td>
                      <td>
                        {{ formatDateForHuman(item.start_date_at_club) }} -
                        {{ formatDateForHuman(item.end_date_at_club) }}
                      </td>
                      <td>{{ item.playing_info }}</td>
                      <td>
                        <div class="buttons-row">
                          <b-button @click="openPlayerCVPlayingExperenceModal(index)" size="lg"
                            variant="outline-success">
                            Update</b-button>

                          <b-button @click="deletePlayingExperence(item.id)" size="lg"
                            variant="outline-danger">Delete</b-button>
                        </div>
                      </td>
                    </tr>
                  </tbody>
                </table>

                <b-button variant="outline-success" @click="openPlayerCVPlayingExperenceModal()"><b-icon icon="pencil"
                    aria-hidden="true"></b-icon>
                  Add</b-button>
              </section>

              <section class="cv-section">
                <div class="education">
                  <h3>EDUCATION</h3>

                  <p v-if="playerCvData.education.length === 0">
                    Add your education
                  </p>

                  <ul style="margin-bottom: 0.5rem">
                    <li v-for="(item, index) in playerCvData.education" :key="index">
                      <span class="title">{{ item.title }}</span>
                      <span class="info">{{ item.start_date }} - {{ item.end_date }}</span>
                      <span class="info">{{ item.more_info }}</span>
                      <div class="buttons-row">
                        <b-button @click="openPlayerEducationModal(index)" size="lg" variant="outline-success">
                          Update</b-button>

                        <b-button @click="deletePlayerEducation(item.id)" size="lg"
                          variant="outline-danger">Delete</b-button>
                      </div>
                    </li>
                  </ul>

                  <b-button variant="outline-success" @click="openPlayerEducationModal()"><b-icon icon="pencil"
                      aria-hidden="true"></b-icon>
                    Add</b-button>
                </div>
              </section>

              <section class="cv-section">
                <div class="hobbies">
                  <h3>CAREER STATISTICS</h3>

                  <!--<ul>
                      <li>Goals: 31</li>
                      <li>Shots: 104</li>
                      <li>Assists: 43</li>
                      <li>Pass completion: 84.6%</li>
                    </ul>-->
                </div>
              </section>

              <section class="cv-section">
                <div class="hobbies">
                  <h3>Interest hobbies outside of football</h3>

                  <p v-if="playerCvData.interests_hobbies.length === 0">
                    Add your interest and hobbies
                  </p>
                  <table v-else style="margin-bottom: 0.5rem" class="w-a-style">
                    <thead>
                      <tr>
                        <th>Interest hobbies</th>
                        <th>More information</th>
                        <th>Actions</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr v-for="(item, index) in playerCvData.interests_hobbies" :key="index">
                        <td>{{ item.interests }}</td>
                        <td>
                          {{ item.more_info }}
                        </td>

                        <td>
                          <div class="buttons-row">
                            <b-button @click="openPlayerInterestsHobbiesModal(index)" size="lg"
                              variant="outline-success">
                              Update</b-button>

                            <b-button @click="deletePlayerInterestsHobbies(item.id)" size="lg"
                              variant="outline-danger">Delete</b-button>
                          </div>
                        </td>
                      </tr>
                    </tbody>
                  </table>

                  <b-button variant="outline-success" @click="openPlayerInterestsHobbiesModal()"><b-icon icon="pencil"
                      aria-hidden="true"></b-icon>
                    Add</b-button>
                </div>
              </section>

              <section class="cv-section">
                <div class="players-videos">
                  <h3>Videos</h3>
                  <p v-if="playerCvData.videos.length === 0">
                    Add your football videos
                  </p>

                  <div class="sessions-info" style="margin-bottom: 0.5rem">
                    <div class="video-wrapper" v-for="(item, index) in playerCvData.videos" :key="index">
                      <a v-if="item.category === ''" target="_blank" :href="item.video_url" class="session-card">
                        <img src="./img/video.jpg" alt="" />
                        <p class="small-date">Video</p>
                        <h3>{{ item.video_name }}</h3>
                      </a>

                      <div class="buttons-row" v-if="item.category === ''">
                        <b-button @click="openPlayerVideosModal(index)" size="lg" variant="outline-success">
                          Update</b-button>

                        <b-button @click="deletePlayerVideoLink(item.id)" size="lg"
                          variant="outline-danger">Delete</b-button>
                      </div>
                    </div>
                  </div>
                </div>

                <b-button variant="outline-success" @click="openPlayerVideosModal()"><b-icon icon="pencil"
                    aria-hidden="true"></b-icon>
                  Add</b-button>
              </section>
            </div>
          </div>
        </div>

        <div class="tab-content no-flex" v-if="selectedPlayerInfoTab === 'player-documents-tab'">
          <div class="games-info">
            <DocumentUploader @getDocumentUploadsCall="getDocumentUploadsCall" documentCategory="player"
              :club_id="userData.clubId" :user_id="userData.id"
              :selectedDocumentData="selectedDocumentData.approvedAccessList" />

            <div class="document" v-for="document in uploadedDocuments" :key="document.id" v-b-tooltip.hover
              :title="removeNumberFromStart(document.name)">
              <a :href="`./${document.url}`" target="_blank">
                <img src="../img/documentlib.svg" />
                <h3>{{ removeNumberFromStart(document.name) }}</h3>
              </a>
            </div>
            <div v-if="uploadedDocuments.length === 0">
              <div class="empty-state">
                <img src="../img/emptyStateIllustration.svg" />
                <p>No documents</p>
              </div>
            </div>
          </div>
        </div>

        <div class="tab-content no-flex" v-if="selectedPlayerInfoTab === 'player-report-tab'">
          <div class="games-info">
            <b-button class="main-button create-academy-report-button" @click="editAcademyReportModal()" size="lg"
              variant="outline-warning" style="margin-right: 1rem">
              <b-icon icon="pencil" aria-hidden="true"></b-icon> Edit player
              report</b-button>

            <div class="player-report-content">
              <iframe v-if="showReport === true" id="theAcademyReportPdf_preview" src="" />
            </div>
          </div>
        </div>

        <div class="tab-content no-flex" v-if="selectedPlayerInfoTab === 'player-videos-tab'">
          <div class="games-info">
            <b-button variant="outline-success" @click="openPlayerVideosModal()"><b-icon icon="pencil"
                aria-hidden="true"></b-icon> Add</b-button>

            <div class="players-videos">
              <div v-if="playerCvData.videos.length === 0">
                <div class="empty-state">
                  <img src="../img/emptyStateIllustration.svg" />
                  <p>No players videos</p>
                </div>
              </div>

              <div class="sessions-info" style="margin-bottom: 0.5rem">
                <div class="video-wrapper" v-for="(item, index) in playerCvData.videos" :key="index">
                  <a v-if="item.category === ''" target="_blank" :href="item.video_url" class="session-card">
                    <img src="./img/video.jpg" alt="" />
                    <p class="small-date">Video</p>
                    <h3>{{ item.video_name }}</h3>
                  </a>

                  <div class="buttons-row" v-if="item.category === ''">
                    <b-button @click="openPlayerVideosModal(index)" size="lg" variant="outline-success">
                      Update</b-button>

                    <b-button @click="deletePlayerVideoLink(item.id)" size="lg"
                      variant="outline-danger">Delete</b-button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </b-modal>

    <b-modal id="ViewTrialist" size="lg" title="Trialist performance review" :hide-footer="Boolean(true)">
      <div class="two-col-pop">
        <div class="user-photo-info">
          <img v-if="
            selectedTrialistsData.userImage !== '' &&
            selectedTrialistsData.userImage !== null
          " :src="getImgUrl(selectedTrialistsData.userImage)" alt="" />
          <img v-else :src="getImgUrl('default-image.jpg')" alt="" />
        </div>
        <div class="user-details-pop">
          <p><span>Name:</span> {{ selectedTrialistsData.name }}</p>
          <p><span>Phone:</span> {{ selectedTrialistsData.phone }}</p>
          <p><span>Email:</span> {{ selectedTrialistsData.email }}</p>
          <p>
            <span>DOB:</span>
            {{ formatDateForHuman(selectedTrialistsData.dob) }}
          </p>
          <p><span>Position:</span> {{ selectedTrialistsData.position }}</p>
          <p>
            <span>Trial length:</span>
            {{ trialLength(selectedTrialistsData.trial_length) }}
          </p>
          <p>
            <span>Trial start date:</span>
            {{ formatDateForHuman(selectedTrialistsData.trial_start_date) }}
          </p>
          <p>
            <span>Preferred foot:</span>
            {{ selectedTrialistsData.preferred_foot }}
          </p>

          <p>
            <span>Team:</span> {{ getTeamName(selectedTrialistsData.teamId) }}
          </p>

          <p>
            <span>Coach:</span>
            {{ selectedTrialistsData.coach_name }}
          </p>
        </div>
      </div>

      <h4>Performance review</h4>

      <div class="form__item">
        <label class="form__label" for="attitude">Attitude</label>
        <div class="ratingStars">
          <star-rating v-model="selectedTrialistsData.attitude"></star-rating>
        </div>
      </div>

      <div class="form__item">
        <label class="form__label" for="skill">Skill</label>
        <div class="ratingStars">
          <star-rating v-model="selectedTrialistsData.skill"></star-rating>
        </div>
      </div>

      <div class="form__item">
        <label class="form__label" for="physique">Physique</label>
        <div class="ratingStars">
          <star-rating v-model="selectedTrialistsData.physique"></star-rating>
        </div>
      </div>

      <div class="form__item">
        <label class="form__label" for="physique">Mentality</label>
        <div class="ratingStars">
          <star-rating v-model="selectedTrialistsData.mentality"></star-rating>
        </div>
      </div>

      <div class="form__item">
        <label class="form__label" for="physique">Leadership</label>
        <div class="ratingStars">
          <star-rating v-model="selectedTrialistsData.leadership"></star-rating>
        </div>
      </div>

      <div class="form__item">
        <label class="form__label" for="physique">Professionalism</label>
        <div class="ratingStars">
          <star-rating v-model="selectedTrialistsData.professionalism"></star-rating>
        </div>
      </div>

      <div class="form__item">
        <label class="form__label" for="comments">Comments</label>
        <wysiwyg v-model="selectedTrialistsData.comments" />
      </div>

      <div class="out-come-buttons" v-if="selectedTrialistsData.outcome === 0">
        <button @click="recordOutcome('1')" class="green-button">
          <b-icon icon="pen" aria-hidden="true"></b-icon> Sign
        </button>
        <button @click="recordOutcome('2')" class="red-button">
          <b-icon icon="exclamation-circle" aria-hidden="true"></b-icon> Reject
        </button>
        <!--<button @click="recordOutcome('3')" class="yellow-button">Waiting list</button>-->
      </div>

      <p>
        When you sign a player, they will be automatically registered with the
        team they tried out for.
      </p>

      <div class="form__item">
        <button @click="saveTrialistPerformanceReview()" class="green-button">
          Save trialist
        </button>
      </div>
    </b-modal>

    <b-modal id="playerVideosModal" size="lg" :hide-footer="Boolean(true)" title="Personal profile">
      <div class="player-cv-content">
        <div class="playing-experence-wrapper">
          <div class="each-target playing-experence-item">
            <div class="form__item">
              <label class="form__label" for="contact">Video title:</label>
              <input class="form__input" type="text" id="club" v-model="selectedPlayerVideoData.video_name" />
            </div>

            <div class="form__item">
              <label class="form__label" for="contact">Video URL:</label>
              <input class="form__input" type="text" id="club" v-model="selectedPlayerVideoData.video_url" />
            </div>

            <b-button v-b-tooltip.hover title="Save" variant="success" v-if="selectedPlayerVideoDataEdit === false"
              @click="savePlayerVideoLink()">Save video</b-button>

            <b-button v-b-tooltip.hover title="Update" variant="success" v-else @click="editPlayerVideoLink()">Update
              video</b-button>
          </div>
        </div>
      </div>
    </b-modal>

    <b-modal id="gameAttendanceReportModal" size="lg" :hide-footer="Boolean(true)" title="Game attendance report">
      <div class="performance-target-modal-content form">
        <div class="form__item">
          <label class="form__label" for="name">Home team name</label>
          <input class="form__input" type="text" v-model="selectedMatchAttendedData.homeTeam" id="homeTeam" />
        </div>

        <div class="form__item">
          <label class="form__label" for="name">Away team name</label>
          <input class="form__input" type="text" v-model="selectedMatchAttendedData.awayTeam" id="awayTeam" />
        </div>

        <div class="form__item">
          <label class="form__label" for="name">Age group</label>

          <datepicker v-model="selectedMatchAttendedData.age_group" :format="DatePickerFormat" minimum-view="year"
            name="datepicker" id="input-id" input-class="input-class">
          </datepicker>
        </div>

        <div class="form__item">
          <label class="form__label" for="gameDate">Match date</label>

          <datepicker v-model="selectedMatchAttendedData.matchDate" name="gameDate" id="gameDate-id"
            input-class="input-class">
          </datepicker>
        </div>

        <div class="form__item">
          <label class="form__label" for="weather_conditions">Weather conditions</label>
          <input class="form__input" type="text" v-model="selectedMatchAttendedData.weather_conditions"
            id="weather_conditions" />
        </div>

        <div class="form__item">
          <label class="form__label" for="textarea-game-comments">Game Comments</label>
          <b-form-textarea id="textarea-game-comments" placeholder="Add game comments"
            v-model="selectedMatchAttendedData.game_comments"></b-form-textarea>
        </div>

        <div class="form__item">
          <label class="form__label" for="position">Scout attending game</label>
          <select id="position" @change="populateAssignedScout($event, 'game attendance')">
            <option disabled value="">Please select a state</option>
            <option value="">Mark as unassigned</option>
            <option v-for="scout in allScoutsData" :key="scout.id" :value="scout.id"
              :selected="scout.id === selectedMatchAttendedData.scoutId">
              {{ scout.name }}
            </option>
          </select>

          <p v-if="
            selectedMatchAttendedData.scoutId !== undefined &&
            selectedMatchAttendedData.scoutId !== null
          ">
            Assigned scout:
            {{ getScoutNameFromId(selectedMatchAttendedData.scoutId) }}
          </p>
        </div>

        <label class="form__label" for="perfomance-targets">Add player to be assessed

          <b-button v-b-tooltip.hover title="Add player to be assessed" @click="addItem()" variant="outline-success">
            <b-icon icon="person" aria-hidden="true"></b-icon>
          </b-button>
        </label>

        <div class="player-watched-details-wrapper" v-if="selectedMatchAttendedData.playersGameData.length > 0">
          <div class="player-watched-details" v-for="(item, index) in selectedMatchAttendedData.playersGameData"
            :key="index">
            <div class="buttons-wrapper">
              <b-button v-if="item.leadId === null" v-b-tooltip.hover title="Add player as lead"
                variant="outline-success" @click="openLeadsModal('scout report lead', item)">
                <b-icon icon="person" aria-hidden="true"></b-icon></b-button>
              <p class="player-added" v-if="item.leadId !== null">
                <b-icon icon="person" aria-hidden="true"></b-icon> Player added
                as lead
              </p>

              <!--<b-button
                  v-b-tooltip.hover
                  title="Invite player for trial"
                  variant="outline-success"
                >
                  <b-icon
                    v-if="item.trialistId === null"
                    icon="person"
                    aria-hidden="true"
                    @click="removeItem(index)"
                  ></b-icon
                ></b-button>-->
              <p v-if="item.trialistId !== null">Player invited for trial</p>

              <b-button v-if="item.leadId === null" v-b-tooltip.hover title="remove player" variant="outline-danger">
                <b-icon icon="x" aria-hidden="true" @click="removeItem(index, item.id)"></b-icon></b-button>
            </div>

            <div class="form__item">
              <label class="form__label" for="name">Players name</label>
              <input class="form__input" type="text" v-model="item.name" id="name" />
            </div>

            <div class="form__item">
              <label class="form__label" for="team">Players team</label>
              <input class="form__input" type="text" v-model="item.team" id="team" />
            </div>

            <div class="form__item">
              <label class="form__label" for="phone">Phone number</label>
              <input class="form__input" type="number" v-model="item.phone" id="phone" />
            </div>

            <div class="form__item">
              <label class="form__label" for="position">Position played</label>
              <select id="position" v-model="item.position">
                <option disabled value="">Please select a position</option>
                <option :selected="item.position === 'Goalkeeper'" value="Goalkeeper">
                  Goalkeeper
                </option>
                <option :selected="item.position === 'Right Full-back (Wingback)'" value="Right Full-back (Wingback)">
                  Right Full-back (Wingback)
                </option>
                <option :selected="item.position === 'Left Full-back (Wingback)'" value="Left Full-back (Wingback)">
                  Left Full-back (Wingback)
                </option>
                <option :selected="item.position === 'Center-back left'" value="Center-back left">
                  Center-back left
                </option>
                <option :selected="item.position === 'Center-back right'" value="Center-back right">
                  Center-back right
                </option>
                <option :selected="item.position === 'Defensive Midfielder'" value="Defensive Midfielder">
                  Defensive Midfielder
                </option>
                <option :selected="item.position === 'Centre midfield'" value="Centre midfield">
                  Centre midfield
                </option>
                <option :selected="item.position === 'Centre Defensive Midfield'" value="Centre Defensive Midfield">
                  Centre Defensive Midfield
                </option>
                <option :selected="item.position === 'Right Midfielder (Winger)'" value="Right Midfielder (Winger)">
                  Right Midfielder (Winger)
                </option>
                <option :selected="item.position === 'Left Midfielder (Winger)'" value="Left Midfielder (Winger)">
                  Left Midfielder (Winger)
                </option>
                <option :selected="item.position === 'Attacking Midfielder'" value="Attacking Midfielder">
                  Attacking Midfielder
                </option>
                <option :selected="item.position === 'Center Forward (Striker) left'"
                  value="Center Forward (Striker) left">
                  Center Forward (Striker) left
                </option>
                <option :selected="item.position === 'Center Forward (Striker) right'"
                  value="Center Forward (Striker) right">
                  Center Forward (Striker) right
                </option>
                <option :selected="item.position === 'I played more than one position'
                  " value="I played more than one position">
                  I played more than one position
                </option>
              </select>
            </div>

            <div class="form__item">
              <label class="form__label" for="attitude">Attitude</label>
              <div class="ratingStars">
                <star-rating v-model="item.attitude"></star-rating>
              </div>
            </div>

            <div class="form__item">
              <label class="form__label" for="skill">Skill</label>
              <div class="ratingStars">
                <star-rating v-model="item.skill"></star-rating>
              </div>
            </div>

            <div class="form__item">
              <label class="form__label" for="physique">Physique</label>
              <div class="ratingStars">
                <star-rating v-model="item.physique"></star-rating>
              </div>
            </div>

            <div class="form__item">
              <label class="form__label" for="physique">Mentality</label>
              <div class="ratingStars">
                <star-rating v-model="item.mentality"></star-rating>
              </div>
            </div>

            <div class="form__item">
              <label class="form__label" for="physique">Leadership</label>
              <div class="ratingStars">
                <star-rating v-model="item.leadership"></star-rating>
              </div>
            </div>

            <div class="form__item">
              <label class="form__label" for="physique">Professionalism</label>
              <div class="ratingStars">
                <star-rating v-model="item.professionalism"></star-rating>
              </div>
            </div>

            <div class="form__item">
              <label class="form__label" for="comments">Overall Assessment</label>
              <wysiwyg v-model="item.comments" />
            </div>
          </div>

          <b-button v-b-tooltip.hover v-if="editMode === false" title="Save" variant="success"
            @click="saveScoutGameAttendanceReport()">Save</b-button>

          <b-button v-b-tooltip.hover v-else title="Save" variant="success"
            @click="updateScoutGameAttendanceReport()">Edit</b-button>
        </div>
      </div>
    </b-modal>

    <b-modal id="editAcademyReportModal" size="lg" :hide-footer="Boolean(true)">
      <div class="performance-target-modal-content">
        <h3>Edit Player report</h3>

        <div class="form__item">
          <label class="form__label" for="coaches_comments">Coaches comments:</label>

          <b-form-textarea id="coaches_comments" v-model="academyReport.coaches_comments"
            placeholder="Enter your comments..." rows="3" max-rows="6"></b-form-textarea>
        </div>

        <div class="form__item">
          <label class="form__label" for="contact">Coaches average score:</label>
          <input class="form__input" type="number" id="average_score" v-model="academyReport.average_score" />
          / 30
        </div>

        <div class="buttons-row">
          <b-button size="lg" variant="outline-warning" @click="updateTheAcademyReport(academyReport.id)">
            Update Player report</b-button>
        </div>
      </div>
    </b-modal>

    <b-modal id="extendTrialModal" size="lg" :hide-footer="Boolean(true)">
      <div class="performance-target-modal-content form">
        <h3>Extend trial</h3>

        <div class="form__item">
          <label class="form__label" for="coaches_comments">Players name</label>
          <p>{{ extendTrial.name }}</p>
        </div>

        <div class="form__item">
          <label class="form__label" for="coachSelect">Select trial length (weeks)</label>

          <input class="form__input" type="number" value="0" min="0" max="20" step="1"
            v-model="extendTrial.trial_length" id="trailLength" />
        </div>

        <div class="form__item uk-form dob">
          <label class="form__label" for="trialStartDate">Trial extention start date</label>
          <datepicker v-model="extendTrial.trial_start_date" name="trialStartDate" id="trialStartDate" />
        </div>

        <div class="buttons-row">
          <b-button size="lg" variant="outline-success" @click="extendTrial()">
            Extend Trial</b-button>
        </div>
      </div>
    </b-modal>

    <b-modal id="viewPlayersCV" size="lg" :hide-footer="Boolean(true)">
      <div class="player-cv-content">
        <iframe id="thePlayerCVPDF_preview" src="" />
      </div>
    </b-modal>

    <b-modal id="playerCVpersonalProfileModal" size="lg" :hide-footer="Boolean(true)" title="Personal profile">
      <div class="player-cv-content">
        <b-form-textarea id="personal_profile_text_box" v-model="playerCvData.personal_profile"
          placeholder="Enter your personal proflie..." rows="8"></b-form-textarea>

        <b-button v-b-tooltip.hover @click="savePersonalProfile()" title="Save" variant="success">Save Personal
          profile</b-button>
      </div>
    </b-modal>

    <b-modal id="playerCVPlayingExperenceModal" size="lg" :hide-footer="Boolean(true)"
      @close="playerCVPlayingExperenceModalClose()" title="Playing experence">
      <div class="player-cv-content">
        <div class="playing-experence-wrapper">
          <div class="each-target playing-experence-item">
            <div class="form__item">
              <label class="form__label" for="contact">Club name:</label>
              <input class="form__input" type="text" id="club" v-model="selectedPlayingExperenceData.club" />
            </div>

            <div class="form__item">
              <label class="form__label" for="contact">Start date at the club:</label>
              <datepicker v-model="selectedPlayingExperenceData.start_date_at_club" name="startDateAtClub"
                id="startDateAtClub" />
            </div>

            <div class="form__item">
              <label class="form__label" for="contact">End date at the club:</label>
              <datepicker v-model="selectedPlayingExperenceData.end_date_at_club" name="endDateAtClub"
                id="endDateAtClub" />
            </div>

            <div class="form__item">
              <label class="form__label" for="perfomance-targets"> </label>

              <b-form-textarea id="textarea" v-model="selectedPlayingExperenceData.playing_info"
                placeholder="Enter Playing information..." rows="3" max-rows="6"></b-form-textarea>
            </div>
          </div>

          <b-button v-b-tooltip.hover title="Save" variant="success" v-if="selectedPlayingExperenceEdit === false"
            @click="savePlayerPlayingExperence()">Save playing experence</b-button>

          <b-button v-b-tooltip.hover title="Update" variant="success" v-else @click="editPlayingExperence()">Update
            playing
            experence</b-button>
        </div>
      </div>
    </b-modal>

    <b-modal id="openPlayerEducationModal" size="lg" :hide-footer="Boolean(true)" @close="playerEducationModalClose()"
      title="Player Education">
      <div class="player-cv-content">
        <div class="playing-experence-wrapper">
          <div class="each-target playing-experence-item">
            <div class="form__item">
              <label class="form__label" for="contact">Qualification name:</label>
              <input class="form__input" type="text" id="Qualification_name"
                v-model="selectedPlayerEducationData.title" />
            </div>

            <div class="form__item">
              <label class="form__label" for="contact">Start date:</label>
              <datepicker v-model="selectedPlayerEducationData.start_date" name="start_date" id="start_date" />
            </div>

            <div class="form__item">
              <label class="form__label" for="contact">End date:</label>
              <datepicker v-model="selectedPlayerEducationData.end_date" name="end_date" id="end_date" />
            </div>

            <div class="form__item">
              <label class="form__label" for="perfomance-targets">Information
              </label>

              <b-form-textarea id="textarea" v-model="selectedPlayerEducationData.more_info"
                placeholder="Enter more information..." rows="3" max-rows="6"></b-form-textarea>
            </div>
          </div>

          <b-button v-b-tooltip.hover title="Save" variant="success" v-if="selectedPlayerEducationDataEdit === false"
            @click="savePlayerEducation()">Save education</b-button>

          <b-button v-b-tooltip.hover title="Update" variant="success" v-else @click="editPlayerEducation()">Update
            education</b-button>
        </div>
      </div>
    </b-modal>

    <b-modal id="openPlayerInterestsHobbiesModal" size="lg" :hide-footer="Boolean(true)"
      @close="playerInterestsHobbiesClose()" title="Player Interests and Hobbies">
      <div class="player-cv-content">
        <div class="form__item">
          <label class="form__label" for="contact">Interest or Hobbie:</label>
          <input class="form__input" type="text" id="interests"
            v-model="selectedPlayerInterestsHobbiesData.interests" />
        </div>

        <div class="form__item">
          <label class="form__label" for="contact">More information:</label>

          <b-form-textarea id="textarea" v-model="selectedPlayerInterestsHobbiesData.more_info"
            placeholder="Enter more information..." rows="3" max-rows="6"></b-form-textarea>
        </div>

        <b-button v-b-tooltip.hover title="Save" variant="success"
          v-if="selectedPlayerInterestsHobbiesDataEdit === false" @click="saveInterestsHobbies()">Save
          interest</b-button>

        <b-button v-b-tooltip.hover title="Update" variant="success" v-else @click="editPlayerInterestsHobbies()">Update
          interest</b-button>
      </div>
    </b-modal>

    <img src="./img/video-icon.jpg" alt="" class="visually-hidden" id="video-icon" />

    <img src="./img/pitch-potions.jpg" alt="" class="visually-hidden" id="pitch_potions" />
    <img src="./img/mood-example.jpg" alt="" class="visually-hidden" id="mood-example_image" />
  </div>
</template>
<script>
import Vue from "vue";
import SpinnerLoader from "../../components/LoadingSpinning";
import AppTemplate from "../layouts/App";
import { mapGetters } from "vuex";
import { USER_POPULATE } from "@/store/actions/user.js";
import { AUTH_SIGNUP } from "@/store/actions/auth";
import dayjs from "dayjs";
import { Users } from "@/services/user-service.js";
import { Trialists } from "@/services/trialist-service.js";
import { Documents } from "@/services/documents-service.js";
import { PlayerCv } from "@/services/player-cv-service.js";
import { Games } from "@/services/games-service.js";
import Datepicker from "vuejs-datepicker";
import { Teams } from "@/services/teams-service.js";
import { AcademyReport } from "@/services/academy-report-service.js";
import ImageUploader from "../../components/ImageUploader";
import StarRating from "vue-star-rating";
import moment from "moment";
import removeDuplicatesMixin from "@/mixin/removeDuplicates.js";
import DocumentUploader from "../../components/doucmentUploader";
import JsPDF from "jspdf";
import extendSeasonEndDateToFullYearMixin from "@/mixin/extendSeasonEndDateToFullYear.js";
import VueGoodTable from "vue-good-table";
import "vue-good-table/dist/vue-good-table.css";

Vue.use(VueGoodTable);

export default {
  name: "Trialists",
  mixins: [removeDuplicatesMixin, extendSeasonEndDateToFullYearMixin],
  components: {
    AppTemplate,
    SpinnerLoader,
    Datepicker,
    ImageUploader,
    StarRating,
    DocumentUploader,
  },
  data() {
    return {
      loadingStuff: false,
      columnsScoutGameAttendance: [
        {
          label: "Match Date",
          field: "matchDate",
        },
        {
          label: "Game information",
          field: "teams",
        },
        {
          label: "Age group",
          field: "age_group",
        },
        {
          label: "Assigned Scout",
          field: "scoutId",
        },

        {
          label: "Actions",
          width: "6rem",
          field: "actions",
        },
      ],
      columnsPlayerLeads: [
        {
          label: "",
          width: "6rem",
          field: "userImage",
        },
        {
          label: "Date",
          field: "date",
        },
        {
          label: "Player",
          field: "name",
        },
        {
          label: "Age group",
          field: "age_group",
        },
        {
          label: "Status",
          field: "state",
        },
        {
          label: "Assigned Scout",
          field: "scout_name",
        },

        {
          label: "Actions",
          width: "6rem",
          field: "actions",
        },
      ],
      selectedFilterData: null,
      toggleFilters: false,
      hideRemoveTeamFilterButton: true,
      liveTrialistsDataFiltered: [],
      extendTrial: {
        id: null,
        name: "",
        trial_length: "",
      },
      selectedPlayerInterestsHobbiesData: {},
      selectedPlayerInterestsHobbiesDataEdit: false,
      selectedPlayerEducationData: {},
      selectedPlayerEducationDataEdit: false,
      selectedPlayingExperenceData: {},
      selectedPlayingExperenceEdit: false,
      selectedGameFullPerformanceReportGPSdataForUser: {},
      playerCvData: {
        personal_profile: "",
        playingExperence: [],
        education: [],
        interests_hobbies: [],
        videos: [],
      },
      allScoutGameAttendanceReports: [],
      selectedUserData: {},
      allPlayersLeadData: [],
      selectedLeadData: {},
      allPlayerLeadsData: [],
      DatePickerFormat: "yyyy",
      DatePickerFormatFull: "dd-mm-yyyy",
      needsValidation: [],
      validationMessage: null,
      validationValues: {
        name: {
          state: false,
          text: "Please add a name please!",
        },
        email: {
          state: false,
          text: "Please add an email address",
        },
        password: {
          state: false,
          text: "Please add password please!",
        },
        dob: {
          state: false,
          text: "Please add a date of birth please!",
        },
      },
      editMode: false,
      trialistsData: [],
      liveTrialistsData: [],
      filteredTrialistsData: [],
      signedtrialistsData: [],
      archivedtrialistsData: [],
      coachesUsersData: [],
      selectedTab: "trialist-tab",
      teamData: [],
      clubTeams: [],
      userData: {
        name: "",
        userType: "",
      },
      userHeaderData: {
        name: "",
        userType: "",
      },
      selectedUserDataForHeader: {},
      selectedUserValue: "",
      weekSelectedNumber: "",
      selectedTrialistsData: {
        dob: "",
        trial_length: "6",
        trial_start_date: "",
        preferred_foot: "Right",
        email: "",
        name: "",
        position: "",
        attitude: 0,
        skill: 0,
        physique: 0,
        mentality: 0,
        leadership: 0,
        professionalism: 0,
        comments: "",
        outcome: "0",
        clubId: "",
        teamId: "",
        userType: "User",
      },
      imageFile: null,
      imageToBeRemoved: null,
      imageURL: "",
      image: {
        imageName: "",
        path: "",
        file: null,
      },
      registerUser: {
        img: "",
        name: "",
        email: "",
        password: "",
        dob: "",
        position: "",
        phone: "",
        emergency_contact_one: "",
        userType: "User",
        action: "register",
        teamId: 0,
        permission: 0,
        clubId: null,
        coach_id: 0,
        team_module: 1,
        preferred_foot: 0,
        joined_date: "",
        medical: "",
      },
      coachData: [],
      selectedPlayerInfoTab: "lead-tab",
      uploadedDocuments: [],
      documentData: {
        name: "",
        text: "",
        created_date: moment().format("YYYY-MM-DD"),
        updated_date: null,
        created_by: null,
        edited_by: null,
        signature: false,
        approvedAccessList: {
          teams: [],
          users: [],
        },
      },
      metaDataReults: [],
      selectedDocumentData: {
        approvedAccessList: {
          teams: [],
          users: [],
          category: "player",
        },
      },
      selectedPlayerVideoData: {},
      selectedPlayerVideoDataEdit: false,
      showReport: false,
      academyReport: {
        id: null,
        player_id: null,
        seasion: null,
        average_score: 0,
        coaches_comments: "",
        seasion: "",
      },
      season_start_date: "",
      season_end_date: "",
      gamePerformanceData: {
        gameId: null,
        postion_played: [],
        goals: 0,
        assists: 0,
        clean_sheet: null,
        player_self_rating: null,
        yellowCardTotal: 0,
        redCardTotal: 0,
        redCardTotal: 0,
        timeOnPitch: null,
        gamesPlayed: null,
      },
      allGamesData: [],
      playerPhysicalPerformanceData: {},
      trainingPerformanceData: {},
      seasonsgamePerformanceReportsForUser: [],
      seasonsTraningPerformanceReportsForUser: [],
      allScoutsData: [],
      selectedMatchAttendedData: {
        clubId: "",
        homeTeam: "",
        awayTeam: "",
        age_group: "",
        matchDate: "",
        playersGameData: [],
        scoutId: null,
        scoutName: "",
      },
    };
  },
  computed: {
    // mix the getters into computed with object spread operator
    ...mapGetters(["getProfile", "getCoachesUsersData"]),
  },
  created() {
    const now = moment().format("MM-DD-YYYY");
    this.weekSelectedNumber = moment(now).isoWeek();
    this.setMonToSundayWeekDates();

    const clientId = JSON.parse(localStorage.getItem("the_w_selectedClientId"));
    this.getUserByIdForHeader(clientId);
    this.getUserById(clientId).then((response) => {
      if (response === true) {
        this.getTeamsByUserId();
        this.getTrialists();
        this.loadPlayerLeads();
        this.loadScouts();
        this.getScoutGameAttendanceReport();
      }
    });
  },
  methods: {
    getDayAndDate(date) {
      return moment(date).format("dddd, MMMM Do YYYY");
    },
    formatISOStringDateAndTime(timeDateString) {
      return moment(timeDateString).utc().format("h:mm a");
    },
    async populatefixtures() {
      const data = {
        action: "getAllGames",
      };
      const responce = await Games.find(data).catch((error) => {
        console.log(error);
      });
      if (responce.data.length > 0) {
        this.allGamesData = responce.data;
      }
    },
    openPlayerInterestsHobbiesModal(index) {
      this.playerInterestsHobbiesClose();
      if (index !== undefined) {
        this.selectedPlayerInterestsHobbiesData =
          this.playerCvData.interests_hobbies[index];
        this.selectedPlayerInterestsHobbiesDataEdit = true;
      }
      this.$bvModal.show("openPlayerInterestsHobbiesModal");
    },
    async editPlayerInterestsHobbies() {
      const data = {
        action: "editPlayerInterestsHobbies",
        playerInterestsHobbies: this.selectedPlayerInterestsHobbiesData,
      };
      const responce = await PlayerCv.find(data).catch((error) => {
        console.log(error);
      });

      if (responce.status === 200) {
        this.populatePlayerPlayingExperenceData();
        this.$bvToast.toast("Player Interests and Hobbies updated.", {
          title: "saved",
          toaster: "b-toaster-bottom-right",
          solid: true,
          appendToast: true,
          variant: "success",
        });

        this.$bvModal.hide("openPlayerInterestsHobbiesModal");
      }
    },
    openPlayerEducationModal(index) {
      this.playerEducationModalClose();
      if (index !== undefined) {
        this.selectedPlayerEducationData = this.playerCvData.education[index];
        this.selectedPlayerEducationDataEdit = true;
      }
      this.$bvModal.show("openPlayerEducationModal");
    },
    async populatePlayerPlayingExperenceData() {
      const data = {
        action: "populatePlayerPlayingExperenceData",
        userId: this.selectedUserData.id,
      };
      const responce = await PlayerCv.find(data).catch((error) => {
        console.log(error);
      });
      if (responce.status === 200) {
        this.playerCvData.playingExperence = responce.data;
      }
    },
    formatDateForDB(date) {
      return moment(date).format("YYYY-MM-DD");
    },
    updateDatesForDB(objectName, itemName) {
      if (this[objectName][itemName] !== null) {
        this[objectName][itemName] = this.formatDateForDB(
          this[objectName][itemName]
        );
      }
    },
    async savePlayerPlayingExperence() {
      this.updateDatesForDB(
        "selectedPlayingExperenceData",
        "start_date_at_club"
      );
      this.updateDatesForDB("selectedPlayingExperenceData", "end_date_at_club");
      this.selectedPlayingExperenceData.player_id = this.selectedUserData.id;

      const data = {
        playingExperence: this.selectedPlayingExperenceData,
        action: "savePlayerPlayingExperence",
      };
      const responce = await PlayerCv.find(data).catch((error) => {
        console.log(error);
      });
      if (responce.status === 200) {
        this.populatePlayerPlayingExperenceData();
        this.$bvToast.toast("Player Playing Experence saved.", {
          title: "saved",
          toaster: "b-toaster-bottom-right",
          solid: true,
          appendToast: true,
          variant: "success",
        });

        this.$bvModal.hide("playerCVPlayingExperenceModal");
      }
    },
    async editPlayingExperence() {
      this.updateDatesForDB(
        "selectedPlayingExperenceData",
        "start_date_at_club"
      );
      this.updateDatesForDB("selectedPlayingExperenceData", "end_date_at_club");
      const data = {
        action: "editPlayingExperence",
        playingExperence: this.selectedPlayingExperenceData,
      };
      const responce = await PlayerCv.find(data).catch((error) => {
        console.log(error);
      });

      if (responce.status === 200) {
        this.populatePlayerPlayingExperenceData();
        this.$bvToast.toast("Player Playing Experence updated.", {
          title: "saved",
          toaster: "b-toaster-bottom-right",
          solid: true,
          appendToast: true,
          variant: "success",
        });

        this.$bvModal.hide("playerCVPlayingExperenceModal");
      }
    },
    playerPlayerVideosClose() {
      this.selectedPlayerVideoData = {};
      this.selectedPlayerVideoDataEdit = false;
    },
    playerCVPlayingExperenceModalClose() {
      this.selectedPlayingExperenceData = {};
      this.selectedPlayingExperenceEdit = false;
    },
    playerEducationModalClose() {
      this.selectedPlayerEducationData = {};
      this.selectedPlayerEducationDataEdit = false;
    },
    playerInterestsHobbiesClose() {
      this.selectedPlayerInterestsHobbiesData = {};
      this.selectedPlayerInterestsHobbiesDataEdit = false;
    },
    openPlayerCVPlayingExperenceModal(index) {
      console.log(index);
      this.playerCVPlayingExperenceModalClose();
      if (index !== undefined) {
        this.selectedPlayingExperenceData =
          this.playerCvData.playingExperence[index];
        this.selectedPlayingExperenceEdit = true;
      }
      this.$bvModal.show("playerCVPlayingExperenceModal");
    },
    openPlayerCVpersonalProfileModal() {
      this.$bvModal.show("playerCVpersonalProfileModal");
    },
    async savePersonalProfile() {
      const data = {
        personalProfileData: this.playerCvData.personal_profile,
        userId: this.selectedUserData.id,
        action: "savePersonalProfile",
      };
      const responce = await Users.find(data).catch((error) => {
        console.log(error);
      });
      if (responce.status === 200) {
        this.$bvToast.toast("Player Personal Profile saved.", {
          title: "saved",
          toaster: "b-toaster-bottom-right",
          solid: true,
          appendToast: true,
          variant: "success",
        });

        this.$bvModal.hide("playerCVpersonalProfileModal");
      }
    },
    produceThePlayerCV() {
      this.$bvModal.show("viewPlayersCV");
      setTimeout(() => {
        let pdf = this.buildThePlayerCVPDF();
        pdf.previewLink.src = pdf.pdfData.output("datauristring");
        this.showCVReport = true;
      }, 500);
    },
    addPlayingExperience(pdf, height) {
      pdf.setFontSize(10).setFont(undefined, "normal");
      height = height + 85;
      height = Math.round(height);

      this.playerCvData.playingExperence.map((item) => {
        let name = pdf.splitTextToSize(item.club, 50);

        pdf.text(5, height, name);
        pdf.text(70, height, this.formatDateForHuman(item.start_date_at_club));
        pdf.text(
          70,
          height + 5,
          this.formatDateForHuman(item.end_date_at_club)
        );

        let paraOneLines = pdf.splitTextToSize(item.playing_info, 90);
        pdf.text(115, height, paraOneLines);

        let dynamicHeight = pdf.getTextDimensions(paraOneLines);
        height = this.newPageCheck(height, dynamicHeight, pdf);
      });

      return height;
    },
    addEducation(pdf, height) {
      pdf.setFontSize(10).setFont(undefined, "normal");
      height = Math.round(height);

      this.playerCvData.education.map((item) => {
        let name = pdf.splitTextToSize(item.title, 50);
        pdf.text(5, height, name);
        pdf.text(70, height, this.formatDateForHuman(item.start_date));
        pdf.text(70, height + 5, this.formatDateForHuman(item.end_date));

        let paraOneLines = pdf.splitTextToSize(item.more_info, 90);
        pdf.text(115, height, paraOneLines);

        let dynamicHeight = pdf.getTextDimensions(paraOneLines);
        height = this.newPageCheck(height, dynamicHeight, pdf);
      });

      return height;
    },
    addInterest(pdf, height) {
      pdf.setFontSize(10).setFont(undefined, "normal");
      height = Math.round(height);

      this.playerCvData.interests_hobbies.map((item) => {
        let interest = pdf.splitTextToSize(item.interests, 50);

        pdf.text(5, height, interest);
        let paraOneLines = pdf.splitTextToSize(item.more_info, 130);
        pdf.text(70, height, paraOneLines);

        let dynamicHeight = pdf.getTextDimensions(paraOneLines);
        height = this.newPageCheck(height, dynamicHeight, pdf);
      });

      return height;
    },
    buildThePlayerCVPDF() {
      let previewContainer = document.getElementById("thePlayerCVPDF_preview");

      // load player image //
      let img = document.querySelector("#user_photo");
      let pdf = new JsPDF("p", "mm", "a4");

      pdf.setTextColor(3, 86, 158);
      pdf.addImage(img, "PNG", 1, 0, "40", "40");

      pdf.setFontSize(23).setFont(undefined, "bold");
      pdf.text(45, 18, this.selectedUserData.name, { maxWidth: 70 });

      pdf.setFontSize(16);
      pdf.text(5, 55, this.selectedUserData.dob);

      pdf.setFontSize(16).setFont(undefined, "bold");
      pdf.text(5, 65, "Personal profile");

      pdf.setFontSize(14).setFont(undefined, "normal");

      let paraOne = this.selectedUserData.personal_profile;

      let paraOneLines = pdf.splitTextToSize(paraOne, 185);
      pdf.text(5, 75, paraOneLines);

      let dynamicHeight = pdf.getTextDimensions(paraOneLines);

      dynamicHeight.h = dynamicHeight.h + 10;
      pdf.setFontSize(16).setFont(undefined, "bold");
      pdf.text(5, 75 + dynamicHeight.h, "Playing experience");

      dynamicHeight.h = dynamicHeight.h + 10;
      pdf.setFontSize(12).setFont(undefined, "bold");
      pdf.text(5, 75 + dynamicHeight.h, "Club");
      pdf.text(70, 75 + dynamicHeight.h, "Time at the club");
      pdf.text(115, 75 + dynamicHeight.h, "Playing stats");

      dynamicHeight.h = this.addPlayingExperience(pdf, dynamicHeight.h);

      pdf.setFontSize(16).setFont(undefined, "bold");
      pdf.text(5, dynamicHeight.h + 10, "Education");

      dynamicHeight.h = this.addEducation(pdf, dynamicHeight.h + 20);

      pdf.setFontSize(16).setFont(undefined, "bold");
      pdf.text(5, dynamicHeight.h + 10, "Interest hobbies");

      dynamicHeight.h = dynamicHeight.h + 20;
      pdf.setFontSize(12).setFont(undefined, "bold");
      pdf.text(5, dynamicHeight.h, "Interest hobbies");
      pdf.text(70, dynamicHeight.h, "More information");

      dynamicHeight.h = this.addInterest(pdf, dynamicHeight.h + 10);

      dynamicHeight.h = dynamicHeight.h + 20;
      pdf.setFontSize(16).setFont(undefined, "bold");
      pdf.text(5, dynamicHeight.h, "Playing videos");

      dynamicHeight.h = this.addPlayerVideos(pdf, dynamicHeight.h + 10);

      return { pdfData: pdf, previewLink: previewContainer };
    },
    checkUserPosition() {
      return Object.keys(this.selectedUserData).length === 0
        ? this.userData.position
        : this.selectedUserData.position;
    },
    removeFilter() {
      this.liveTrialistsDataFiltered = this.liveTrialistsData;
      this.hideRemoveTeamFilterButton = true;
    },
    filterBy(arrayToFilter, filterBy) {
      const filterResult = this[arrayToFilter].filter(
        (player) => player[filterBy] === this.selectedFilterData
      );

      this.liveTrialistsDataFiltered = filterResult;
      this.hideRemoveTeamFilterButton = false;
    },
    getScoutNameFromId(id) {
      const scout = this.allScoutsData.find((scout) => scout.id === Number(id));
      return scout ? scout.name : "";
    },
    lookUpSearch(value) {
      if (value.length > 2) {
        this.filteredTrialistsData = [];

        const results = Array.from(value).reduce(
          (a, v, i) => `${a}[^${value.substr(i)}]*?${v}`,
          ""
        );
        const regEx = RegExp(results);

        this.liveTrialistsData.filter((v) => {
          console.log(v);
          if (v.name.match(regEx)) {
            this.filteredTrialistsData.push(v);
          }
        });
      } else {
        this.filteredTrialistsData = [];
      }
    },
    async getAllCoachesAttachedToTeam(teamId) {
      const data = {
        teamId: teamId,
        action: "getAllCoachesAttachedToTeam",
      };
      const responce = await Teams.find(data).catch((error) => {
        console.log(error);
      });
      if (responce.data.length > 0) {
        return responce.data;
      }
    },
    async selectTeamSelectCoach(key, teamId) {
      const coaches = await this.getAllCoachesAttachedToTeam(teamId);
      this[key].coach_id = coaches[0].id;
    },
    async extendTrail() {
      this.selectedTrialistsData = this.extendTrial;
      await this.getUserByTrialistId(this.selectedTrialistsData.id);
      this.saveTrialistPerformanceReview();
      this.$bvModal.hide("extendTrialModal");
    },
    extendTrialModal(player) {
      this.extendTrial = { ...player };
      this.extendTrial.trial_length = 0;
      this.$bvModal.show("extendTrialModal");
    },
    addItem(targetText) {
      const selectedMatchAttendedPlayerData = {
        name: "",
        position: "",
        attitude: 0,
        skill: 0,
        physique: 0,
        mentality: 0,
        leadership: 0,
        professionalism: 0,
        comments: "",
        trialistId: null,
        leadId: null,
      };
      this.selectedMatchAttendedData.playersGameData = [
        ...this.selectedMatchAttendedData.playersGameData,
        selectedMatchAttendedPlayerData,
      ];
    },
    removeItem(index, id) {
      this.selectedMatchAttendedData.playersGameData.splice(index, 1);
      this.removedPlayersBeingAssessed(id);
    },
    async removedPlayersBeingAssessed(id) {
      const data = {
        action: "removedPlayersBeingAssessed",
        id: id,
      };

      const responce = await Trialists.find(data).catch((error) => {
        console.log(error);
      });

      if (responce.status === 200) {
        this.$bvModal.hide("gameAttendanceReportModal");

        this.$bvToast.toast("Game attendance player report removed.", {
          title: "removed",
          toaster: "b-toaster-bottom-right",
          solid: true,
          appendToast: true,
          variant: "success",
        });
      }
    },
    async addEditGameAttendanceModel(id, type) {
      if (type === "edit") {
        this.editMode = true;
        let scoutGameReportData = this.allScoutGameAttendanceReports.find(
          (scout) => Number(scout.id) === Number(id)
        );
        scoutGameReportData.playersGameData = [];

        Object.assign(this.selectedMatchAttendedData, {
          age_group: scoutGameReportData.age_group,
          awayTeam: scoutGameReportData.awayTeam,
          clubId: scoutGameReportData.clubId,
          game_comments: scoutGameReportData.game_comments,
          homeTeam: scoutGameReportData.homeTeam,
          id: scoutGameReportData.id,
          matchDate: scoutGameReportData.matchDate,
          playersGameData: scoutGameReportData.playersGameData,
          scoutId: scoutGameReportData.scoutId,
          weather_conditions: scoutGameReportData.weather_conditions,
        });

        const response = await this.getplayersBeingassesedByScoutReportId(id);

        if (response !== undefined) {
          this.selectedMatchAttendedData.playersGameData = response;
          this.getScoutGameAttendanceReport();

          if (type !== "edit") {
            this.$bvToast.toast("Game attendance added.", {
              title: "added",
              toaster: "b-toaster-bottom-right",
              solid: true,
              appendToast: true,
              variant: "success",
            });
          }
        }
      } else {
        this.editMode = false;
        this.selectedMatchAttendedData = {
          clubId: "",
          homeTeam: "",
          awayTeam: "",
          age_group: "",
          matchDate: "",
          playersGameData: [],
          scoutId: null,
          scoutName: "",
        };
      }
      this.$bvModal.show("gameAttendanceReportModal");
    },
    copyToClipboard(link) {
      navigator.clipboard
        .writeText(link)
        .then(() => { })
        .catch((err) => {
          console.log(err);
        });
    },
    getTrialLink() {
      let selectedLeadData = 0;
      if (this.selectedLeadData.id !== undefined) {
        selectedLeadData = this.selectedLeadData.id;
      }
      return `${window.location.origin}/trialists/invitation-sign-up/${this.userData.clubId}/${selectedLeadData}`;
    },
    getScoutName(id) {
      const scoutData = this.allScoutsData.find(
        (scout) => Number(scout.id) === Number(id)
      );
      return scoutData !== undefined ? scoutData.name : "";
    },
    populateAssignedScout(scoutId, type) {
      if (type === "game attendance") {
        const scoutData = this.allScoutsData.find(
          (scout) => Number(scout.id) === Number(scoutId.target.value)
        );
        this.selectedMatchAttendedData.scoutId = scoutId.target.value;
        this.selectedMatchAttendedData.scoutName = scoutData.name;
      } else {
        if (scoutId.target.value === "") {
          this.selectedLeadData.scout_name = "";
          this.selectedLeadData.scout_contact = "";
          this.selectedLeadData.scoutId = "";
        }
        const scoutData = this.allScoutsData.find(
          (scout) => Number(scout.id) === Number(scoutId.target.value)
        );
        this.selectedLeadData.scout_name = scoutData.name;
        this.selectedLeadData.scout_contact = scoutData.email;
        this.selectedLeadData.scoutId = scoutId.target.value;
      }
    },
    async getplayersBeingassesedByScoutReportId(id) {
      const data = {
        action: "getplayersBeingassesedByScoutReportId",
        id: id,
      };

      const responce = await Trialists.find(data).catch((error) => {
        console.log(error);
      });

      if (responce.status === 200) {
        return responce.data;
      }
    },
    async updateScoutGameAttendanceReport() {
      if (typeof this.selectedMatchAttendedData.matchDate === "object") {
        this.selectedMatchAttendedData.matchDate = dayjs(
          this.selectedMatchAttendedData.matchDate
        ).format("YYYY-MM-DD");
      }
      if (typeof this.selectedMatchAttendedData.age_group === "object") {
        this.selectedMatchAttendedData.age_group = dayjs(
          this.selectedMatchAttendedData.age_group
        ).format("YYYY");
      }
      const data = {
        action: "updateScoutGameAttendanceReport",
        ...this.selectedMatchAttendedData,
      };

      const responce = await Trialists.find(data).catch((error) => {
        console.log(error);
      });

      if (responce.status === 200) {
        this.getScoutGameAttendanceReport();
        this.$bvModal.hide("gameAttendanceReportModal");
        this.$bvToast.toast("Game attendance report updated.", {
          title: "saved",
          toaster: "b-toaster-bottom-right",
          solid: true,
          appendToast: true,
          variant: "success",
        });
      }
    },
    async deleteGameAttendance(id) {
      const data = {
        action: "deleteGameAttendance",
        id: id,
      };

      const responce = await Trialists.find(data).catch((error) => {
        console.log(error);
      });

      if (responce.status === 200) {
        this.getScoutGameAttendanceReport();
        this.$bvToast.toast("Game attendance report deleted.", {
          title: "deleted",
          toaster: "b-toaster-bottom-right",
          solid: true,
          appendToast: true,
          variant: "success",
        });
      }
    },
    async saveScoutGameAttendanceReport() {
      this.selectedMatchAttendedData.clubId = this.userData.clubId;
      this.selectedMatchAttendedData.matchDate = dayjs(
        this.selectedMatchAttendedData.matchDate
      ).format("YYYY-MM-DD");
      this.selectedMatchAttendedData.age_group = dayjs(
        this.selectedMatchAttendedData.age_group
      ).format("YYYY");
      const data = {
        action: "saveScoutGameAttendanceReport",
        ...this.selectedMatchAttendedData,
      };

      const responce = await Trialists.find(data).catch((error) => {
        console.log(error);
      });

      if (responce.status === 200) {
        this.getScoutGameAttendanceReport();
        this.$bvModal.hide("gameAttendanceReportModal");
        this.$bvToast.toast("Game attendance report saved.", {
          title: "saved",
          toaster: "b-toaster-bottom-right",
          solid: true,
          appendToast: true,
          variant: "success",
        });
      }
    },
    async populateAcademyReport() {
      const data = {
        action: "populateAcademyReport",
        userId: this.selectedUserData.id,
      };
      const responce = await AcademyReport.find(data).catch((error) => {
        console.log(error);
      });
      if (responce.status === 200 && responce.data.id !== undefined) {
        this.academyReport = responce.data;
      }
    },
    editAcademyReportModal() {
      this.$bvModal.show("editAcademyReportModal");
    },
    async updateTheAcademyReport(id) {
      id !== null ? this.editAcademyReport() : this.saveAcademyReport();
    },
    async editAcademyReport() {
      const data = {
        action: "editAcademyReport",
        academyReport: this.academyReport,
      };
      const responce = await AcademyReport.find(data).catch((error) => {
        console.log(error);
      });
      if (responce.status === 200) {
        this.populateAcademyReport();
      }
      this.$bvModal.hide("editAcademyReportModal");
    },
    async saveAcademyReport() {
      const data = {
        action: "saveAcademyReport",
        academyReport: this.academyReport,
      };
      const responce = await AcademyReport.find(data).catch((error) => {
        console.log(error);
      });
      if (responce.status === 200) {
        this.populateAcademyReport();
      }
      this.$bvModal.hide("editAcademyReportModal");
    },
    async getAllgoalsScoredByPlayerId() {
      const data = {
        action: "getAllgoalsScoredByPlayerId",
        userId: this.selectedUserData.id,
        season_start_date: localStorage.getItem(
          "currentPlayingseasonStartDate"
        ),
        season_end_date: this.extendSeasonEndDateToFullYear(),
      };
      const responce = await Games.find(data).catch((error) => {
        console.log(error);
      });

      if (responce.data) {
        this.gamePerformanceData.goals = responce.data;
      }
    },
    async getAllgoalAssistsByPlayerId() {
      const data = {
        action: "getAllgoalAssistsByPlayerId",
        userId: this.selectedUserData.id,
        season_start_date: localStorage.getItem(
          "currentPlayingseasonStartDate"
        ),
        season_end_date: this.extendSeasonEndDateToFullYear(),
      };
      const responce = await Games.find(data).catch((error) => {
        console.log(error);
      });

      if (responce.data) {
        this.gamePerformanceData.assists = responce.data;
      }
    },
    dayMonthYearDate(date) {
      return dayjs(date, "YYYY-MM-DD").format("MMM YY");
    },
    produceTheAcademyReport() {
      this.showReport = true;
      this.academyReport.player_id = this.selectedUserData.id;
      this.academyReport.seasion = "2023";
      //this.loadPlayerPhysicalPerformanceData();

      setTimeout(() => {
        let pdf = this.buildTheAcademyReportPDF();
        pdf.previewLink.src = pdf.pdfData.output("datauristring");
        this.showReport = true;
      }, 500);
    },
    buildTheAcademyReportPDF() {
      let previewContainer = document.getElementById(
        "theAcademyReportPdf_preview"
      );
      let shirtImage = document.getElementById("small_shirt");
      // load player image //
      let img = document.querySelector("#user_photo");
      let pdf = new JsPDF("p", "mm", "a4");

      pdf.setFillColor(241, 246, 249);
      pdf.rect(0, 0, 300, 120, "F");

      pdf.setTextColor(3, 86, 158);
      pdf.addImage(img, "PNG", 1, 0, "40", "40");

      pdf.setFontSize(23).setFont(undefined, "bold");
      pdf.text(45, 18, this.selectedUserData.name, { maxWidth: 70 });

      pdf.setFontSize(16);
      pdf.text(
        5,
        55,
        `Season: ${this.dayMonthYearDate(
          this.season_start_date
        )} - ${this.dayMonthYearDate(this.season_end_date)}`
      );

      pdf.setFontSize(14).setFont(undefined, "normal");
      pdf.text(5, 65, "Appearances:");
      pdf.text(75, 65, `${this.gamePerformanceData.gamesPlayed}`);

      pdf.text(5, 75, "Goals:");
      pdf.text(75, 75, `${this.gamePerformanceData.goals}`);

      pdf.text(5, 85, "Assists:");
      pdf.text(75, 85, `${this.gamePerformanceData.assists}`);

      pdf.text(5, 95, "Player performace rating:");
      let playerSelfRating;
      this.gamePerformanceData.player_self_rating === null
        ? (playerSelfRating = 0)
        : (playerSelfRating = this.gamePerformanceData.player_self_rating);
      pdf.text(75, 95, `${playerSelfRating}`);

      pdf.text(5, 105, "Game time:");
      let timeOnPitch;
      this.gamePerformanceData.timeOnPitch === null
        ? (timeOnPitch = 0)
        : (timeOnPitch = this.gamePerformanceData.timeOnPitch);
      pdf.text(75, 105, `${timeOnPitch} mins`);

      pdf
        .text(
          125,
          15,
          `Dob: ${this.formatDateForHuman(this.selectedUserData.dob)}`
        )
        .setFont(undefined, "normal");
      pdf
        .text(125, 25, `Position: ${this.selectedUserData.position}`)
        .setFont(undefined, "normal");
      pdf
        .text(
          125,
          35,
          `Height: ${this.playerPhysicalPerformanceData.height} cm`
        )
        .setFont(undefined, "normal");
      pdf
        .text(
          125,
          45,
          `Weight:  ${this.playerPhysicalPerformanceData.weight} kg`
        )
        .setFont(undefined, "normal");
      pdf
        .text(125, 55, `Age: ${this.selectedUserData.age_group}`)
        .setFont(undefined, "normal");
      pdf
        .text(
          125,
          65,
          `Signed: ${this.formatDateForHuman(
            this.selectedUserData.joined_date
          )}`
        )
        .setFont(undefined, "normal");

      let pitchBackground = document.getElementById("pitch_potions");
      pdf.addImage(pitchBackground, "PNG", 120, 90, 80, 120);

      let height = this.addPlayingPostions(pdf, 85);

      pdf.setFontSize(14).setFont(undefined, "bold");
      pdf.text(125, height, "PLAYING POSITIONS");

      pdf.text(125, height + 135, "PHYSICAL PERFORMANCE");

      pdf.setFontSize(12).setFont(undefined, "normal");
      pdf.text(125, height + 143, "10m SPRINT");
      const tenMsprintTime =
        this.playerPhysicalPerformanceData.tenM_sprint_time;
      if (tenMsprintTime !== undefined) {
        pdf.text(183, height + 143, tenMsprintTime);
      }

      pdf.text(125, height + 153, "40m SPRINT");
      const fortyMSprintTime =
        this.playerPhysicalPerformanceData.fortyM_sprint_time;
      if (fortyMSprintTime !== undefined) {
        pdf.text(183, height + 153, `${fortyMSprintTime}s`);
      }

      pdf.text(125, height + 163, "Countermovement jump");
      const cuntermovementJump =
        this.playerPhysicalPerformanceData.cuntermovement_jump;
      if (cuntermovementJump !== undefined) {
        pdf.text(183, height + 163, `${cuntermovementJump}cm`);
      }

      pdf.text(125, height + 173, "Reactive strength index");
      const reactiveStrengthindex =
        this.playerPhysicalPerformanceData.reactive_strength_index;
      if (reactiveStrengthindex !== undefined) {
        pdf.text(183, height + 173, `${reactiveStrengthindex}`);
      }

      pdf.text(125, height + 183, "30-15 Intermittent Fitness Test");
      const thirty15IntermittentFitnessTest =
        this.playerPhysicalPerformanceData.reactive_strength_index;
      if (thirty15IntermittentFitnessTest !== undefined) {
        pdf.text(183, height + 183, `${thirty15IntermittentFitnessTest}`);
      }

      pdf.setFontSize(16).setFont(undefined, "bold");
      pdf.text(5, 130, "COACHES COMMENTS");

      pdf.setFontSize(12).setFont(undefined, "normal");

      //let playerPerformanceReview = this.getTheLastPlayerPerformanceReview();
      let paraOneLines = "Please add comments";

      var paraOne = this.academyReport.coaches_comments;
      paraOneLines = pdf.splitTextToSize(paraOne, 112);

      pdf.text(5, 139, paraOneLines);
      let dynamicHeight = pdf.getTextDimensions(paraOneLines);

      pdf.setFontSize(16).setFont(undefined, "bold");
      pdf.text(5, dynamicHeight.h + 150, "Playing videos");
      dynamicHeight.h = this.addPlayerVideos(pdf, dynamicHeight.h + 160);

      return { pdfData: pdf, previewLink: previewContainer };
    },
    addPlayerVideos(pdf, height) {
      pdf.setFontSize(10).setFont(undefined, "normal");
      height = Math.round(height);

      this.playerCvData.videos.map((item, index) => {
        let videoIcon = document.getElementById("video-icon");
        pdf.addImage(videoIcon, "PNG", 5, height - 7, 10, 10);

        pdf.textWithLink(item.video_name, 20, height, { url: item.video_url });
        pdf.textWithLink(item.video_name, 20, height, { url: item.video_url });
        height = height + 10;

        if (index !== 0 && height >= "210") {
          pdf.addPage();
          height = 10;
        }
      });
      return height;
    },
    async getThisSeasonsgamePerformanceReportsForUser() {
      this.seasonsgamePerformanceReportsForUser = [];

      if (localStorage.getItem("currentPlayingSeason") !== null) {
        this.currentPlayingSeason = localStorage.getItem(
          "currentPlayingSeason"
        );
      }
      const userData = {
        user_id: this.selectedUserData.id,
        action: "getThisSeasonsgamePerformanceReportsForUser",
        season_start_date: localStorage.getItem(
          "currentPlayingseasonStartDate"
        ),
        season_end_date: this.extendSeasonEndDateToFullYear(),
      };
      const responce = await Games.find(userData).catch((error) => {
        console.log(error);
      });

      if (responce.data.length > 0) {
        this.seasonsgamePerformanceReportsForUser = responce.data;
        //this.populatePlayerSelfRating();
        this.populateGameStatsForThisSeason("goals");
        this.populateGameStatsForThisSeason("assists");
        this.populateGameStatsForThisSeason("clean_sheet");
        this.populateGameStatsForThisSeason("timeOnPitch");
        this.pupulatePlayerCards();
      }

      this.populatePostionsPlayed();
    },
    populatePostionsPlayed() {
      if (this.seasonsgamePerformanceReportsForUser.length > 0) {
        this.gamePerformanceData.postion_played = [];
        let array = [];
        this.seasonsgamePerformanceReportsForUser.filter(function (postion) {
          array.push(postion.postion_played);
        });
        Object.keys(this.selectedUserData).length === 0
          ? this.gamePerformanceData.postion_played.push(this.userData.position)
          : this.gamePerformanceData.postion_played.push(
            this.selectedUserData.position
          );
        this.gamePerformanceData.postion_played = array;
      } else {
        Object.keys(this.selectedUserData).length === 0
          ? this.gamePerformanceData.postion_played.push(this.userData.position)
          : this.gamePerformanceData.postion_played.push(
            this.selectedUserData.position
          );
      }
    },
    pupulatePlayerCards() {
      this.seasonsgamePerformanceReportsForUser.forEach((player) => {
        if (player.card === "Yellow") {
          this.gamePerformanceData.yellowCardTotal =
            this.gamePerformanceData.yellowCardTotal + 1;
        }
        if (player.card === "Red" || player.card === "two Yellows (red)") {
          this.gamePerformanceData.redCardTotal =
            this.gamePerformanceData.redCardTotal + 1;
        }
      });
    },
    populateGameStatsForThisSeason(typeOfData) {
      if (this.seasonsgamePerformanceReportsForUser.length > 0) {
        console.log(this.seasonsgamePerformanceReportsForUser);
        this.gamePerformanceData[typeOfData] =
          this.seasonsgamePerformanceReportsForUser.reduce(function (
            accumulator,
            player
          ) {
            return accumulator + Number(player[typeOfData]);
          },
            0);
      }
    },
    addPlayingPostions(pdf, height) {
      pdf.setFontSize(10).setFont(undefined, "normal");

      this.gamePerformanceData.postion_played.map((item, index) => {
        if (item === "Goalkeeper") {
          pdf.text(158, 201, "GK");
        }

        if (item === "Right Full-back (Wingback)") {
          pdf.text(188.5, 189, "RB");
        }

        if (item === "Center-back left") {
          pdf.text(147, 189, "CB");
        }

        if (item === "Center-back right") {
          pdf.text(167, 189, "CB");
        }

        if (item === "Defensive Midfielder") {
          pdf.text(147, 152, "CM");
        }

        if (item === "Attacking Midfielder") {
          pdf.text(167, 152, "CM");
        }

        if (item === "Right Midfielder (Winger)") {
          pdf.text(188.5, 152, "RW");
        }

        if (item === "Left Midfielder (Winger)") {
          pdf.text(127.5, 152, "LW");
        }

        if (item === "Center Forward (Striker) left") {
          pdf.text(147, 119, "CF");
        }

        if (item === "Center Forward (Striker) right") {
          pdf.text(169, 119, "CF");
        }

        if (item === "Left Full-back (Wingback)") {
          pdf.text(127.5, 189, "LB");
        }
      });

      return height;
    },
    newPageCheck(height, dynamicHeight, pdf) {
      if (height >= "210") {
        pdf.addPage();
        return 10;
      } else {
        height = Math.round(dynamicHeight.h + height + 10);
        return height;
      }
    },
    async editTrialistPerformanceReview(selectedTrialistsData) {
      await this.getUserByTrialistId(selectedTrialistsData.id);
      this.selectedTrialistsData = { ...selectedTrialistsData };
      this.editMode = true;
      this.$bvModal.show("addEditTrialist");
    },
    async savePlayerVideoLink() {
      this.selectedPlayerVideoData.player_id = this.selectedUserData.id;

      const data = {
        playerVideo: this.selectedPlayerVideoData,
        action: "savePlayerVideoLink",
      };
      const responce = await PlayerCv.find(data).catch((error) => {
        console.log(error);
      });
      if (responce.status === 200) {
        this.populatePlayerVideoLinkData();
        this.$bvToast.toast("Player video saved.", {
          title: "saved",
          toaster: "b-toaster-bottom-right",
          solid: true,
          appendToast: true,
          variant: "success",
        });

        this.$bvModal.hide("playerVideosModal");
      }
    },
    async deletePlayerVideoLink(id) {
      const data = {
        action: "deletePlayerVideoLink",
        id: id,
      };
      const responce = await PlayerCv.find(data).catch((error) => {
        console.log(error);
      });

      if (responce.status === 200) {
        this.populatePlayerVideoLinkData();
        this.$bvToast.toast("Player video  deleted.", {
          title: "saved",
          toaster: "b-toaster-bottom-right",
          solid: true,
          appendToast: true,
          variant: "success",
        });
      }
    },
    async populatePlayerVideoLinkData() {
      const data = {
        action: "populatePlayerVideoLinkData",
        userId: this.selectedUserData.id,
      };
      const responce = await PlayerCv.find(data).catch((error) => {
        console.log(error);
      });
      if (responce.status === 200) {
        this.playerCvData.videos = responce.data;
      }
    },
    openPlayerVideosModal(index) {
      if (index !== undefined) {
        this.selectedPlayerVideoData = this.playerCvData.videos[index];
        this.selectedPlayerVideoDataEdit = true;
      }
      this.$bvModal.show("playerVideosModal");
    },
    removeNumberFromStart(documentName) {
      if (documentName !== undefined) {
        return documentName.substring(documentName.indexOf("_") + 1);
      }
    },
    async getDocumentUploadsCall() {
      this.getDocumentUploadsByUserId();
      this.$bvToast.toast("Player document uploaded.", {
        title: "uploaded",
        toaster: "b-toaster-bottom-right",
        solid: true,
        appendToast: true,
        variant: "success",
      });
    },
    async getDocumentUploadsByUserId() {
      const userData = {
        action: "getDocumentUploadsByUserId",
        user_id: this.selectedTrialistsData.id,
      };
      const response = await Documents.find(userData).catch((error) => {
        console.log(error);
      });

      if (response.status === 200) {
        this.uploadedDocuments = response.data;
      }
    },
    viewLeadModal(playerLead) {
      this.selectedLeadData = playerLead;
      this.$bvModal.show("viewLead");
    },
    openLeadsModal(type, playerLead) {
      this.editMode = false;
      switch (type) {
        case "edit":
          this.selectedLeadData = playerLead;
          this.editMode = true;
          break;

        case "scout report lead":
          this.selectedLeadData = {
            age_group: this.selectedMatchAttendedData.age_group,
            club_name: playerLead.team,
            dob: "",
            name: playerLead.name,
            notes: "",
            playing_position: playerLead.position,
            scoutId: this.selectedMatchAttendedData.scoutId,
            scout_contact: "",
            scout_name: this.getScoutName(
              this.selectedMatchAttendedData.scoutId
            ),
            playerLeadId: playerLead.id,
            state: "Still to Watch",
          };

          this.$bvModal.hide("gameAttendanceReportModal");

          this.editMode = false;
          break;

        case "add more lead information":
          this.selectedLeadData = playerLead;
          break;

        default:
          this.selectedLeadData = {};
          this.selectedLeadData.state = "Still to Watch";
          this.editMode = false;
          break;
      }

      this.$bvModal.show("addEditLead");
    },
    async getScoutGameAttendanceReport() {
      const userData = {
        action: "getScoutGameAttendanceReport",
        clubId: this.userData.clubId,
      };
      const responce = await Trialists.find(userData).catch((error) => {
        console.log(error);
      });

      if (responce.status === 200) {
        this.allScoutGameAttendanceReports = [];
        this.allScoutGameAttendanceReports = responce.data;
      }
    },
    async loadScouts() {
      const userData = {
        action: "loadScouts",
      };
      const responce = await Trialists.find(userData).catch((error) => {
        console.log(error);
      });

      if (responce.status === 200) {
        this.allScoutsData = responce.data;
      }
    },
    async loadOnlyScoutPlayerLeads() {
      const userData = {
        action: "loadOnlyScoutPlayerLeads",
        scoutId: this.userData.id,
      };
      const responce = await Trialists.find(userData).catch((error) => {
        console.log(error);
      });

      if (responce.status === 200) {
        this.allPlayerLeadsData = await this.playerLeadsMetaData(responce.data);
      }
    },
    filterOutAllMetaDataResults(data, metaData) {
      const metaDataIds = new Set(metaData.map((meta) => meta.leadId));
      const filteredData = [];

      data.forEach((lead) => {
        if (metaDataIds.has(lead.id)) {
          this.metaDataReults.push(lead);
        } else {
          filteredData.push(lead);
        }
      });

      return filteredData;
    },
    async playerLeadsMetaData(data) {
      const userData = {
        action: "loadPlayerLeadsMetaData",
      };
      const responce = await Trialists.find(userData).catch((error) => {
        console.log(error);
      });

      if (responce.status === 200) {
        return responce.data.length > 0
          ? this.filterOutAllMetaDataResults(data, responce.data)
          : data;
      }
    },
    async loadAllPlayerLeads() {
      const userData = {
        action: "loadPlayerLeads",
      };
      const responce = await Trialists.find(userData).catch((error) => {
        console.log(error);
      });

      if (responce.status === 200) {
        this.allPlayerLeadsData = await this.playerLeadsMetaData(responce.data);
      }
    },
    async loadPlayerLeads() {
      this.loadAllPlayerLeads();
      /* this.userData.userType === "Scout"
      ? this.loadOnlyScoutPlayerLeads()
      : this.loadAllPlayerLeads(); */
    },
    async removePlayerLead(id) {
      const userData = {
        id: id,
        action: "removePlayerLead",
      };
      const responce = await Trialists.find(userData).catch((error) => {
        console.log(error);
      });

      if (responce.status === 200) {
        this.loadPlayerLeads();
        this.$bvToast.toast("Player lead removed.", {
          title: "removed",
          toaster: "b-toaster-bottom-right",
          solid: true,
          appendToast: true,
          variant: "success",
        });
      }
    },
    async editPlayerLead() {
      if (typeof this.selectedLeadData.age_group === "object") {
        this.selectedLeadData.age_group = dayjs(
          this.selectedLeadData.age_group
        ).format("YYYY");
      }

      if (typeof this.selectedLeadData.dob === "object") {
        this.selectedLeadData.dob = dayjs().format("YYYY-MM-DD");
      }
      const userData = {
        ...this.selectedLeadData,
        action: "editPlayerLead",
      };
      const responce = await Trialists.find(userData).catch((error) => {
        console.log(error);
      });

      if (responce.status === 200) {
        this.loadPlayerLeads();
        this.$bvModal.hide("addEditLead");
        this.$bvModal.hide("ViewTrialistLeadPlayerProfile");
        this.$bvToast.toast("Player lead edited.", {
          title: "edited",
          toaster: "b-toaster-bottom-right",
          solid: true,
          appendToast: true,
          variant: "success",
        });
      }
    },
    async addNewPlayerLead() {
      let hasLeadId = null;
      if (this.selectedLeadData.id !== undefined) {
        hasLeadId = this.selectedLeadData.id;
      }
      let scoutId = this.selectedMatchAttendedData.scoutId;
      let scoutName = "";
      if (scoutId === null) {
        if (this.selectedLeadData.scoutId !== null) {
          scoutId = this.selectedLeadData.scoutId;
          scoutName = this.getScoutName(this.selectedLeadData.scoutId);
        }
      } else {
        scoutName = this.getScoutName(this.selectedMatchAttendedData.scoutId);
      }
      this.selectedLeadData = {
        age_group: dayjs(this.selectedLeadData.age_group).format("YYYY"),
        club_name: this.selectedLeadData.club_name || "",
        dob: dayjs(this.selectedLeadData.dob).format("YYYY-MM-DD"),
        name: this.selectedLeadData.name || "",
        notes: this.selectedLeadData.notes || "",
        playing_position: this.selectedLeadData.playing_position || "",
        scoutId: scoutId,
        scout_contact: this.selectedLeadData.scout_contact || "",
        scout_name: scoutName,
        state: this.selectedLeadData.state || "Still to Watch",
        date: dayjs(this.selectedLeadData.date).format("YYYY-MM-DD"),
        leadId: this.selectedLeadData.playerLeadId || null,
        trialistId: this.selectedLeadData.trialistId || null,
        userImage: null,
      };

      if (hasLeadId !== null) {
        this.selectedLeadData.id = hasLeadId;
      }

      const userData = {
        ...this.selectedLeadData,
        action: "addNewPlayerLead",
      };
      const responce = await Trialists.find(userData).catch((error) => {
        console.log(error);
      });

      if (responce.status === 200) {
        this.loadPlayerLeads();
        this.$bvModal.hide("addEditLead");
        this.$bvModal.hide("ViewTrialistLeadPlayerProfile");
        this.$bvToast.toast("New lead added.", {
          title: "added",
          toaster: "b-toaster-bottom-right",
          solid: true,
          appendToast: true,
          variant: "success",
        });
      }
    },
    async addLeadMeta() {
      const userData = {
        leadId: value,
        action: "addLeadMeta",
      };
      const responce = await Users.find(userData).catch((error) => {
        console.log(error);
      });
    },
    async checkIfEmailExists(value) {
      const userData = {
        email: value,
        action: "checkEmailexists",
      };
      const responce = await Users.find(userData).catch((error) => {
        console.log(error);
      });

      if (responce.data.length > 0) {
        this.validationValues.email.state = true;
        this.validationValues.email.text =
          "Email address aready exits, please change it";
      } else {
        this.validationValues.email.state = false;
        this.validationValues.email.text = "Please add an email address";
      }
    },
    requiredEmailValidation(value) {
      var validRegex =
        /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;
      return validRegex.test(value);
    },
    checkChangeVlaidation(value, elementName) {
      if (elementName === "email") {
        if (value !== "") {
          this.checkIfEmailExists(value);
        } else {
          this.validationValues.email.state = false;
          this.validationValues.email.text = "Please add an email address";
        }
        const validated = this.requiredEmailValidation(value);
        this.validationValues[elementName].state = validated !== true;
      } else {
        JSON.stringify(value).length > 0
          ? (this.validationValues[elementName].state = false)
          : (this.validationValues[elementName].state = true);
      }
    },
    getTimeLeftInTrial(trialStartDate, trialLength) {
      if (trialLength === undefined) {
        return "Trial length is not defined.";
      }

      trialLength = trialLength * 7;

      const startDate = new Date(trialStartDate);
      const currentDate = new Date();
      const endDate = new Date(dayjs(startDate).add(trialLength, "day"));

      if (currentDate < startDate) {
        return "Trial period has not started.";
      }

      const totalSeconds = Math.floor((endDate - currentDate) / 1000);

      if (totalSeconds < 0) {
        return "Trial period has ended.";
      }

      const days = Math.floor(totalSeconds / (3600 * 24));
      const hours = Math.floor((totalSeconds % (3600 * 24)) / 3600);

      return `${days} days, ${hours} hrs`;
    },
    async populateCoachesData() {
      const coachesData = {
        clubId: this.userData.clubId,
        action: "populateCoachesData",
      };

      const responce = await Users.find(coachesData).catch((error) => {
        console.log(error);
      });

      return responce.data.length > 0 ? responce.data : [];
    },
    async getAllGamesAttendedById() {
      const userData = {
        user_id: this.selectedUserData.id,
        action: "getAllGamesAttendedById",
        season_start_date: localStorage.getItem(
          "currentPlayingseasonStartDate"
        ),
        season_end_date: this.extendSeasonEndDateToFullYear(),

        year: this.currentPlayingSeason,
      };
      const responce = await Games.find(userData).catch((error) => {
        console.log(error);
      });

      this.gamePerformanceData.gamesPlayed = responce.data;
    },
    SetTrainingHoursThisSeason() {
      return this.trainingPerformanceData.traningAttended !== 0
        ? (this.trainingPerformanceData.time =
          this.trainingPerformanceData.traningAttended * 60)
        : (this.trainingPerformanceData.time = 0);
    },
    async getAllTraningAttendedById() {
      const userData = {
        user_id: this.selectedUserData.id,
        action: "getAllTraningAttendedById",
        season_start_date: localStorage.getItem(
          "currentPlayingseasonStartDate"
        ),
        season_end_date: this.extendSeasonEndDateToFullYear(),
      };
      const responce = await Games.find(userData).catch((error) => {
        console.log(error);
      });

      this.trainingPerformanceData.traningAttended = responce.data;
      this.SetTrainingHoursThisSeason();
    },
    resetGamePerformanceData() {
      this.gamePerformanceData = {
        gameId: null,
        postion_played: [],
        goals: 0,
        assists: 0,
        clean_sheet: null,
        player_self_rating: null,
        yellowCardTotal: 0,
        redCardTotal: 0,
        redCardTotal: 0,
        timeOnPitch: null,
        gamesPlayed: null,
      };
    },
    resetplayerTrainingData() {
      this.trainingPerformanceData = {};
    },
    resetAllPlayerData() {
      this.resetGamePerformanceData();
      this.resetplayerTrainingData();
    },
    loadPlayersProfilePerformanceData() {
      this.resetAllPlayerData();
      this.getAllGamesAttendedById();
      this.getAllTraningAttendedById();
      this.getThisSeasonsgamePerformanceReportsForUser();
    },
    async archiveUser(userData) {
      userData.userType = "Archived";
      const playerData = {
        ...userData,
        action: "archiveUser",
      };
      const responce = await Users.find(playerData).catch((error) => {
        console.log(error);
      });

      if (responce.status === 200) {
        this.saveTrialistPerformanceReview();
        this.getTrialists();
        this.hideModal("ViewTrialistLeadPlayerProfile");
        this.$bvToast.toast(responce.data, {
          title: "Player archived",
          toaster: "b-toaster-bottom-right",
          solid: true,
          appendToast: true,
          variant: "success",
        });
      }
    },
    async rejectPlayer() {
      this.archiveUser(this.selectedUserData);
    },
    async signPlayer() {
      const data = {
        trialistId: this.selectedTrialistsData.id,
        action: "signTrialistPlayer",
      };

      const responce = await Users.find(data).catch((error) => {
        console.log(error);
      });

      if (responce.status === 200) {
        this.saveTrialistPerformanceReview();
        this.getTrialists();
        this.hideModal("ViewTrialistLeadPlayerProfile");
        this.loadingStuff = false;
        this.$bvToast.toast("Trialist signed.", {
          title: "removed",
          toaster: "b-toaster-bottom-right",
          solid: true,
          appendToast: true,
          variant: "success",
        });

        this.loadPlayersProfilePerformanceData();
      }
    },
    getCoachesName(coach_id) {
      let name = "";
      this.coachData.map((coach) => {
        if (coach.id === coach_id) {
          name = coach.name;
        }
      });
      return name;
    },
    outComeActions(value) {
      value == 1 ? this.signPlayer() : this.rejectPlayer();
    },
    async populateTeamData() {
      const teamData = {
        clubId: this.userData.clubId,
        action: "populateTeamData",
      };

      const responce = await Teams.find(teamData).catch((error) => {
        console.log(error);
      });

      return responce.data.length > 0 ? responce.data : [];
    },
    async removeTrialistFromDB(id) {
      const userData = {
        action: "removeTrialist",
        id: id,
      };

      const responce = await Trialists.find(userData).catch((error) => {
        console.log(error);
      });

      if (responce.status === 200) {
        this.getTrialists();
        this.loadPlayerLeads();
        this.hideModal("ViewTrialist");
        this.loadingStuff = false;
        this.$bvToast.toast("Trialist removed.", {
          title: "removed",
          toaster: "b-toaster-bottom-right",
          solid: true,
          appendToast: true,
          variant: "success",
        });
      }
    },
    recordOutcome(value) {
      this.selectedTrialistsData.outcome = value;
      this.outComeActions(value);
    },
    outComeText(outCome) {
      switch (outCome) {
        case 0:
          return "Under consideration";
        case 1:
          return "Signed";
        case 2:
          return "Rejected";
        case 3:
          return "Waiting list";
      }
    },
    totalScore(trialist) {
      let values = [
        trialist.attitude,
        trialist.skill,
        trialist.physique,
        trialist.mentality,
        trialist.leadership,
        trialist.professionalism,
      ];

      var totalYears = values.reduce(function (accumulator, value) {
        return accumulator + +value;
      }, 0);

      return totalYears;
    },
    getTeamName(teamId) {
      if (teamId === "") {
        return teamId;
      }
      var selectedTeam = this.clubTeams.find(function (team) {
        return team.id == teamId;
      });

      return selectedTeam !== undefined ? selectedTeam.teamName : "";
    },
    trialLength(number) {
      if (number == "6") {
        return "6 weeks";
      }
      if (number == "12") {
        return "12 weeks";
      }
    },
    tabChange(tabValue, selectedTabValue) {
      this[tabValue] = selectedTabValue;

      if (selectedTabValue === "player-documents-tab") {
        if (this.selectedTrialistsData.id !== undefined) {
          this.selectedDocumentData.approvedAccessList.users = [];
          this.selectedDocumentData.approvedAccessList.users.push({
            id: this.selectedTrialistsData.id,
            name: this.selectedTrialistsData.name,
          });
        }

        this.getDocumentUploadsByUserId();
      }

      if (selectedTabValue === "player-videos-tab") {
        this.populatePlayerVideoLinkData();
      }

      if (selectedTabValue === "player-report-tab") {
        this.getAllgoalsScoredByPlayerId();
        this.getAllgoalAssistsByPlayerId();
        this.populatePlayerVideoLinkData();
        this.populateAcademyReport();
        this.produceTheAcademyReport();
      }

      if (selectedTabValue === "player-history-tab") {
        this.populatePlayerCV(this.selectedUserData);
      }
      if (selectedTabValue === "fixtures-tab") {
        this.populatefixtures();
      }
    },
    getImgUrl(pic) {
      return pic !== undefined && pic !== "" ? `/images/${pic}` : "";
    },
    clearImageDataFromUpload() {
      (this.imageURL = ""),
        (this.image = {
          imageName: "",
          path: "",
          file: null,
        });
    },
    imageUploadObjectDataChanged(data) {
      this.image = data;
    },
    imageUploadFileDataChnged(data) {
      this.imageFile = data;
    },
    trialistDataconvertStringsToNumbers(trialist) {
      trialist.attitude = parseInt(trialist.attitude);
      trialist.skill = parseInt(trialist.skill);
      trialist.physique = parseInt(trialist.physique);
      trialist.mentality = parseInt(trialist.mentality);
      trialist.leadership = parseInt(trialist.leadership);
      trialist.professionalism = parseInt(trialist.professionalism);

      return trialist;
    },
    async getUserByEmailAndName(email, name) {
      const userData = {
        name: name,
        email: email,
        action: "getUserByEmailAndName",
      };
      const responce = await Users.find(userData).catch((error) => {
        console.log(error);
      });

      if (responce.status === 200) {
        this.selectedUserData = responce.data;
        this.loadPlayersProfilePerformanceData();
      }
    },
    async populateLeadAndTrialistData(playerData, type) {
      //get trialist id
      let trialistData = this.trialistDataconvertStringsToNumbers(playerData);
      this.selectedTrialistsData = trialistData;

      if (type === "signed") {
        await this.getUserByEmailAndName(playerData.email, playerData.name);
      } else {
        this.getUserByTrialistId(this.selectedTrialistsData.id);
      }

      //populate player lead data
      this.selectedLeadData = this.allPlayerLeadsData.find(
        (lead) => lead.trialistId === playerData.id
      );
      if (this.selectedLeadData === undefined) {
        this.selectedLeadData = {};
      }
    },
    selectedUseChanged(playerData, type, state) {
      if (type === "trialist") {
        this.populateLeadAndTrialistData(playerData, state);
      } else {
        //lead mode
        if (playerData.trialistId !== null) {
          this.selectedTrialistsData = this.trialistsData.find(
            (lead) => Number(lead.id) === Number(playerData.trialistId)
          );
          this.getUserByTrialistId(this.selectedTrialistsData.id);
          this.selectedLeadData = playerData;
        } else {
          this.selectedLeadData = playerData;
          this.selectedUserData = {
            name: playerData.name,
            age_group: playerData.age_group,
            userImage: null,
          };
        }
      }
      this.showModal("ViewTrialistLeadPlayerProfile");
    },
    weekSelectedChanged(number) {
      this.weekSelectedNumber = number.weekSelectedNumber;
    },
    mondaySunDatesChanged(data) {
      this.selectedWeekMonSunday = data.monToFri;
    },
    setMonToSundayWeekDates() {
      const year = JSON.stringify(moment().year());
      const monday = moment()
        .year(year)
        .isoWeek(this.weekSelectedNumber)
        .day("monday")
        .startOf("day");
      this.selectedWeekMonSunday =
        monday.format("MMM D - ") + monday.add(6, "days").format("D YYYY");
      // this.depatchMonToSundayWeekDatesInLocalStorage();
    },
    async getUserByTrialistId(trialistsId) {
      const userData = {
        trialistsId: trialistsId,
        action: "getUserByTrialistId",
      };
      const responce = await Users.find(userData).catch((error) => {
        console.log(error);
      });

      if (responce.status === 200) {
        this.selectedUserData = responce.data;
        this.loadPlayersProfilePerformanceData();
      }
    },
    async getUserById(clientId) {
      const userData = {
        clientId: clientId,
        action: "getUserByID",
      };
      const responce = await Users.find(userData).catch((error) => {
        console.log(error);
      });
      if (responce.data.length > 0) {
        this.userData = responce.data[0];
        const userData = responce.data[0];
        this.$store.dispatch(USER_POPULATE, { userData });
        return true;
      }
    },
    async getUserByIdForHeader(clientId) {
      const userData = {
        clientId: clientId,
        action: "getUserByID",
      };
      const responce = await Users.find(userData).catch((error) => {
        console.log(error);
      });

      if (responce.data.length > 0) {
        this.userHeaderData = responce.data[0];
      }
    },
    addEditCoachDetails() {
      this.editMode !== true
        ? this.addTrialistDetailsSubmit()
        : this.saveTrialistPerformanceReview(); //this.editTrialistDetailsSubmit();
    },
    async saveTrialistPerformanceReview() {
      this.selectedTrialistsData.trial_start_date = this.fotmatDatesForDb(
        this.selectedTrialistsData.trial_start_date
      );

      if (this.image.imageFile !== null && this.imageFile !== null) {
        this.selectedUserData.imageFile = this.image;
        this.selectedUserData.fileName = this.imageFile.imageName;
      }

      this.loadingStuff = true;
      const userData = {
        action: "updateTrialist",
        ...this.selectedTrialistsData,
        trialUserProfileData: this.selectedUserData,
      };

      const responce = await Trialists.find(userData).catch((error) => {
        console.log(error);
      });

      this.getTrialists();
      this.loadPlayerLeads();
      this.hideModal("ViewTrialist");
      this.hideModal("addEditTrialist");
      this.hideModal("ViewTrialistLeadPlayerProfile");
      this.loadingStuff = false;
      this.$bvToast.toast("Trialist updated.", {
        title: "Updated",
        toaster: "b-toaster-bottom-right",
        solid: true,
        appendToast: true,
        variant: "success",
      });
    },
    filterTrialistsIntoCategories() {
      this.liveTrialistsData = this.trialistsData.filter(
        (trialists) => trialists.outcome === 0
      );
      this.liveTrialistsDataFiltered = this.liveTrialistsData;

      this.signedtrialistsData = this.trialistsData.filter(
        (trialists) => trialists.outcome === 1
      );
      this.archivedtrialistsData = this.trialistsData.filter(
        (trialists) => trialists.outcome === 2
      );
    },
    async getOnlyScoutTrialists() {
      const userData = {
        action: "getOnlyScoutTrialists",
        clubId: this.userData.clubId,
        scoutId: this.userData.id,
      };

      const responce = await Trialists.find(userData).catch((error) => {
        console.log(error);
      });

      if (responce.data.length > 0) {
        this.trialistsData = responce.data;
      }

      this.filterTrialistsIntoCategories();

      this.coachData = await this.populateCoachesData();
      this.teamData = await this.populateTeamData();
    },
    async getAllTrialists() {
      const userData = {
        action: "getTrialists",
        clubId: this.userData.clubId,
      };

      const responce = await Trialists.find(userData).catch((error) => {
        console.log(error);
      });

      if (responce.data.length > 0) {
        this.trialistsData = responce.data;
      }

      this.filterTrialistsIntoCategories();

      this.coachData = await this.populateCoachesData();
      this.teamData = await this.populateTeamData();
    },
    async getTrialists() {
      this.getAllTrialists();

      /*this.userData.userType === "Scout"
      ? this.getOnlyScoutTrialists()
      : this.getAllTrialists();*/
    },
    setClubName() {
      if (this.userData.clubId === "surfSoccer-2085XH") {
        return "The big test";
      }

      if (this.userData.clubId === "ptfc-2085XH") {
        return "Partick Thistle";
      }

      if (this.userData.clubId === "hafc-2085XH") {
        return "Hamilton Academical";
      }

      if (this.userData.clubId === "kfc-2085XH") {
        return "kilmarnock fc";
      }
    },
    async getTeamsByUserId() {
      const data = {
        action: "getTeamsDataById",
        userId: this.userData.id,
      };

      if (
        this.userData.userType === "Admin" ||
        this.userData.userType === "Coach"
      ) {
        data.clubId = this.userData.clubId;
      }
      const responce = await Teams.find(data).catch((error) => {
        console.log(error);
      });

      if (this.clubTeams.length > 0) {
        this.clubTeams = this.removeDuplicates(responce.data, "id");
      } else {
        this.clubTeams = await this.populateTeamData();
        this.clubTeams = this.removeDuplicates(this.clubTeams, "id");
      }

      this.season_start_date = this.clubTeams[0].season_start_date;
      this.season_end_date = this.clubTeams[0].season_end_date;

      localStorage.setItem(
        "currentPlayingseasonStartDate",
        this.season_start_date
      );
      localStorage.setItem("currentPlayingseasonEndDate", this.season_end_date);
    },
    async addTrialistDetailsSubmit() {
      this.selectedTrialistsData.attitude = 0;
      this.selectedTrialistsData.skill = 0;
      this.selectedTrialistsData.physique = 0;
      this.selectedTrialistsData.mentality = 0;
      this.selectedTrialistsData.leadership = 0;
      this.selectedTrialistsData.professionalism = 0;
      this.selectedTrialistsData.comments = "";
      this.selectedTrialistsData.outcome = 0;
      this.selectedTrialistsData.clubId = this.userData.clubId;

      this.selectedTrialistsData.leadId = this.selectedLeadData.id;
      this.loadingStuff = true;
      if (this.image.imageFile !== null && this.imageFile !== null) {
        this.selectedTrialistsData.fileName = this.imageFile.imageName;
        this.selectedTrialistsData.imageFile = this.image;
      }
      this.selectedTrialistsData.trial_start_date = this.fotmatDatesForDb(
        this.selectedTrialistsData.trial_start_date
      );
      this.selectedTrialistsData.age_group = this.formatAgeGroupForSQL(
        this.selectedTrialistsData.dob
      );

      this.selectedTrialistsData.coach_name = this.getCoachesName(
        this.selectedUserData.coach_id
      );
      this.selectedTrialistsData.permission = true ? 1 : 0;
      this.selectedTrialistsData.userType = "User";

      if (this.selectedTrialistsData.hasOwnProperty("name") === false) {
        this.selectedTrialistsData.name = this.selectedUserData.name;
      }
      if (this.selectedTrialistsData.hasOwnProperty("dob") === false) {
        this.selectedTrialistsData.dob = this.fotmatDatesForDb(
          this.selectedUserData.dob
        );
      }
      if (
        this.selectedTrialistsData.hasOwnProperty("playing_position") === false
      ) {
        this.selectedTrialistsData.playing_position =
          this.selectedUserData.position;
      }
      if (
        this.selectedTrialistsData.hasOwnProperty("preferred_foot") === false
      ) {
        this.selectedTrialistsData.preferred_foot =
          this.selectedUserData.preferred_foot;
      }
      if (this.selectedTrialistsData.hasOwnProperty("email") === false) {
        this.selectedTrialistsData.email = this.selectedUserData.email;
      }
      if (this.selectedTrialistsData.hasOwnProperty("leadId") === false) {
        this.selectedTrialistsData.leadId = 0;
      }

      if (this.selectedUserData.hasOwnProperty("position") === false) {
        this.selectedUserData.position =
          this.selectedTrialistsData.playing_position;
      }
      if (this.selectedUserData.hasOwnProperty("clubId") === false) {
        this.selectedUserData.clubId = this.userData.clubId;
      }
      if (this.selectedUserData.hasOwnProperty("userImage") === false) {
        this.selectedUserData.userImage = "";
      }
      if (this.selectedUserData.hasOwnProperty("place_of_birth") === false) {
        this.selectedUserData.place_of_birth = "";
      }
      if (this.selectedUserData.hasOwnProperty("country_of_birth") === false) {
        this.selectedUserData.country_of_birth = "";
      }
      if (this.selectedUserData.hasOwnProperty("nationality") === false) {
        this.selectedUserData.nationality = "";
      }
      if (this.selectedUserData.hasOwnProperty("previous_club") === false) {
        this.selectedUserData.previous_club = "";
      }
      if (this.selectedUserData.hasOwnProperty("joined_date") === false) {
        this.selectedUserData.joined_date = null;
      }
      if (this.selectedUserData.hasOwnProperty("nationality") === false) {
        this.selectedUserData.joined_date = null;
      }
      if (this.selectedUserData.hasOwnProperty("preferred_foot") === false) {
        this.selectedUserData.joined_date = null;
      }
      if (this.selectedTrialistsData.hasOwnProperty("age_group") === false) {
        this.selectedTrialistsData.age_group = this.formatAgeGroupForSQL(
          this.selectedUserData.dob
        );
      }

      if (this.selectedUserData.hasOwnProperty("userType") === false) {
        this.selectedUserData.userType = "User";
      }
      if (this.selectedUserData.hasOwnProperty("age_group") === false) {
        this.selectedUserData.age_group = this.formatAgeGroupForSQL(
          this.selectedUserData.dob
        );
      }

      this.selectedTrialistsData.preferred_foot === "Left"
        ? (this.selectedUserData.preferred_foot = 0)
        : (this.selectedUserData.preferred_foot = 1);

      const userData = {
        ...this.selectedTrialistsData,
        registerUser: this.selectedUserData,

        action: "addTrialist",
      };
      const responce = await Trialists.find(userData).catch((error) => {
        console.log(error);
      });

      if (responce.status === 200) {
        this.getTrialists();
        this.loadPlayerLeads();
        this.$bvModal.hide("addEditTrialist");
        this.loadingStuff = false;
        this.hideModal("ViewTrialist");
        this.$bvToast.toast("Trialist added.", {
          title: "Added",
          toaster: "b-toaster-bottom-right",
          solid: true,
          appendToast: true,
          variant: "success",
        });
      }
    },
    resetTrialistData() {
      this.selectedUserData = {};
      this.selectedLeadData = {};
      this.selectedTrialistsData = {};
      this.editMode = false;
    },
    async addEditTrialistModel(trialist) {
      if (trialist !== undefined) {
        this.selectedUserData = {};
        this.selectedLeadData = trialist;
        this.selectedTrialistsData = { ...trialist };

        this.selectedUserData = {
          img: "",
          name: "",
          email: "",
          password: "",
          dob: "",
          position: "",
          phone: "",
          emergency_contact_one: "",
          emergency_contact_two: "",
          userType: "User",
          teamId: 0,
          permission: 0,
          clubId: null,
          coach_id: 0,
          team_module: 1,
          preferred_foot: 0,
          joined_date: null,
          medical: "",
          permission: null,
        };

        this.selectedUserData.name = trialist.name;
        this.selectedUserData.dob = trialist.dob;
        this.selectedUserData.email = trialist.email;
        this.selectedUserData.position = trialist.playing_position;
        this.selectedUserData.age_group = trialist.age_group;
      } else {
        this.selectedUserData = {};
        this.selectedLeadData = {};
        this.selectedTrialistsData = {};
        this.editMode = false;
      }
      this.showModal("addEditTrialist");
    },
    resetSelectedCoach() {
      this.selectedTrialistsData = {
        dob: "",
        trial_length: "6",
        trial_start_date: "",
        preferred_foot: "Right",
        email: "",
        name: "",
        position: "",
        attitude: 0,
        skill: 0,
        physique: 0,
        mentality: 0,
        leadership: 0,
        professionalism: 0,
        comments: "",
        outcome: "0",
        clubId: "",
        teamId: "",
      };

      this.selectedLeadData = {};
      this.selectedTab = "leads-tab";
      this.selectedPlayerInfoTab = "lead-tab";
    },
    setTodaysDate() {
      return dayjs().format("DD-MM-YYYY");
    },
    setHeaderUserData() {
      if (this.getProfile !== null) {
        this.userHeaderData = this.getProfile;
      }
    },
    showModal(modelId) {
      this.$bvModal.show(modelId);
    },
    hideModal(modelId) {
      this.$bvModal.hide(modelId);
    },
    fotmatDatesForDb(date) {
      return moment(date).format("YYYY-MM-DD");
    },
    formatDateForHuman(date) {
      return moment(date).format("MMMM Do YYYY");
    },
    formatDatesForSQL(date) {
      return moment(date).format(moment.HTML5_FMT.DATE);
    },
    formatAgeGroupForSQL(dob) {
      const d = new Date(dob);
      return d.getFullYear();
    },
    dayMonthYear(date) {
      return moment(date).format("D MMM YYYY");
    },
    formatMatchDate(date) {
      return moment(date).format("YYYY-MM-DD");
    },
    setPlayerLeadTextColor(state) {
      switch (state) {
        case "Invited for trial":
          return "green-text";
        case "Still to Watch":
          return "amber-text";
        case "Not at the standard required":
          return "red-text";
        case "Trial invite refused":
          return "red-text";
        default:
          return "";
      }
    },
    populatePlayerCV(data) {
      this.playerCvData.personal_profile = data.personal_profile;
      this.populatePlayerPlayingExperenceData();
      this.populatePlayerEducationData();
      this.populatePlayerInterestsHobbiesData();
      this.populatePlayerVideoLinkData();
    },
    async populatePlayerEducationData() {
      const data = {
        action: "populatePlayerEducationData",
        userId: this.selectedUserData.id,
      };
      const responce = await PlayerCv.find(data).catch((error) => {
        console.log(error);
      });
      if (responce.status === 200) {
        this.playerCvData.education = responce.data;
      }
    },
    async populatePlayerInterestsHobbiesData() {
      const data = {
        action: "populatePlayerInterestsHobbiesData",
        userId: this.selectedUserData.id,
      };
      const responce = await PlayerCv.find(data).catch((error) => {
        console.log(error);
      });
      if (responce.status === 200) {
        this.playerCvData.interests_hobbies = responce.data;
      }
    },
  },
};
</script>
<style lang="scss" scoped>
.scout-id-tab {
  background: #e5e5e5;
}

.filters {
  width: 100%;
  transition: all 0.4s ease-in-out;
}

.filters-sort {
  display: flex;
  flex-wrap: wrap;
  gap: 1rem;
  padding: 1rem;
}

.search-small {
  max-width: 20rem;
}

.filter-small {
  height: 2.5rem;
  border: transparent;
  color: #9ea7b0;

  &:hover,
  &:focus {
    background: transparent;
    color: #7c838a;
    border: transparent;
    outline: transparent;
    box-shadow: 0 0 0 0rem;
  }
}

.scout-id {
  border: 2px solid #e1e5e9;
  padding: 2rem;
  border-radius: 10px;
  width: 50rem !important;
  position: relative;
  margin-bottom: 1rem;
  box-shadow: 0 4px 16px rgba(138, 138, 138, 0.3);
  background: white;

  .background-logo {
    position: absolute;
    top: 1rem;
    right: 1rem;
    opacity: 0.2;
    width: 37%;
  }

  h2 {
    border-bottom: 1px solid #e1e5e9;
    padding-bottom: 1rem;
    margin-bottom: 1rem;

    img {
      margin-right: 1rem;
      width: 4rem;
    }
  }

  .id-info-wrapper {
    display: flex;
    gap: 1rem;

    .user-photo-info {
      border: 1px solid #e1e5e9;
    }

    .id-info {}
  }
}

.other-leads-wrapper {
  margin-top: 3rem;
}

img.user-image-small {
  width: 4rem;
  display: block;
  margin: 0 auto;
  border-radius: 400px;
}

.player-status-text {
  font-size: 2rem;
  line-height: 2rem;
  margin: 1rem 0 0 0;
  padding: 0;
  font-weight: bold;
}

.player-status-text.signed {
  color: #019e53;
}

.player-status-text.rejected {
  color: #ff2850;
}

.red-text {
  color: #ff2850;
  font-weight: bold;
}

.amber-text {
  color: #ffae21;
  font-weight: bold;
}

.amber-text {
  color: #ffae21;
  font-weight: bold;
}

.green-text {
  color: #019e53;
  font-weight: bold;
}

.trial-in-progress {
  color: #61b720;
}

.trial-ended {
  color: #f33256;
}

.player-added {
  border: 1px solid #06a110;
  padding: 1rem;
  border-radius: 4px;
}

.player-watched-details-wrapper {
  .player-watched-details {
    border: 1px solid #dee2e6;
    padding: 2rem;
    margin: 1rem 0;

    .buttons-wrapper {
      display: flex;
      gap: 0.5rem;
    }
  }
}

.trialist-onboarding-message {
  padding: 2rem;
  background: #f3faf2;
}

.table-scroll {
  width: 100%;
  overflow: scroll;

  .w-a-style {
    width: 84rem;
  }
}

.player-report-content {
  margin-top: 1rem;
  width: 100%;
  height: 50rem;

  iframe {
    width: 100%;
    height: 100%;
  }
}

.intro-header {
  border-bottom: none;
}

.player-cv {
  border: 1px solid #a9b3bc;
  max-width: 1000px;
  padding: 2rem;
  overflow: scroll;
}

.cv-section {
  padding-bottom: 1rem;
  margin-bottom: 1rem;
  border-bottom: 1px dashed #dddddd;
}

.each-target.playing-experence-item {
  border-bottom: 1px dashed #dddddd;
  padding-bottom: 1rem;
  margin-bottom: 2rem;
}

.playing-experence-wrapper {
  padding-top: 1rem;

  .playing-experiance-add-button {
    margin-right: 1rem;
  }

  label {
    margin-right: 1rem;
  }
}

.player-cv-content {
  margin-top: 1rem;
  width: 100%;
  height: 50rem;

  iframe {
    width: 100%;
    height: 100%;
  }

  .form__item {
    display: grid;
  }
}

#small_shirt {
  width: 2.5rem;
  margin-right: 1.1rem;
}

.lead-form-wrapper {
  .form__item {
    display: flex !important;
    flex-wrap: wrap;
    gap: 5rem;

    .form__label {
      width: 7rem;
    }

    .form__input {
      width: 71%;
    }

    p {
      margin: 0;
    }
  }
}

.add-new-lead-wrapper {
  width: 100%;
  margin-bottom: 1rem;
}

.player-cv-content {
  .form__input {
    width: 100% !important;
  }
}

.leads-tab {
  padding: 1rem;
}

.two-col-pop {
  display: flex;
  flex-wrap: wrap;
  gap: 1.5rem;
  margin-bottom: 1rem;
  border-bottom: 1px solid #dee2e6;
}

span.vue-star-rating-rating-text {
  font-size: 2rem;
  padding: 0.5rem 1rem 0 1rem;
}

.user-details-pop p span {
  font-weight: bold;
  display: inline-block;
  min-width: 8rem;
}

.trial-performance-wrapper {
  margin-bottom: 2rem;
}

.out-come-buttons {
  display: flex;
  flex-wrap: wrap;
  gap: 1rem;
  margin-bottom: 1rem;
  height: 7rem;
  margin-top: 4rem;

  p {
    width: 100%;
  }

  button {
    min-width: 9rem;
  }

  .red-button {
    background: #d85c64;
  }

  .yellow-button {
    background: #ffc107;
  }
}

.outCome-Under {
  border: 1px solid #004ca3;
  padding: 0.5rem !important;
  margin: 0.5rem 0 !important;
  color: #008afc;
  background: #e8f5ff;
}

.outCome-Signed {
  border: 1px solid #18a300;
  padding: 0.5rem !important;
  margin: 0.5rem 0 !important;
  color: #18a300;
  background: #ecffe8;
}

.outCome-Rejected {
  border: 1px solid #d85c64;
  padding: 0.5rem !important;
  margin: 0.5rem 0 !important;
  color: #d85c64;
  background: #ffe8ec;
}

.outCome-Waiting {
  border: 1px solid #ffc107;
  padding: 0.5rem !important;
  margin: 0.5rem 0 !important;
  color: #ffc107;
  background: #fffbe8;
}

.trialist-tab {
  padding: 1rem;
}

.sessions-info .session-card {
  display: block;
}

.document {
  max-width: 14rem;
  text-align: center;
  padding: 1rem;
  margin: 1rem;
  border-radius: 4px;
  box-shadow: 0 4px 16px rgba(138, 138, 138, 0.3);
  border: 1px solid transparent;

  img {
    width: 40%;
  }

  h3 {
    margin-top: 1rem;
    font-size: 1rem;
    overflow: hidden;
    white-space: nowrap;
    text-transform: inherit;
  }
}

.lead-form-wrapper {
  margin-bottom: 1rem;
}

@media screen and (min-width: 1200px) {
  .video-wrapper {
    width: 20%;
  }

  .document {
    width: 20%;
  }
}

@media screen and (min-width: 1600px) {
  .games-info {
    margin: 2rem 0;
  }
}
</style>
