<template>
  <div>
    <app-template />
    <div class="uk-container uk-container-large">
      <h1>Chats</h1>
      <div class="tabs-wrapper">
        <section class="tab-content">
          <div class="multi-chats-wrapper">
            <div
              class="chat-group-wrapper"
              v-for="team in clubTeams"
              :key="team.id"
            >
              <h3>{{ team.teamName }}</h3>
              <chats-component
                :key="chatComponentKey"
                :userData="userData"
                :selectedTeam="team"
                :currentTeam="team.playersInTeam"
                @getChatGroupInfo="getChatGroupInfo"
                @getNewDataFromCollection="getNewDataFromCollection"
                :teamChatMessages="teamChatMessages"
              />
            </div>
          </div>
        </section>
      </div>
    </div>
  </div>
</template>

<script>
import Vue from "vue";
import { mapGetters } from "vuex";
import { CHECK_PROFILE_HAS_DATA, USER_POPULATE } from "@/store/actions/user.js";
import { Users } from "@/services/user-service.js";
import { Teams } from "@/services/teams-service.js";
import { Chat } from "@/services/chat-service.js";
import ChatsComponent from "../../components/Chats";
import removeDuplicatesMixin from "@/mixin/removeDuplicates.js";
import AppTemplate from "../layouts/App";
import VModal from "vue-js-modal";
import {
  where,
  orderBy,
  query,
  limit,
  collection,
  getDoc,
  onSnapshot,
  addDoc,
  doc,
  updateDoc,
  arrayUnion,
  serverTimestamp,
  getCountFromServer,
} from "firebase/firestore";
import db from "@/firebase/init.js";
import dayjs from "dayjs";

Vue.use(VModal);

export default {
  name: "Chats",
  mixins: [removeDuplicatesMixin],
  components: { AppTemplate, ChatsComponent },
  data() {
    return {
      publicPath: process.env.BASE_URL,
      editMode: false,
      loadingStuff: false,
      numberOfMessagesWithinTheCollection: null,
      chatComponentKey: 0,
      currentTeam: [],
      clubTeams: [],
      teamChats: [],
      selectedUserValue: "",
      weeklyDate: "",
      listOfSelectedplayerIds: [],
      userHeaderData: {
        name: "",
        userType: "",
      },
      userData: {
        name: "",
        userType: "",
      },
    };
  },
  computed: {
    ...mapGetters(["getProfile", "getCoachesUsersData", "getWeekNumber"]),
  },
  created() {
    this.teamChatMessages = collection(db, "team_chat_messages");
    this.checkTheNumberOfMessagesWithinTheCollection();
    this.$store.dispatch(CHECK_PROFILE_HAS_DATA).then((response) => {
      if (response === true) {
        this.setUser();
        this.getUsersTeams();
        this.deleteFireBaseDocs(db);
      } else {
        let clientId = JSON.parse(
          localStorage.getItem("the_w_selectedClientId")
        );
        this.getUserById(clientId).then((response) => {
          if (response === true) {
            this.selectedUserValue = clientId;
            this.getUsersTeams();
            this.deleteFireBaseDocs(db);
          }
        });
      }
    });
  },
  methods: {
    forceRerender() {
      this.chatComponentKey += 1;
    },
    getNewDataFromCollection() {
      this.forceRerender();
    },
    async deleteFireBaseDocs(db) {
      var userdelete_query = db
        .collection("team_chat_messages")
        .where(
          "createdDate",
          "<=",
          `${dayjs().subtract(30, "days").format("YYYY-MM-DD")}T00:00:00.000Z`
        )
        .where("clubId", "==", this.userData.clubId.toString());

      userdelete_query.get().then((querySnapshot) => {
        querySnapshot.forEach((doc) => {
          doc.ref.delete();
        });
      });
    },
    async checkTheNumberOfMessagesWithinTheCollection() {
      if (this.numberOfMessagesWithinTheCollection === null) {
        const snapshot = await getCountFromServer(this.teamChatMessages);
        this.numberOfMessagesWithinTheCollection = snapshot.data().count;
      }
    },
    getChatGroupInfo(data) {
      if (data.chatGroup.length > 0) {
        const q = query(
          data.collection,
          where("chatId", "==", data.chatGroup[0].id.toString()),
          where("clubId", "==", this.userData.clubId.toString()),
          orderBy("createdDate", "asc"),
          limit(100)
        );

        this.chatListener = onSnapshot(q, (querySnapshot) => {
          querySnapshot.forEach((doc) => {
            let chatMessage = doc.data();

            if (chatMessage.readById !== undefined) {
              let readMessage = chatMessage.readById.includes(
                this.userData.id.toString()
              );

              const chatIdElement = document.getElementById(chatMessage.chatId);
              const seconddiv = chatIdElement.querySelector(
                ".number-of-messages"
              );

              seconddiv.innerHTML === "0";

              if (readMessage !== true) {
                if (seconddiv.innerHTML === "0") {
                  seconddiv.innerHTML = 1;
                } else {
                  seconddiv.innerHTML = Number(seconddiv.innerHTML) + 1;
                }

                document
                  .getElementById(chatMessage.chatId)
                  .querySelectorAll(".hidden")
                  .forEach(function (el) {
                    el.classList.remove("hidden");
                  });
              }

              if (readMessage === true && seconddiv.innerHTML === "0") {
                document
                  .getElementById(chatMessage.chatId)
                  .querySelectorAll(".number-of-messages")
                  .forEach(function (el) {
                    el.classList.add("hidden");
                  });
              }
            }
          });
        });
      }
    },
    async getUserByTeamId(id) {
      const userData = {
        teamId: id,
        action: "getUserByTeamId",
      };
      const responce = await Users.find(userData).catch((error) => {
        console.log(error);
      });
      if (responce.data.length > 0) {
        return responce.data;
      }
    },
    populateAllPlayersInTeam() {
      this.clubTeams.map(async (team) => {
        team.playersInTeam = await this.getUserByTeamId(team.id);
        let listOfSelectedplayerIds = this.currentTeam.map(
          (player) => player.id
        );
      });
    },
    async getTeamsByUserId() {
      const data = {
        action: "getTeamsDataById",
        userId: this.userData.id,
      };

      if (this.userData.userType === "Admin") {
        data.clubId = this.userData.clubId;
      }

      const responce = await Teams.find(data).catch((error) => {
        console.log(error);
      });

      this.clubTeams = this.removeDuplicates(responce.data, "id");
      this.populateAllPlayersInTeam();
    },
    async getTeamsByCoachid() {
      const data = {
        action: "getTeamsByCoachid",
        coach_id: this.userData.id,
      };

      const responce = await Teams.find(data).catch((error) => {
        console.log(error);
      });

      if (responce.data.length > 0) {
        this.clubTeams = this.removeDuplicates(responce.data, "id");
        this.populateAllPlayersInTeam();
      }
    },
    getUsersTeams() {
      if (this.userData.userType === "Admin") {
        this.getTeamsByUserId();
      } else if (this.userData.userType === "Coach") {
        this.getTeamsByCoachid();
      } else {
        this.getTeamsByUserId();
      }
    },
    async getUserById(clientId) {
      const userData = {
        clientId: clientId,
        action: "getUserByID",
      };
      const response = await Users.find(userData).catch((error) => {
        console.log(error);
      });
      if (response.data.length > 0) {
        this.userData = response.data[0];
        const userData = response.data[0];
        this.$store.dispatch(USER_POPULATE, { userData });
        if (
          this.userData.userType === "Admin" ||
          this.userData.userType === "Coach"
        ) {
        }
        return true;
      }
    },
    setUser() {
      if (this.getProfile !== null) {
        this.userData = this.getProfile;
      }
    },
    setHeaderUserData() {
      if (this.getProfile !== null) {
        this.userHeaderData = this.getProfile;
      }
    },
  },
};
</script>
<style scoped lang="scss">
.multi-chats-wrapper {
  display: flex;
  flex-wrap: wrap;
  gap: 2rem;

  .chat-group-wrapper {
    border: 1px solid #e0e5e9;
    padding: 1rem;
    min-width: 21rem;
  }
}

.document-uploads-tab {
  padding: 2rem;
}

.signed-documents-wrapper {
  display: block;

  .signed-documents {
    display: flex;
    flex-wrap: wrap;
    gap: 1rem;
  }
}

.tabs-wrapper .tab-content {
  padding: 1rem;
}

.sorting-wrapper {
  display: flex;
  flex-wrap: wrap;
  gap: 1rem;
  align-items: start;

  .filter-by {
    background: transparent;
  }

  .search {
    max-width: 20rem;

    input {
      padding: 0.5rem;
      font-size: 0.875rem;
      border-radius: 0;
      background: "white";
    }

    .input-group-text {
      padding: 0.5rem;
      border-radius: 0;
    }
  }
}

.access-pool {
  padding: 1rem;
  border: 1px solid #dddddd;
  display: flex;
  flex-wrap: wrap;
  gap: 1rem;

  .has-access {
    background: #e3f0fb;
    padding: 0.5rem;
    display: block;
    border-radius: 10px;
    cursor: pointer;

    &:hover,
    &:focus {
      background: #f8e0e0;
    }
  }
}

.buttons-row {
  margin-top: 1rem;
  padding-top: 1rem;
}

.file-upload {
  border: 1px solid #dddddd;
  padding: 1rem;
  margin-bottom: 1rem;
}

.documents-tab {
  display: flex;
  flex-wrap: wrap;

  .document {
    max-width: 14rem;
    text-align: center;
    padding: 1rem;
    margin: 1rem;
    border-radius: 4px;
    box-shadow: 0 4px 16px rgba(138, 138, 138, 0.3);
    border: 1px solid transparent;

    &:hover,
    &:focus {
      border: 1px solid #dcca0d;
    }

    h3 {
      margin-top: 1rem;
      font-size: 1rem;
      overflow: hidden;
      white-space: nowrap;
    }

    img {
      width: 100%;
    }
  }
}

.doc-buttons {
  display: flex;
  gap: 1rem;
  justify-content: center;
  margin-top: 1rem;

  svg {
    border: 1px solid #c3cdd5;
    padding: 0.3rem;
    font-size: 1.7rem;

    &:hover,
    &:focus {
      cursor: pointer;
      background: #eaeff3;
    }
  }
}
</style>
