<template>
  <div>
    <spinner-loader :loadingStuff="loadingStuff" />
    <app-template />
    <template>
      <div v-if="userData.name !== ''" class="uk-container uk-container-large">
        <div class="sessions-week-layout">
          <ul class="tabs">
            <li class="tab-item">
              <a :class="selectedTab === 'current-week-tab'
                  ? 'tab-link active'
                  : 'tab-link'
                " @click="tabChange('selectedTab', 'current-week-tab')" href="javascript:;">Your Apps</a>
            </li>

            <!-- <li class="tab-item" v-if="userData.trialistId === null">
              <a
                :class="
                  selectedTab === 'actions-tab' ? 'tab-link active' : 'tab-link'
                "
                @click="tabChange('selectedTab', 'actions-tab')"
                href="javascript:;"
              >
                <span class="number-alert" v-if="playerActionNumber > 0">{{
                  playerActionNumber
                }}</span
                >Your Actions</a
              >
            </li>-->
            <li class="tab-item" v-if="
              userData.userType === 'Admin' || userData.userType === 'Coach'
            ">
              <a :class="selectedTab === 'notifications-tab'
                  ? 'tab-link active'
                  : 'tab-link'
                " @click="tabChange('selectedTab', 'notifications-tab')" href="javascript:;">
                <span class="number-alert" v-if="playerActionNumber > 0">{{
                  playerActionNumber
                  }}</span>Notifications</a>
            </li>
          </ul>
          <div class="tabs-wrapper">
            <div class="current-week-tab tab-content" v-if="selectedTab === 'current-week-tab'">
              <div v-if="
                userData.trialistId === null && userData.userType !== 'Scout'
              " class="training-session-entry">
                <div class="session-content large-app">
                  <router-link :to="{ name: 'profile' }" class="uk-navbar-item uk-logo">
                    <div class="session-data">
                      <img src="../img/boot.svg" />
                      <h3 v-if="
                        userData.userType !== 'Admin' &&
                        userData.userType !== 'Coach' &&
                        userHasMoreThanOneAccount === null
                      ">
                        Your profile
                      </h3>
                      <h3 v-else>Overview</h3>
                    </div>
                  </router-link>
                </div>
              </div>

              <div class="training-session-entry" v-if="userData.userType !== 'Scout'">
                <div class="session-content large-app">
                  <router-link :to="{ name: 'teams' }" class="uk-navbar-item uk-logo">
                    <div class="session-data">
                      <img src="../img/teams-icon.svg" />
                      <h3>Teams information</h3>
                    </div>
                  </router-link>
                </div>
              </div>

              <div class="training-session-entry" v-if="
                userData.userType === 'Admin' ||
                userData.userType === 'Scout' ||
                userData.userType === 'Coach'
              ">
                <div class="session-content large-app">
                  <router-link :to="{ name: 'trialists' }" class="uk-navbar-item uk-logo">
                    <div class="session-data">
                      <img src="../img/player-change.svg" />
                      <h3>Trialists</h3>
                    </div>
                  </router-link>
                </div>
              </div>

              <div class="training-session-entry" v-if="
                userData.userType === 'Admin' || userData.userType === 'Coach'
              ">
                <div class="session-content large-app">
                  <router-link :to="{ name: 'booking' }" class="uk-navbar-item uk-logo">
                    <div class="session-data">
                      <img src="../img/pitch-icon.svg" />
                      <h3>Resources and booking</h3>
                    </div>
                  </router-link>
                </div>
              </div>

              <div class="training-session-entry" v-if="
                userData.userType === 'Admin' || userData.userType === 'Coach'
              ">
                <div class="session-content large-app">
                  <router-link :to="{ name: 'coaches' }" class="uk-navbar-item uk-logo">
                    <div class="session-data">
                      <img src="../img/strip.svg" />
                      <h3>Coaches</h3>
                    </div>
                  </router-link>
                </div>
              </div>

              <div class="training-session-entry" v-if="
                userData.userType === 'Admin' ||
                userData.userType === 'Coach' ||
                userData.userType === 'Scout'
              ">
                <div class="session-content large-app">
                  <router-link :to="{ name: 'scouts' }" class="uk-navbar-item uk-logo">
                    <div class="session-data">
                      <img src="../img/ball.svg" />
                      <h3 v-if="userData.userType === 'Scout'">Your profile</h3>
                      <h3 v-else>Scouts</h3>
                    </div>
                  </router-link>
                </div>
              </div>

              <div class="training-session-entry" v-if="
                userData.pt_module != '0' && userData.userType !== 'Scout'
              ">
                <div class="session-content large-app">
                  <router-link v-if="
                    userData.userType === 'Admin' ||
                    userData.userType === 'Coach'
                  " :to="{ name: 'ptHome' }" class="uk-navbar-item uk-logo">
                    <div class="session-data">
                      <img src="../img/clock.svg" />
                      <h3>Training plans</h3>
                    </div>
                  </router-link>
                  <router-link v-else :to="{ name: 'ptUserHome' }" class="uk-navbar-item uk-logo">
                    <div class="session-data">
                      <img src="../img/clock.svg" />
                      <h3>Personal Training</h3>
                    </div>
                  </router-link>
                </div>
              </div>

              <div class="training-session-entry" v-if="userData.userType === 'Admin'">
                <div class="session-content large-app">
                  <router-link :to="{ name: 'documents' }" class="uk-navbar-item uk-logo">
                    <div class="session-data">
                      <img src="../img/documentlib.svg" />
                      <h3>Documents library</h3>
                    </div>
                  </router-link>
                </div>
              </div>

              <div class="training-session-entry" v-if="userData.userType !== 'Scout'">
                <div class="session-content large-app">
                  <router-link :to="{ name: 'chats' }" class="uk-navbar-item uk-logo">
                    <div class="session-data">
                      <img src="../img/chat.svg" />
                      <h3>Chats</h3>
                    </div>
                  </router-link>
                </div>
              </div>
              <!--<div class="training-session-entry">
                <div class="session-content large-app">
                  <router-link
                    :to="{ name: 'payments' }"
                    class="uk-navbar-item uk-logo"
                  >
                    <div class="session-data">
                      <img src="../img/payments.svg" />
                      <h3>Payments / subscriptions</h3>
                    </div>
                  </router-link>
                </div>
              </div>-->
            </div>
            <div class="actions-tab tab-content" v-if="selectedTab === 'actions-tab'">
              <div v-if="
                userData.userType !== 'Admin' || userData.userType === 'Coach'
              " class="padding-wrapper">
                <div v-if="playerActionNumber == 0">
                  <div class="empty-state">
                    <img src="../img/emptyStateIllustration.svg" />
                    <p>You have no actions, Good job!</p>
                  </div>
                </div>

                <b-alert v-if="gamePerformanceData.created_date === null" show variant="danger">
                  <p>
                    <b-icon icon="file-text" aria-hidden="true"></b-icon> You
                    have not submitted any Game reports - Please submit one now
                    -
                    <router-link :to="{ name: 'profile' }">Profile</router-link>
                  </p>
                </b-alert>

                <b-alert v-if="trainingPerformanceData.created_date === null" show variant="danger">
                  <p>
                    <b-icon icon="file-text" aria-hidden="true"></b-icon> You
                    have not submitted any Training reports - Please submit one
                    now -
                    <router-link :to="{ name: 'profile' }">Profile</router-link>
                  </p>
                </b-alert>

                <b-alert v-if="checkDateTimeFromNow(gamePerformanceData.created_date)" show variant="danger">
                  <p>
                    <b-icon icon="file-text" aria-hidden="true"></b-icon> You
                    last submitted a Game report on
                    {{ formatDate(gamePerformanceData.created_date) }} - Please
                    submit one now -
                    <router-link :to="{ name: 'profile' }">Profile</router-link>
                  </p>
                </b-alert>

                <b-alert v-if="
                  checkDateTimeFromNow(trainingPerformanceData.created_date)
                " show variant="danger">
                  <p>
                    <b-icon icon="file-text" aria-hidden="true"></b-icon> You
                    last submitted a Training report on
                    {{ formatDate(trainingPerformanceData.created_date) }} -
                    Please submit one now -
                    <router-link :to="{ name: 'profile' }">Profile</router-link>
                  </p>
                </b-alert>
              </div>
            </div>
            <div class="notifications-tab tab-content" v-if="selectedTab === 'notifications-tab'">
              <div v-if="
                userData.userType === 'Admin' || userData.userType === 'Coach'
              " class="padding-wrapper">
                <div class="form__item">
                  <label class="form__label" for="notifications-radios">Select type of message</label>
                  <div class="radio-group-flex">
                    <b-form-radio v-model="notification.type" name="notifications-radios"
                      value="Email">Email</b-form-radio>
                    <b-form-radio v-model="notification.type" name="notifications-radios" value="Notificationeft">App
                      notification</b-form-radio>
                    <!--<b-form-radio
                      v-model="notification.type"
                      name="notifications-radios"
                      value="Both"
                      >Both</b-form-radio
                    >-->
                  </div>
                </div>

                <div class="form__item">
                  <label class="form__label" for="title">Title</label>

                  <b-form-input id="textarea" v-model="notification.title" placeholder="" rows="10"
                    max-rows="100"></b-form-input>
                </div>

                <div class="form__item">
                  <label class="form__label" for="textarea">Message</label>

                  <b-form-textarea id="textarea" v-model="notification.message" placeholder="" rows="10"
                    max-rows="100"></b-form-textarea>
                </div>

                <div class="form__item">
                  <b-form-checkbox @change="ageGroupClear($event)" id="sendToEveryone" v-model="notification.all"
                    name="sendToEveryone" value="1" unchecked-value="0">Send to everyone
                  </b-form-checkbox>
                </div>

                <div class="form__item">
                  <label class="form__label" for="userSelect">Select team</label>

                  <div class="user-select-wrapper">
                    <div v-for="team in clubTeams" :key="team.id">
                      <input @change="ageGroupAddRemove($event)" type="checkbox" :id="team.id" :name="team.teamName"
                        :value="team.age_group" :checked="team.selected" />
                      <label :for="team.id">{{ team.teamName }}</label><br />
                    </div>
                  </div>
                </div>

                <button @click="sendNotification()" class="green-button">
                  Send notification
                </button>
              </div>

              <div class="padding-wrapper notifications-history">
                <h5>Notifications history</h5>

                <div class="scroll-wrapper">
                  <div class="history-list" v-for="notification in notificationHistory" :key="notification.id">
                    <p class="date">{{ formatDate(notification.sent_date) }}</p>
                    <p class="title">{{ notification.title }}</p>
                    <p class="body">{{ notification.body }}</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </template>
    <!-- <template v-else>
      <div class="big-message">
        <h2>Please fill out your Player profile to get started.</h2>
        <button class="green-button">Create Player profile </button>
      </div>
    </template>-->
    <b-modal class="full-screen-modal" id="playerProfile" size="lg" title="Create your player profile"
      :no-close-on-backdrop="true" :hide-footer="Boolean(true)">
      <p>Welcome {{ userData.name }}</p>
      <p>
        To get you fully onboarded. We need you to fill out your Player profile
        / bio and self assessment.
      </p>
      <form class="form" @submit.prevent="createPlayerProfile()">
        <section class="player-profile-part-one" v-if="playerProfileForm.sectionOne === true">
          <h3>More Details</h3>
          <div class="form__item">
            <label class="form__label" for="imageURL">Upload image</label>
            <input accept="image/jpeg|image/jpg|image/heic|.jpeg"
              @change="imageChange($event, 'imageFile', 'image', 'fileOne')" type="file" class="form__input"
              ref="fileOne" id="imageURL" />
          </div>
          <div class="form__item">
            <label class="form__label" for="name">Full Name</label>
            <input type="text" v-model="playerProfileData.name" id="name" />
          </div>

          <div class="form__item">
            <label class="form__label" for="email">Email Address</label>
            <input type="email" v-model="playerProfileData.email" id="email" />
          </div>

          <div class="form__item uk-form dob">
            <label class="form__label" for="dob">DOB</label>
            <datepicker v-model="playerProfileData.dob" name="dob" id="dob" />
          </div>

          <div class="form__item">
            <label class="form__label" for="coachSelect">Select coach</label>
            <select v-model="playerProfileData.coachId" id="coachSelect">
              <option disabled value="">Please select a coach</option>
              <option v-for="coach in coachData" :value="coach.id" :key="coach.id">
                {{ coach.name }}
              </option>
            </select>
          </div>

          <div class="form__item">
            <label class="form__label" for="position">Position</label>
            <select id="position" v-model="playerProfileData.position">
              <option disabled value="">Please select a position</option>
              <option value="Goalkeeper">Goalkeeper</option>
              <option value="Right Full-back (Wingback)">
                Right Full-back (Wingback)
              </option>
              <option value="Left Full-back (Wingback)">
                Left Full-back (Wingback)
              </option>
              <option value="Center-back left">Center-back left</option>
              <option value="Center-back right">Center-back right</option>
              <option value="Defensive Midfielder">Defensive Midfielder</option>
              <option value="Centre midfield">Centre midfield</option>
              <option value="Centre Defensive Midfield">
                Centre Defensive Midfield
              </option>
              <option value="Right Midfielder (Winger)">
                Right Midfielder (Winger)
              </option>
              <option value="Left Midfielder (Winger)">
                Left Midfielder (Winger)
              </option>
              <option value="Attacking Midfielder">Attacking Midfielder</option>
              <option value="Center Forward (Striker) left">
                Center Forward (Striker) left
              </option>
              <option value="Center Forward (Striker) right">
                Center Forward (Striker) right
              </option>
            </select>
          </div>
        </section>

        <section v-if="playerProfileForm.sectionTwo === true">
          <h3>Your Profile</h3>
          <div class="form__item">
            <label class="form__label" for="name">Height</label>
            <input type="text" v-model="playerProfileData.height" id="name" />
          </div>

          <div class="form__item">
            <label class="form__label" for="foot-radios">Preferred foot</label>
            <div class="radio-group-flex">
              <b-form-radio v-model="playerProfileData.foot" name="foot-radios" value="Right">Right</b-form-radio>
              <b-form-radio v-model="playerProfileData.foot" name="foot-radios" value="Left">Left</b-form-radio>
              <b-form-radio v-model="playerProfileData.foot" name="foot-radios" value="Both">Both</b-form-radio>
            </div>
          </div>
        </section>

        <section v-if="playerProfileForm.sectionThree === true">
          <p class="more-chat">
            Tell us more about the teams you have played for.
          </p>
          <h3>Playing History / awards, previous tournaments </h3>

          <div class="form__item">
            <label class="form__label" for="name">Information</label>
            <wysiwyg v-model="playerProfileData.history" />
          </div>
        </section>

        <section v-if="playerProfileForm.sectionFour === true">
          <p class="more-chat">
            Tell us more about any other footballing qualifcations or
            experixances you have had.
          </p>
          <h3>Football Experience / courses</h3>

          <div class="form__item">
            <label class="form__label" for="name">Information</label>
            <wysiwyg v-model="playerProfileData.courses" />
          </div>
        </section>

        <section v-if="playerProfileForm.sectionFive === true">
          <p class="more-chat">
            Tell us about any dietary requierments you have, like allages etc..
          </p>
          <h3>Nutrition requierments</h3>

          <div class="form__item">
            <label class="form__label" for="name">Information</label>
            <b-form-textarea id="textarea" v-model="playerProfileData.nutrition" placeholder="Enter something..."
              rows="3" max-rows="6"></b-form-textarea>
          </div>
        </section>

        <section v-if="playerProfileForm.sectionSix === true">
          <p class="more-chat">
            Tell us about what else you like to do in your spare time.
          </p>
          <h3>Interests</h3>

          <div class="form__item">
            <label class="form__label" for="name">Information</label>
            <b-form-textarea id="textarea" v-model="playerProfileData.interests" placeholder="Enter something..."
              rows="3" max-rows="6"></b-form-textarea>
          </div>
        </section>

        <section v-if="playerProfileForm.sectionSeven === true">
          <p class="more-chat">We need to ask :-).</p>
          <h3>Discipline reds / yellow / bans</h3>

          <div class="form__item">
            <label class="form__label" for="name">Information</label>
            <b-form-textarea id="textarea" v-model="playerProfileData.discipline" placeholder="Enter something..."
              rows="3" max-rows="6"></b-form-textarea>
          </div>
        </section>

        <section v-if="playerProfileForm.sectionEight === true">
          <p class="more-chat">Do you have any football videos or reels?</p>
          <h3>Football Highlight Videos</h3>

          <div class="form__item">
            <label class="form__label" for="name">Information</label>
            <b-form-textarea id="textarea" v-model="playerProfileData.videos" placeholder="Enter something..." rows="3"
              max-rows="6"></b-form-textarea>
          </div>
        </section>

        <div class="buttons-flex">
          <button @click="formSectionsMove('back')" class="green-button">
            <b-icon icon="arrow-left" aria-hidden="true"></b-icon> Back
          </button>

          <button v-if="playerProfileForm.sectionEight === true" @click="formSectionsMove('next')" class="green-button">
            Save
          </button>
          <button v-else @click="formSectionsMove('next')" class="green-button">
            Next <b-icon icon="arrow-right" aria-hidden="true"></b-icon>
          </button>
        </div>
      </form>
    </b-modal>

    <b-modal id="webPermmissionsModal" size="lg" title="Push notifications permissions" :hide-footer="Boolean(true)">
      <h2>Permissons</h2>
      <p>
        We would love to send you push notifications on team events and other
        amazing stuff.
      </p>

      <button @click="requestPermission()" class="green-button">
        Allow notifications
      </button>
    </b-modal>

    <b-modal id="showMessageModal" size="lg" title="Message" :hide-footer="Boolean(true)">
      <h2>{{ currentMessage.notification.title }}</h2>
      <p>
        {{ currentMessage.notification.body }}
      </p>
    </b-modal>

    <b-modal id="showNotificationsNotSupportedModal" size="lg" title="Message" :hide-footer="Boolean(true)">
      <h2>Upgrade or change your browser</h2>
      <p>
        Your current browser does not support notifications. Please upgrade or
        switch your browser to access all the features of the app.
      </p>
    </b-modal>

    <b-modal id="showGrantPermissionsModal" size="lg" title="Message" :hide-footer="Boolean(true)">
      <h2>Please allow notifications</h2>
      <p>
        Your current browser has notifications blocked. Please grant access to
        this app so you can access all the features of the app and receive
        notifications.
      </p>

      <a href="https://support.google.com/chrome/answer/3220216?hl=en-GB&co=GENIE.Platform%3DAndroid">Chrome browser</a>
      <a
        href="https://support.apple.com/en-gb/guide/safari/sfri40734/mac#:~:text=website%20notifications%20appear-,On%20your%20Mac%2C%20choose%20Apple%20menu%20%3E%20System%20Settings%2C%20then,may%20need%20to%20scroll%20down.)&text=settings%20for%20me-,Go%20to%20Application%20Notifications%20on%20the%20right%2C%20click%20the%20website,alert%20style%20and%20other%20settings.">Safari
        browser</a>
      <a href="https://support.mozilla.org/en-US/kb/push-notifications-firefox">FireFox browser</a>
    </b-modal>
  </div>
</template>

<script>
import Vue from "vue";
import { mapGetters } from "vuex";
import Datepicker from "vuejs-datepicker";
import { CHECK_PROFILE_HAS_DATA, USER_POPULATE } from "@/store/actions/user.js";
import { Users } from "@/services/user-service.js";
import { Games } from "@/services/games-service.js";
import { Teams } from "@/services/teams-service.js";
import { Chat } from "@/services/chat-service.js";
import { Mail } from "@/services/mail-service.js";
import { PushNotifications } from "@/services/pushNotifications-service.js";
import AppTemplate from "../layouts/App";
import SpinnerLoader from "../../components/LoadingSpinning";
import VModal from "vue-js-modal";
import wysiwyg from "vue-wysiwyg";
import extendSeasonEndDateToFullYearMixin from "@/mixin/extendSeasonEndDateToFullYear.js";
import moment from "moment";
import checkIfUserHasMoreThanOneAccountMixin from "@/mixin/checkIfUserHasMoreThanOneAccount.js";
import { getMessaging, getToken, onMessage } from "firebase/messaging";

Vue.use(VModal);
Vue.use(wysiwyg, {
  forcePlainTextOnPaste: true,
});

export default {
  name: "Dashboard",
  components: { AppTemplate, SpinnerLoader, Datepicker },
  mixins: [
    checkIfUserHasMoreThanOneAccountMixin,
    extendSeasonEndDateToFullYearMixin,
  ],
  data() {
    return {
      currentMessage: {
        notification: {
          title: "",
          body: "",
        },
      },
      clubTeams: [],
      listOfSelectedTeamIds: [],
      listOfSelectedTeamIdsToRemove: [],
      listOfSelectedYear: [],
      listOfSelectedYearToRemove: [],
      notification: {
        type: "Both",
        message: "",
        all: 0,
      },
      userHasMoreThanOneAccount: null,
      gamePerformanceData: {
        created_date: null,
      },
      trainingPerformanceData: {
        created_date: null,
      },
      playerProfileFormSelection: "sectionOne",
      playerProfileForm: {
        sectionOne: true,
        sectionTwo: false,
        sectionThree: false,
        sectionFour: false,
        sectionFive: false,
        sectionSix: false,
        sectionSeven: false,
        sectionEight: false,
      },
      notificationHistory: [],
      coachData: [],
      playerProfileData: {},
      playerHasProfile: false,
      publicPath: process.env.BASE_URL,
      loadingStuff: false,
      selectedUserValue: "",
      weeklyDate: "",
      userData: {
        name: "",
        userType: "",
      },
      coachesUsersData: [],
      selectedTab: "current-week-tab",
      currentPlayingSeason: null,
      weekSelectedNumber: "",
      playerActionNumber: null,
    };
  },
  computed: {
    // mix the getters into computed with object spread operator
    ...mapGetters(["getProfile"]),
  },
  created() {
    this.loadingStuff = true;
    const now = moment().format("MM-DD-YYYY");
    this.weekSelectedNumber = moment(now).isoWeek();
    this.userHasMoreThanOneAccount = this.checkIfUserHasMoreThanOneAccount();
    this.$store.dispatch(CHECK_PROFILE_HAS_DATA).then((response) => {
      if (response === true) {
        this.setUser();
        this.getUserById(this.userData.id);
        //this.checkPlayerHasProfile();
        if (
          this.userData.userType !== "Admin" &&
          this.userData.userType !== "Coach" &&
          this.userHasMoreThanOneAccount === null
        ) {
          this.getTeamsDataById(this.userData.teamId);
          this.setPlayingSeasion();
        }
        this.getTeamsByUserId();
        this.loadingStuff = false;
      } else {
        const clientId = JSON.parse(
          localStorage.getItem("the_w_selectedClientId")
        );
        this.getUserById(clientId).then((response) => {
          if (response === true) {
            this.selectedUserValue = clientId;
            if (
              this.userData.userType !== "Admin" &&
              this.userData.userType !== "Coach" &&
              this.userHasMoreThanOneAccount === null
            ) {
              this.getTeamsDataById(this.userData.teamId);
              this.setPlayingSeasion();
            }
            //this.checkPlayerHasProfile();
          }
          this.getTeamsByUserId();
          this.loadingStuff = false;
        });
      }
    });
    if (
      this.userData.userType !== "Admin" ||
      this.userData.userType !== "Coach"
    ) {
      this.getNotificationHistory();
    }
  },
  mounted() {
    this.checkPermisson();
  },
  methods: {
    async getNotificationHistory() {
      const data = {
        action: "getNotificationHistory",
      };
      const responce = await Chat.find(data).catch((error) => {
        console.log(error);
      });

      if (responce.status === 200) {
        this.notificationHistory = responce.data;
      }
    },
    async sendFBNotificationMessage() {
      const data = {
        action: "sendFBNotificationMessage",
        title: this.notification.title,
        body: this.notification.message,
        link: `${window.location.origin}/login`,
        age_group: this.listOfSelectedYear,
      };
      const responce = await Chat.find(data).catch((error) => {
        console.log(error);
      });

      if (responce.status === 200) {
        this.$bvToast.toast("Notification sent", {
          title: "Notification sent",
          toaster: "b-toaster-bottom-right",
          solid: true,
          appendToast: true,
          variant: "success",
        });

        this.getNotificationHistory();

        this.notification = {
          title: "",
          message: "",
          age_group: "0000",
        };
      }
    },
    showBrowserDoesNotSupportNotificationsModal() {
      this.$bvModal.show("showNotificationsNotSupportedModal");
    },
    async sendPushNotification(currentToken) {
      this.loadingStuff = true;
      const data = {
        action: "sendNotificationBackend",
        name: this.userData.name,
        message: this.notification.message,
        sendToAll: this.notification.all,
        idsList: this.listOfSelectedTeamIds,
        link: `${window.location.origin}/login`,
        deviceToken: currentToken,
      };

      const responce = await Mail.find(data).catch((error) => {
        console.log(error);
      });
    },
    checkPermisson() {
      if (
        navigator.serviceWorker &&
        window.PushManager &&
        window.Notification
      ) {
        console.log("the device supports the Web Push API");
        this.requestPermission();
      } else {
        // the device does not support the Web Push API
        console.log("the device does not support the Web Push API");
        this.showBrowserDoesNotSupportNotificationsModal();
      }
    },
    showWebPushPermissionsModel() {
      this.$bvModal.show("webPermmissionsModal");
    },
    async saveCurrentFBPermissionsToken(token) {
      const data = {
        action: "sendNotifsaveCurrentFBPermissionsTokenicationBackend",
        token: token,
        userId: this.userData.id,
        age_group: this.userData.age_group,
      };

      const responce = await Chat.find(data).catch((error) => {
        console.log(error);
      });
      this.$bvModal.hide("webPermmissionsModal");
    },
    async requestPermission() {
      Notification.requestPermission().then((permission) => {
        if (permission === "granted") {
          const messaging = getMessaging();

          onMessage(messaging, (payload) => {
            console.log("Message received. ", payload);
            this.currentMessage = payload;
            this.$bvModal.show("showMessageModal");
          });
          getToken(messaging, {
            vapidKey:
              "BKjh5WgXQ98IFuUQ2h8dWrAv96RfO4Wqa8zyaQ3v9Eeg11qYNUQA1wMjBh-56gXcGu-Z0uPSWGc0jLMntioXWx8",
          })
            .then((currentToken) => {
              if (currentToken) {
                //const currentToken = this.getUsersFBTokenById();
                //if (tokenIsFresh === true) {
                this.saveCurrentFBPermissionsToken(currentToken);
                //} else {
                //  console.log("need a new token");
                //}
              } else {
                // Show permission request UI
                console.log(
                  "No registration token available. Request permission to generate one."
                );
                this.showWebPushPermissionsModel();
              }
            })
            .catch((err) => {
              console.log("An error occurred while retrieving token. ", err);
            });
        } else {
          this.$bvModal.show("showGrantPermissionsModal");
        }
      });
    },
    addTolistOfSelectedTeamIds(id) {
      this.listOfSelectedTeamIds.push(id);
      this.listOfSelectedTeamIdsToRemove =
        this.listOfSelectedTeamIdsToRemove.filter((item) => item !== id);
    },
    addToListOfSelectedTeamAgeGroups(year) {
      const yearMap = {
        2009: "2010",
        2007: "2008",
      };

      const newYear = yearMap[year] || year;

      if (!this.listOfSelectedYear.includes(newYear)) {
        this.listOfSelectedYear.push(newYear);

        if (year === "2007") {
          this.listOfSelectedYear.push("2007");
        }

        if (year === "2009") {
          this.listOfSelectedYear.push("2009");
        }
      }
    },
    removeFromlistOfSelectedTeamIds(id) {
      this.listOfSelectedTeamIdsToRemove.push(id);
      this.listOfSelectedTeamIds = this.listOfSelectedTeamIds.filter(
        (item) => item !== id
      );
    },
    removeFromListOfSelectedAgeGroups(year) {
      const yearMap = {
        2009: "2010",
        2007: "2008",
      };

      const yearToRemove = yearMap[year] || year;
      this.listOfSelectedYear = this.listOfSelectedYear.filter(
        (item) => item !== year && item !== yearToRemove
      );
    },
    ageGroupClear(data) {
      if (data === "1") {
        this.listOfSelectedYear = [];
      }
    },
    ageGroupAddRemove(data) {
      data.target.checked === true
        ? this.addToListOfSelectedTeamAgeGroups(data.target.value)
        : this.removeFromListOfSelectedAgeGroups(data.target.value);
    },
    teamIdsAddRemove(data) {
      data.target.checked === true
        ? this.addTolistOfSelectedTeamIds(data.target.id)
        : this.removeFromlistOfSelectedTeamIds(data.target.id);
    },
    sendNotification() {
      this.notification.type === "Email"
        ? this.sendNotificationEmails()
        : this.sendNotificationBackend();
    },
    resetNotificationsData() {
      this.notification = {
        type: "Both",
        message: "",
        all: 0,
      };

      this.listOfSelectedTeamIds = [];
    },
    async sendNotificationBackend() {
      this.sendFBNotificationMessage();
    },
    async sendNotificationEmails() {
      this.loadingStuff = true;
      const data = {
        action: "emailNotification",
        name: this.userData.name,
        title: this.notification.title,
        message: this.notification.message,
        sendToAll: this.notification.all,
        idsList: this.listOfSelectedTeamIds,
      };
      const responce = await Mail.find(data).catch((error) => {
        console.log(error);
      });

      this.resetNotificationsData();

      this.loadingStuff = false;

      if (responce.status === 200) {
        this.$bvToast.toast("Notification sent", {
          title: "Notification sent",
          toaster: "b-toaster-bottom-right",
          solid: true,
          appendToast: true,
          variant: "success",
        });
      }
    },
    async getTeamsByUserId() {
      this.userHasMoreThanOneAccount === null;
      const data = {
        action: "getTeamsDataById",
        userId: this.userData.id,
      };

      if (
        this.userData.userType === "Admin" ||
        this.userData.userType === "Coach"
      ) {
        data.clubId = this.userData.clubId;
      }
      const responce = await Teams.find(data).catch((error) => {
        console.log(error);
      });
      this.clubTeams = responce.data;
    },
    async getTeamsDataById(teamId) {
      const userData = {
        id: teamId,
        action: "getTeamDataById",
      };

      if (
        this.userData.userType === "Admin" ||
        this.userData.userType === "Coach"
      ) {
        userData.clubId = this.userData.clubId;
      }
      const responce = await Teams.find(userData).catch((error) => {
        console.log(error);
      });

      if (responce.data !== null) {
        if (Object.keys(responce.data).length !== 0) {
          localStorage.setItem(
            "currentPlayingseasonStartDate",
            responce.data.season_start_date
          );
          localStorage.setItem(
            "currentPlayingseasonEndDate",
            responce.data.season_end_date
          );

          (this.season_start_date = responce.data.season_start_date),
            (this.season_end_date = responce.data.season_end_date);
          this.getThisSeasonsgamePerformanceReportsForUser();
          this.getTheLastDateSubmittedSeasonsTrainingPerformanceReport();
        }
        this.clubTeams = responce.data;

        return true;
      }
    },
    getNumberOfActionsForUser(data) {
      if (data.created_date === null) {
        return null;
      }
      this.playerActionNumber = 0;
      this.checkDateTimeFromNow(data.created_date) === true
        ? (this.playerActionNumber = this.playerActionNumber + 1)
        : (this.playerActionNumber = 0);
    },
    setPlayingSeasion() {
      if (this.weekSelectedNumber >= 8 && this.weekSelectedNumber < 52) {
        this.currentPlayingSeason = moment().format("YYYY");
      }
      if (this.weekSelectedNumber >= 1 && this.weekSelectedNumber < 8) {
        this.currentPlayingSeason = moment().subtract(1, "year").format("YYYY");
      }
      if (this.weekSelectedNumber == 52) {
        moment().format("M") === "1"
          ? (this.currentPlayingSeason = moment()
            .subtract(1, "year")
            .format("YYYY"))
          : (this.currentPlayingSeason = moment().format("YYYY"));
      }

      localStorage.setItem("currentPlayingSeason", this.currentPlayingSeason);
    },
    async populateCoachesData() {
      const coachesData = {
        clubId: this.userData.clubId,
        action: "populateCoachesData",
      };

      const responce = await Users.find(coachesData).catch((error) => {
        console.log(error);
      });

      return responce.data.length > 0 ? responce.data : [];
    },
    resetPlayerProfileForm() {
      this.playerProfileForm = {
        sectionOne: false,
        sectionTwo: false,
        sectionThree: false,
        sectionFour: false,
        sectionFive: false,
        sectionSix: false,
        sectionSeven: false,
        sectionEight: false,
      };
    },
    changePlayerProfileFormSection(section) {
      this.resetPlayerProfileForm();
      this.playerProfileFormSelection = section;
      this.playerProfileForm[section] = true;
    },
    formSectionsMove(type) {
      if (this.playerProfileFormSelection === "sectionOne" && type === "next") {
        this.changePlayerProfileFormSection("sectionTwo");
        return;
      }
      if (this.playerProfileFormSelection === "sectionTwo" && type === "next") {
        this.changePlayerProfileFormSection("sectionThree");
        return;
      }
      if (
        this.playerProfileFormSelection === "sectionThree" &&
        type === "next"
      ) {
        this.changePlayerProfileFormSection("sectionFour");
        return;
      }
      if (
        this.playerProfileFormSelection === "sectionFour" &&
        type === "next"
      ) {
        this.changePlayerProfileFormSection("sectionFive");
        return;
      }
      if (
        this.playerProfileFormSelection === "sectionFive" &&
        type === "next"
      ) {
        this.changePlayerProfileFormSection("sectionSix");
        return;
      }
      if (this.playerProfileFormSelection === "sectionSix" && type === "next") {
        this.changePlayerProfileFormSection("sectionSeven");
        return;
      }
      if (
        this.playerProfileFormSelection === "sectionSeven" &&
        type === "next"
      ) {
        this.changePlayerProfileFormSection("sectionEight");
        return;
      }
      if (
        this.playerProfileFormSelection === "sectionEight" &&
        type === "next"
      ) {
        return;
      }

      if (this.playerProfileFormSelection === "sectionTwo" && type === "back") {
        this.changePlayerProfileFormSection("sectionOne");
        return;
      }
      if (
        this.playerProfileFormSelection === "sectionThree" &&
        type === "back"
      ) {
        this.changePlayerProfileFormSection("sectionTwo");
        return;
      }
      if (
        this.playerProfileFormSelection === "sectionFour" &&
        type === "back"
      ) {
        this.changePlayerProfileFormSection("sectionThree");
        return;
      }
      if (
        this.playerProfileFormSelection === "sectionFive" &&
        type === "back"
      ) {
        this.changePlayerProfileFormSection("sectionFour");
        return;
      }
      if (this.playerProfileFormSelection === "sectionSix" && type === "back") {
        this.changePlayerProfileFormSection("sectionFive");
        return;
      }
      if (
        this.playerProfileFormSelection === "sectionSeven" &&
        type === "back"
      ) {
        this.changePlayerProfileFormSection("sectionSix");
        return;
      }
      if (
        this.playerProfileFormSelection === "sectionEight" &&
        type === "back"
      ) {
        this.changePlayerProfileFormSection("sectionSeven");
        return;
      }
    },
    async createPlayerProfile() { },
    async checkPlayerHasProfile() {
      if (
        this.userData.playerProfile === true ||
        this.userData.userType === "Admin" ||
        this.userData.userType === "Coach"
      ) {
        this.playerHasProfile = true;
        this.loadingStuff = false;
      } else {
        this.playerProfileData = {
          name: this.userData.name,
          email: this.userData.email,
          dob: this.userData.dob,
          position: this.userData.position,
        };
        this.coachData = await this.populateCoachesData();
        this.showPlayerProfileModal();
      }
    },
    showPlayerProfileModal() {
      this.loadingStuff = false;
      this.showModal("playerProfile");
    },
    tabChange(tabValue, selectedTabValue) {
      this[tabValue] = selectedTabValue;
    },
    async getUserById(clientId) {
      const userData = {
        clientId: clientId,
        action: "getUserByID",
      };
      const responce = await Users.find(userData).catch((error) => {
        console.log(error);
      });
      if (responce.data.length > 0) {
        this.userData = responce.data[0];
        const userData = responce.data[0];
        this.$store.dispatch(USER_POPULATE, { userData });
        return true;
      }
    },
    setUser() {
      if (this.getProfile !== null) {
        this.userData = this.getProfile;
      }
    },
    async getAllUsersForThisAdmin() {
      const userData = {
        coachId: this.userData.id,
        action: "getUserByCoachId",
      };
      const responce = await Users.find(userData).catch((error) => {
        console.log(error);
      });
      if (responce.data.length > 0) {
        this.coachesUsersData = responce.data;
        this.$store.dispatch(POPULATE_COACHES_USER_DATA, this.coachesUsersData);
        localStorage.setItem(
          "coachesUsersData",
          JSON.stringify(this.coachesUsersData)
        );
      }
    },
    checkForCoachesUsersData() {
      if (this.getCoachesUsersData.length > 0) {
        this.coachesUsersData = this.getCoachesUsersData;
      } else {
        if (
          this.userData.userType === "Admin" ||
          this.userData.userType === "Coach"
        ) {
          this.getAllUsersForThisAdmin();
        }
      }
    },
    async getThisSeasonsgamePerformanceReportsForUser() {
      if (this.selectedUserValue === "") {
        this.selectedUserValue = this.userData.id;
      }
      if (localStorage.getItem("currentPlayingSeason") !== null) {
        this.currentPlayingSeason = localStorage.getItem(
          "currentPlayingSeason"
        );
      }
      const userData = {
        user_id: this.selectedUserValue,
        action: "getTheLastDateSubmittedSeasonsgamePerformanceReport",
        season_start_date: localStorage.getItem(
          "currentPlayingseasonStartDate"
        ),
        season_end_date: this.extendSeasonEndDateToFullYear(),
        year: this.currentPlayingSeason,
      };
      const responce = await Games.find(userData).catch((error) => {
        console.log(error);
      });

      if (responce.data.length > 0) {
        this.gamePerformanceData = responce.data[0];
        this.getNumberOfActionsForUser(this.gamePerformanceData);
      } else {
        this.playerActionNumber = this.playerActionNumber + 1;
      }
    },
    async getTheLastDateSubmittedSeasonsTrainingPerformanceReport() {
      if (this.selectedUserValue === "") {
        this.selectedUserValue = this.userData.id;
      }
      if (localStorage.getItem("currentPlayingSeason") !== null) {
        this.currentPlayingSeason = localStorage.getItem(
          "currentPlayingSeason"
        );
      }
      const userData = {
        user_id: this.selectedUserValue,
        action: "getTheLastDateSubmittedSeasonsTrainingPerformanceReport",
        season_start_date: localStorage.getItem(
          "currentPlayingseasonStartDate"
        ),
        season_end_date: this.extendSeasonEndDateToFullYear(),
        year: this.currentPlayingSeason,
      };
      const responce = await Games.find(userData).catch((error) => {
        console.log(error);
      });

      if (responce.data.length > 0) {
        this.trainingPerformanceData = responce.data[0];
        this.getNumberOfActionsForUser(this.trainingPerformanceData);
      } else {
        this.playerActionNumber = this.playerActionNumber + 1;
      }
    },
    checkDateTimeFromNow(date) {
      if (date === null) {
        return false;
      }
      let now = new Date();
      let otherDate = new Date(date);
      new Date(now.setHours(0, 0, 0, 0)).toISOString();
      new Date(otherDate.setHours(0, 0, 0, 0)).toISOString();

      const diffTime = Math.abs(
        new Date(now.setHours(0, 0, 0, 0)) -
        new Date(otherDate.setHours(0, 0, 0, 0))
      );
      const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));

      return diffDays > 7 ? true : false;
    },
    showModal(modelId) {
      this.$bvModal.show(modelId);
    },
    hideModal(modelId) {
      this.$bvModal.hide(modelId);
    },
    formatDate(date) {
      return moment(moment(date, "YYYY-MM-DD")).format("MMMM Do YYYY");
    },
  },
};
</script>

<style lang="scss" scoped>
.survey-link {
  border: 1px solid #bab8b8;
  padding: 2rem;
  margin-bottom: 2rem;
  background: #ffffff;
  border-radius: 4px;

  p {
    margin: 0;
  }

  a {
    border: 1px solid #15a159;
    padding: 1rem;
    margin-top: 1rem;
    display: block;
    text-decoration: none;
    cursor: pointer;
    width: 16rem;
    border-radius: 4px;
    background: #16a159;
    color: white;
    font-weight: bold;

    &:hover {
      background: #22b167;
    }
  }
}

.notifications-history {
  flex: 1;

  .scroll-wrapper {
    height: 49rem;
    overflow-y: scroll;
  }

  .history-list {
    background: #ededed;
    padding: 1rem;
    border-radius: 4px;
    max-width: 43rem;
    margin-bottom: 1rem;

    .date {
      font-size: 0.9rem;
    }

    .title {
      font-weight: bold;
    }

    .body {
      margin-top: 0.5rem;
    }

    p {
      margin: 0;
    }
  }
}
</style>
